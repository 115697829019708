import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {User} from "@models/user";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {UserService} from "@services/user.service";
import {first} from "rxjs/operators";
import {PagesService} from "@services/pages.service";
import { ActivatedRoute } from '@angular/router';
import {Page} from "@models/page";
import {Perm} from "@models/perm";
import {WavesService} from "@services/waves.service";
import {TilesService} from "@services/tiles.service";
import {Wave} from "@models/wave";
import {Tile} from "@models/tile";
import {NotifyService} from "@services/notify.service";
@Component({
  selector: 'app-list-perm',
  templateUrl: './list-perm.component.html',
  styleUrls: ['./list-perm.component.scss']
})
export class ListPermComponent implements OnInit {
  public users:User[] =[];
  public page!:Page;
  public wave!:Wave
  public tile!:Tile
  public type = this.route.snapshot.paramMap.get('type');
  public id = this.route.snapshot.paramMap.get('customID');
  public bool:boolean = false;
  displayedColumns: string[] = ['firstName', 'lastName', 'profil', 'email', 'phoneNumber', 'companyName', 'action'];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource!:MatTableDataSource<User>
  constructor(private accountService: UserService,
              private cdr: ChangeDetectorRef,
              private pageService: PagesService,
              private route: ActivatedRoute,
              private waveService: WavesService,
              private tilesService: TilesService,
              private notifyService: NotifyService) {

  }

  ngOnInit() {
    var user:User
    this.accountService.getAll()
      .subscribe(
        (users: User[]) => {
          // @ts-ignore
          this.users = users['data'];
          console.log(this.users)
          this.dataSource = new MatTableDataSource<User>(this.users);
          this.cdr.detectChanges();
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort
        }
      )
  switch (this.type) {
    case "page":{
      if (this.id)
      this.pageService.getPage(this.id)
        .subscribe(
          (page: Page) => {
            this.page = page;
          }
        )
      break;
    }
    case "wave":{
      if (this.id)
        this.waveService.getWave(this.id)
          .subscribe(
            (wave: Wave) => {
              this.wave = wave;
            }
          )
      break;
    }
    case "tile":{
      if (this.id)
        this.tilesService.getTileById(this.id)
          .subscribe(
            (tile: Tile) => {
              this.tile = tile;
            }
          )
      break;
    }


  }





  }
  ngAfterViewInit() {

  }
  // findPerm(customIDElem:string|undefined,user:User):Array<boolean>{
  //   var aPerm = user.permissions.find(e => e.customIDElem === this.page.customID)
  //   var permission = []
  //   var isRead:boolean = false;
  //   var isUpdate:boolean = false;
  //   var isImport:boolean = false;
  //   var isDelete:boolean = false;
  //   if (aPerm){
  //     if (aPerm._permissions.includes("read")){
  //       isRead = true;
  //       console.log("read true aaa")
  //     }
  //     if (aPerm._permissions.includes("import")){
  //       isImport = true;
  //     }
  //     if (aPerm._permissions.includes("delete")){
  //       isDelete = true;
  //     }
  //     if (aPerm._permissions.includes("update")){
  //       isUpdate = true;
  //     }
  //     permission.push(isRead,isImport,isDelete,isUpdate)
  //
  //   }
  //   return permission
  // }
  // findPermRead(customIDElem:string,user:User):boolean{
  //   var isRead = false;
  //   var tablebool:Array<boolean>;
  //   tablebool = this.findPerm(customIDElem,user)
  //   return tablebool[0]
  // }
  removePerm(user:User, permission:string){
    var i;
    var aPerm;
    switch (this.type) {
      case "page":{
        i = user.permissions.findIndex(e => e.customIDElem === this.page.customID)
        aPerm = user.permissions.find(e => e.customIDElem === this.page.customID)
        break;
      }
      case "wave":{
        i = user.permissions.findIndex(e => e.customIDElem === this.wave.customID)
        aPerm = user.permissions.find(e => e.customIDElem === this.wave.customID)
        break;
      }
      case "tile":{
        i = user.permissions.findIndex(e => e.customIDElem === this.tile.customID)
        aPerm = user.permissions.find(e => e.customIDElem === this.tile.customID)
        break;
      }
    }
    if (i && aPerm)
    {
      user.permissions[i].permissions = user.permissions[i].permissions.replace(permission + '_',"")
      //user.permissions.splice(i, 1)
    }
    console.log(user);
    this.accountService.update(user.customID, user)
      .subscribe({
        next: () => {
          this.notifyService.success("Permission " + permission + " supprimée !  ")
        },
        error: ()  => {
          this.notifyService.failed("Une erreur est survenue")
        }
      });
  }
  addPerm(user:User, permission:string){
  var i;
  var aPerm;
  var customId;
  switch (this.type) {
    case "page":{
      i = user.permissions.findIndex(e => e.customIDElem === this.page.customID)
      aPerm = user.permissions.find(e => e.customIDElem === this.page.customID)
      customId = this.page.customID
      break;
    }
    case "wave":{
      i = user.permissions.findIndex(e => e.customIDElem === this.wave.customID)
      aPerm = user.permissions.find(e => e.customIDElem === this.wave.customID)
      customId = this.wave.customID
      console.log(aPerm)
      break;
    }
    case "tile":{
      i = user.permissions.findIndex(e => e.customIDElem === this.tile.customID)
      aPerm = user.permissions.find(e => e.customIDElem === this.tile.customID)
      customId = this.tile.customID
      break;
    }
  }

    if (i && aPerm)
    {
      if(!aPerm.permissions.includes(permission))
      {
        user.permissions[i].permissions = aPerm.permissions + permission + '_'
      }
    }
    else{
      if (customId)
      {
        user.permissions.push(new Perm(customId,permission + '_'))
      }
    }
    console.log(user)
    this.accountService.update(user.customID, user)
      .subscribe({
        next: () => {
          this.notifyService.success("Permission " + permission + " ajoutée  ")
        },
        error: ()  => {
          this.notifyService.failed("Une erreur est survenue")
        }
      });
  }
  setPerm(user:User, permission:string){


    if(this.isPerm(user,permission)){
      console.log("remove perm")
      this.removePerm(user,permission)
    }
    else{
      console.log("Add perm")
      this.addPerm(user,permission)
    }
  }

  checkAll(user: any){
    let perms = ["read", "update", "import"];
    perms.forEach(perm => {
      this.removePerm(user, perm);
      this.addPerm(user, perm);
    })
  }

  canAddUser():boolean{
    
    if(this.accountService.user.profilType == "Admin" || this.accountService.user.profilType == "Contributor")
    {
      return true;
    }
    else{
      return false;
    }

  }
  canFilterUser():boolean{
    
    if(this.accountService.user.profilType == "Admin" || this.accountService.user.profilType == "Contributor")
    {
      return true;
    }
    else{
      return false;
    }

  }

  isPerm(user:User,permission:string):boolean{
    var i;
    var aPerm;
    var customId;
    var check = false;
    switch (this.type) {
      case "page":{
        if(this.page){
          i = user.permissions.findIndex(e => e.customIDElem === this.page.customID)
          aPerm = user.permissions.find(e => e.customIDElem === this.page.customID)
          customId = this.page.customID
        }
        break;
      }
      case "wave":{
        if(this.wave){
          i = user.permissions.findIndex(e => e.customIDElem === this.wave.customID)
          aPerm = user.permissions.find(e => e.customIDElem === this.wave.customID)
          customId = this.wave.customID
        }
        break;
      }
      case "tile":{
        if(this.tile){
          i = user.permissions.findIndex(e => e.customIDElem === this.tile.customID)
          aPerm = user.permissions.find(e => e.customIDElem === this.tile.customID)
          customId = this.tile.customID
        }
        break;
      }
    }
    if (i && aPerm)
    {
      console.log(i);
      console.log(aPerm);
      
      if(aPerm.permissions.includes(permission))
      {
        check = true;
        return check;
      }
    }
    return check;
  }

}
