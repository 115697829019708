  <div class="title">
    <p>RETOUR UTILISATEURS</p>
  </div>
  <div [routerLink]="'/feedback'" *ngIf="fullDisplay === false" class="array">
    <table mat-table  [dataSource]="dataSource" class="mat-elevation-z8 demo-table">
            <!-- Date Column -->
            <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let element"> {{element.createdAt}} </td>
            </ng-container>
        
            

            <!-- Page where user sent the feedback Column -->
            <ng-container matColumnDef="actualpage">
            <th mat-header-cell *matHeaderCellDef> Page </th>
            <td mat-cell *matCellDef="let element"> {{element.actualpage}} </td>
            </ng-container>
            <!-- Time passed on Mediashow Column -->
            <ng-container matColumnDef="timepassed">
            <th mat-header-cell *matHeaderCellDef> Temps passé </th>
            <td mat-cell *matCellDef="let element"> {{element.timepassed}} </td>
            </ng-container>

            <!-- message Column -->
            <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef> Message </th>
            <td mat-cell *matCellDef="let element"> {{element.message}} </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div *ngIf="fullDisplay === true" class="array">
    <table mat-table  [dataSource]="dataSource" class="mat-elevation-z8 demo-table">
            <!-- Date Column -->
            <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let element"> {{element.createdAt}} </td>
            </ng-container>
            <!-- Page where user sent the feedback Column -->
            <ng-container matColumnDef="actualpage">
            <th mat-header-cell *matHeaderCellDef> Page </th>
            <td mat-cell *matCellDef="let element"> {{element.actualpage}} </td>
            </ng-container>
            <!-- Time passed on Mediashow Column -->
            <ng-container matColumnDef="timepassed">
            <th mat-header-cell *matHeaderCellDef> Temps passé </th>
            <td mat-cell *matCellDef="let element"> {{element.timepassed}} </td>
            </ng-container>
        
            <!-- message Column -->
            <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef> Message </th>
            <td mat-cell *matCellDef="let element"> {{element.message}} </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div class="buttons">
    <button (click)="Displayingwindow();" style="left: 47%;" mat-raised-button color="primary"> {{boutonText}}</button>
  </div>