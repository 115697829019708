import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { RESTFulObject } from '@models/restful';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { Feedback,FeedbackServices } from '@services/feedback.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  public message :string[] = [];
  public datas: Feedback[] = [];
  public displayedColumns : string[] = [];
  public dataSource: Feedback[] =[]
  public fullDisplay : boolean = false;
  public boutonText : string = "Montrer tout";


  constructor(
    private feedbackservices: FeedbackServices,
    private route: ActivatedRoute
  ) {}

    ngAfterViewInit(){
    }

  ngOnInit(): void {
    this.feedbackservices.getFeedbackData().subscribe((resp: RESTFulObject) => {
      this.datas = resp.data as Feedback[];
      console.log("Take", this.datas)
      this.displayedColumns = ['Date','actualpage','timepassed', 'message'];
      this.dataSource = this.datas.reverse().slice(0, 5);

      
      
      console.log(this.dataSource, "tock");
    },
    err => {
      alert("Error while getting Feedback Data");
    })
   
  }

  public Displayingwindow(){
    this.fullDisplay = !this.fullDisplay;
    if(this.fullDisplay === false){
      this.dataSource = this.datas.slice(0, 5);
      this.boutonText = "Montrer tout";

    }else
    {
      this.dataSource = this.datas;
      this.boutonText = "Cacher Tout";
    }  
    console.log("Tare", this.fullDisplay);
  }
}
