import {
  Component,
  OnInit,
  ElementRef,
  AfterViewInit,
  ContentChild,
} from '@angular/core';
import { RESTFulObject } from '@models/restful';
import { KPI, KPIService } from '@services/kpi.service';
import { environment } from 'src/environments/environment';
import { Page } from '@models/page';
import { PagesService } from '@services/pages.service';
import { ActivatedRoute, Router } from '@angular/router';
import {Chart} from 'chart.js';



const getWeekOfYear =  function(date: Date) {
        var target = new Date(date.valueOf()),
            dayNumber = (date.getUTCDay() + 6) % 7,
            firstThursday;

        target.setUTCDate(target.getUTCDate() - dayNumber + 3);
        firstThursday = target.valueOf();
        target.setUTCMonth(0, 1);

        if (target.getUTCDay() !== 4) {
            target.setUTCMonth(0, 1 + ((4 - target.getUTCDay()) + 7) % 7);
        }

        return Math.ceil((firstThursday.valueOf() - target.valueOf()) /  (7 * 24 * 3600 * 1000)) + 1;
    }
interface Landingpage {
  id: number;
  page: string;
}

export class SelectValueBindingExample {
  selected = '0';
}

export interface PeriodicElement {
  perHours: number;
  perDays: number;
  perWeeks: number;
  perMonths: number;
}

// different arrays
enum Tabs {
  ARRAY1,
  ARRAY2,
  ARRAY3,
}

// different Times
enum Time {
  NONE = 1,
  DAYS = 6,
  HOURS = 23,
  MONTHS = 11,
}

@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss'],
  animations: [],
})
export class KPIComponent implements OnInit, AfterViewInit {
  public step: Tabs = Tabs.ARRAY1;
  public datas: KPI[] = [];
  public pageN: Page[] = [];
  public pagesT: string[] = [];
  public displayedDatas: any = [];
  public displayedColumnsArray: string[] = [];
  public elementos: any[] = [];
  public title = '';
  private frontendEndpoint: string = environment.frontendEndPoint;
  private currentDate: Date = new Date();
  public expanded = false;
  public Larray: any[] = [];
  public booltab = false;
  public Landingpagearray: string[] = [];
  public Displaylandingpagearray: Landingpage[] = [];
  public selected = '';
  public widgetTabdispay: any[] = [];
  public widgetorigins: string[] = [];
  public chart: any;
  public selectedPeriod: any = 0;
  public periods = [
    { value: 0, label: 'Last 4 Hours' },
    { value: 1, label: 'Last day' },
    { value: 2, label: 'Last week' },
    { value: 3, label: 'Last month' },
  ];
  public platforms = [
    { value: '0', label: 'All' },
    { value: '1', label: 'Desktop' },
    { value: '2', label: 'Mobile' },
  ];
  @ContentChild('chart', { static: false }) chartComponent!: ElementRef;
  // allows us to go back to the previous array
  public previousArray() {
    if (this.step == Tabs.ARRAY1) {
      this.step = 2;
    } else {
      this.step--;
    }
    this.initArray();
  }
  public changePeriod(value: string) {
    this.selectedPeriod = value;
    this.refreshChart();
  }
  // getter
  public get filtredDatas(): Array<KPI> {
    console.log(
      'widget data',
      this.getWidgetData().map((el) => el.widgetLandingPage)
    );
    return this.getWidgetData()
      .filter((el) => el.widgetLandingPage === this.selected)
      .filter(
        (el) =>
          this.selectedPeriod == null ||
          this.checkPeriod(new Date(el.createdAt), this.selectedPeriod)
      );
  }
  public checkPeriod(date: Date, selectedPeriod: number): boolean {
    // check if date is in the selected perio d
    const currentDate = new Date();
    if (selectedPeriod == 0) {
      return (
        currentDate.getHours() - date.getHours() <= 4 &&
        currentDate.getDate() == date.getDate() &&
        currentDate.getMonth() == date.getMonth() &&
        currentDate.getFullYear() == date.getFullYear()
      );
    }
    if (selectedPeriod == 1) {
      currentDate.getDate() - 1 == date.getDate() &&
        currentDate.getMonth() == date.getMonth() &&
        currentDate.getFullYear() == date.getFullYear();
    }
    if (
      selectedPeriod == 2 &&
      currentDate.getMonth() == date.getMonth() &&
      currentDate.getFullYear() == date.getFullYear()
    ) {
      // compute the week number of currentDate
      console.log(getWeekOfYear(currentDate), getWeekOfYear(date));
      return getWeekOfYear(currentDate) - getWeekOfYear(date) == 1;
    }
    if (selectedPeriod == 3) {
      return (
        currentDate.getMonth() - date.getMonth() == 1 &&
        currentDate.getFullYear() == date.getFullYear()
      );
    }
    return false;
  }
  public barChartsLabels = [
    'Visite Totale',
    'Visite with Blocker',
    'Widget Open',
    'Click Ok',
    'Click second boutton',
    'Click Close',
    'Moins de 10 secondes',
  ];
  public get barChartData(): Chart.ChartDataSets[]{
    console.log('elel', this.filtredDatas);
    return [
      {
        label: 'All',
        data: this.barChartsLabels.map((label, index) => {
          let r =
            this.filtredDatas
              // .filter((el) => el.widgetPlateform == "")
              .filter((el) => {
                switch (index) {
                  case 0:
                    return true;
                  case 1:
                    return el.widgetABEnabled;
                  case 2:
                    return el.widgetOpen;
                  case 3:
                    return el.widgetOk;
                  case 4:
                    return el.widgetLearnMore;
                  case 5:
                    return el.widgetClosed;
                  case 6:
                    return el.widgetClosedBefor10;
                  default:
                    return false;
                }
              }).length || 0;
          console.log('All', r);
          return r;
        }),
        borderColor: 'rgba(105, 214, 244,1)',
        backgroundColor: 'rgba(105, 214, 244,0.5)',
      },
      {
        label: 'mobile',
        data: this.barChartsLabels.map((label, index) => {
          let r =
            this.filtredDatas
              .filter((el) => el.widgetPlateform == 'mobile')
              .filter((el) => {
                switch (index) {
                  case 0:
                    return true;
                  case 1:
                    return el.widgetABEnabled;
                  case 2:
                    return el.widgetOpen;
                  case 3:
                    return el.widgetOk;
                  case 4:
                    return el.widgetLearnMore;
                  case 5:
                    return el.widgetClosed;
                  case 6:
                    return el.widgetClosedBefor10;
                  default:
                    return true;
                }
              }).length || 0;
          console.log('mobile', r);
          return r;
        }),
        borderColor: 'rgb(242, 83, 83)',
        backgroundColor: 'rgba(242, 83, 83,0.5)',
      },
      {
        label: 'desktop',
        data: this.barChartsLabels.map((label, index) => {
          let r =
            this.filtredDatas
              .filter((el) => el.widgetPlateform == 'desktop')
              .filter((el) => {
                switch (index) {
                  case 0:
                    return true;
                  case 1:
                    return el.widgetABEnabled;
                  case 2:
                    return el.widgetOpen;
                  case 3:
                    return el.widgetOk;
                  case 4:
                    return el.widgetLearnMore;
                  case 5:
                    return el.widgetClosed;
                  case 6:
                    return el.widgetClosedBefor10;
                  default:
                    return false;
                }
              }).length || 0;
          console.log('desktop', r);
          return r;
        }),
        borderColor: 'rgb(0, 114, 100)',
        backgroundColor: 'rgba(0, 114, 100,0.5)',
      },
    ];
  }

  // allows us to go to the next array
  public nextArray() {
    if (this.step == Tabs.ARRAY3) {
      this.step = 0;
    } else {
      this.step++;
    }
    this.initArray();
  }
  public expandTab() {
    const element = document.getElementsByClassName('hide');
    const arrayelement = Array.from(element);
    console.log('Track', element);
    for (const tab in element) {
      element[tab].setAttribute('style', 'display: table-cell');
    }
  }

  ngAfterViewInit() {
    console.log('Values on ngAfterViewInit():');
    // console.log('sample:', this.sample.nativeElement);
  }
  // allows us to sort our data by hours, day and months
  private sortDataByTime(datas: KPI[], selectedTime: Time): KPI[] {
    const sortedData: KPI[] = [];

    datas.forEach((data: KPI) => {
      const dataDate = new Date(data.createdAt);
      if (this.currentDate.getFullYear() === dataDate.getFullYear()) {
        if (selectedTime === Time.HOURS) {
          if (
            this.currentDate.getMonth() === dataDate.getMonth() &&
            this.currentDate.getDay() === dataDate.getDay()
          ) {
            // minus 2 here bcs the time saved in db is in GMT
            let hour = this.currentDate.getHours() - 2;
            if (this.currentDate.getHours() === 0) {
              hour = Time.HOURS - 1;
            }
            if (hour === dataDate.getHours() - 1) {
              sortedData.push(data);
            }
          }
        } else if (selectedTime === Time.DAYS) {
          if (this.currentDate.getMonth() === dataDate.getMonth()) {
            let day = this.currentDate.getDay() - 1;
            if (this.currentDate.getDay() === 0) {
              day = Time.DAYS;
            }
            if (day === dataDate.getDay()) {
              sortedData.push(data);
            }
          }
        } else if (selectedTime === Time.MONTHS) {
          if (this.currentDate.getMonth() === dataDate.getMonth()) {
            sortedData.push(data);
          }
        }
      }
    });
    return sortedData;
  }

  private getFrontEndData(): KPI[] {
    const frontEndData: KPI[] = [];
    this.datas.forEach((element: KPI) => {
      if (element.origin == this.frontendEndpoint) {
        frontEndData.push(element);
      }
    });
    return frontEndData;
  }

  private getWidgetData(): KPI[] {
    return this.datas.filter(
      (element: KPI) => element.origin !== this.frontendEndpoint
    );
  }
  private getTotalVisitbyactualpage(time: Time, thepage: string): number {
    const sortedData = this.sortDataByTime(this.getFrontEndData(), time);
    if (sortedData) {
      let totalVisits = 0;
      sortedData.forEach((sortedElements: KPI) => {
        if (sortedElements.actualPage == thepage) {
          totalVisits++;
        }
        console.log('Chack', sortedElements.actualPage, thepage);
      });

      return totalVisits;
    }
    return -1;
  }

  private getdisablingABvisitbyactualpage(time: Time, thepage: string): number {
    const totaladblockers =
      this.getTotalVisitbyactualpage(time, thepage) -
      this.getTotalAdBlockUnablingbyactualpage(time, thepage);
    return totaladblockers;
  }

  private getTotalUsefulVisitbyactualpage(time: Time, thepage: string): number {
    const totaluseful =
      this.getTotalVisitbyactualpage(time, thepage) -
      this.gettotalclosedBefore1byactualpage(time, thepage);
    return totaluseful;
  }

  private getTotalAdBlockUnabling(time: Time): number {
    const sortedData = this.sortDataByTime(this.getFrontEndData(), time);
    if (sortedData) {
      let totalAdblock = 0;
      sortedData.forEach((sortedElements: KPI) => {
        if (sortedElements.tabOnDisplay) {
          totalAdblock++;
        }
      });
      return totalAdblock;
    }
    return -1;
  }
  private getTotalAdBlockUnablingbyactualpage(
    time: Time,
    thepage: string
  ): number {
    const sortedData = this.sortDataByTime(this.getFrontEndData(), time);
    if (sortedData) {
      let totalAdblock = 0;
      sortedData.forEach((sortedElements: KPI) => {
        if (
          sortedElements.tabOnDisplay &&
          sortedElements.actualPage == thepage
        ) {
          totalAdblock++;
        }
      });
      return totalAdblock;
    }
    return -1;
  }

  private getTotalNbOKbyactualpage(time: Time, thepage: string): number {
    const sortedData = this.sortDataByTime(this.getFrontEndData(), time);
    if (sortedData) {
      let totalNbOK = 0;
      sortedData.forEach((sortedElements: KPI) => {
        if (
          sortedElements.popInOkIsDone &&
          sortedElements.actualPage == thepage
        ) {
          totalNbOK++;
        }
      });
      return totalNbOK;
    }
    return -1;
  }

  private getTotalHowTobyactualpage(time: Time, thepage: string): number {
    const sortedData = this.sortDataByTime(this.getFrontEndData(), time);
    if (sortedData) {
      let totalHowTo = 0;
      sortedData.forEach((sortedElements: KPI) => {
        if (sortedElements.popInHowTo && sortedElements.actualPage == thepage) {
          totalHowTo++;
        }
      });
      return totalHowTo;
    }
    return -1;
  }

  private getTotalVideobyactualpage(time: Time, thepage: string): number {
    const sortedData = this.sortDataByTime(this.getFrontEndData(), time);
    if (sortedData) {
      let totalVideo = 0;
      sortedData.forEach((sortedElements: KPI) => {
        if (
          sortedElements.popInAccessVideo &&
          sortedElements.actualPage == thepage
        ) {
          totalVideo++;
        }
      });
      return totalVideo;
    }
    return -1;
  }

  private gettotalclosedBefore1byactualpage(
    time: Time,
    thepage: string
  ): number {
    const sortedData = this.sortDataByTime(this.getFrontEndData(), time);
    if (sortedData) {
      let nbVisit = 0;
      sortedData.forEach((sortedElements: KPI) => {
        if (
          sortedElements.closedBefore10 &&
          sortedElements.actualPage == thepage
        ) {
          nbVisit++;
        }
      });
      return nbVisit;
    }
    return -1;
  }

  private gettotalTutoOkisDonebyactualpage(
    time: Time,
    thepage: string
  ): number {
    const sortedData = this.sortDataByTime(this.getFrontEndData(), time);
    if (sortedData) {
      let nbVisit = 0;
      sortedData.forEach((sortedElements: KPI) => {
        if (
          sortedElements.tutoOkisDone &&
          sortedElements.actualPage == thepage
        ) {
          nbVisit++;
        }
      });
      return nbVisit;
    }
    return -1;
  }

  private gettotalTutoHowTo(time: Time): number {
    const sortedData = this.sortDataByTime(this.getFrontEndData(), time);
    if (sortedData) {
      let nbVisit = 0;
      sortedData.forEach((sortedElements: KPI) => {
        if (sortedElements.tutoHowto) {
          nbVisit++;
        }
      });
      return nbVisit;
    }
    return -1;
  }

  private getNBVisitsWidget(time: Time): number {
    const sortedData = this.sortDataByTime(this.getWidgetData(), time);
    if (sortedData) {
      let totalVisit = 0;
      sortedData.forEach((sortedElements: KPI) => {
        totalVisit++;
      });
      return totalVisit;
    }
    return -1;
  }

  private getNbOKWidget(time: Time): number {
    const sortedData = this.sortDataByTime(this.getWidgetData(), time);
    if (sortedData) {
      let totalOk = 0;
      sortedData.forEach((sortedElements: KPI) => {
        if (sortedElements.widgetOk) {
          totalOk++;
        }
      });
      return totalOk;
    }
    return -1;
  }

  private getNbLearnMoreWidget(time: Time): number {
    const sortedData = this.sortDataByTime(this.getWidgetData(), time);
    if (sortedData) {
      let totalNbLearnMore = 0;
      sortedData.forEach((sortedElements: KPI) => {
        if (sortedElements.widgetLearnMore) {
          totalNbLearnMore++;
        }
      });
      return totalNbLearnMore;
    }
    return -1;
  }

  private getNbIsWidgetCrossClicked(time: Time): number {
    const sortedData = this.sortDataByTime(this.getWidgetData(), time);
    if (sortedData) {
      let totalWidgetCrossClicked = 0;
      sortedData.forEach((sortedElements: KPI) => {
        if (sortedElements.widgetClosed) {
          totalWidgetCrossClicked++;
        }
      });
      return totalWidgetCrossClicked;
    }
    return -1;
  }

  private getNbvisitsLessThan10secWidget(time: Time): number {
    const sortedData = this.sortDataByTime(this.getWidgetData(), time);
    if (sortedData) {
      let totalVisit = 0;
      sortedData.forEach((sortedElements: KPI) => {
        if (sortedElements.timePassed < 10) {
          totalVisit++;
        }
      });
      return totalVisit;
    }
    return -1;
  }

  private getTotalWidgetTimewithoutactions(time: Time): number {
    const sortedData = this.sortDataByTime(this.getFrontEndData(), time);
    if (sortedData) {
      let totalTime = 0;
      let nbVisit = 0;
      sortedData.forEach((sortedElements: KPI) => {
        if (sortedElements.widgettimePassedwithoutaction) {
          totalTime += sortedElements.widgettimePassedwithoutaction;
        }
        nbVisit++;
      });
      // convert time in minutes
      // TODO display seconds with it. when it equal 0.07 minutes it will display 0 minute but it should display the seconds
      return Math.round(totalTime / nbVisit / 60);
    }
    return -1;
  }

  public getNbVisitByOrigin(origin: string, time: Time): number {
    const sortedData = this.sortDataByTime(this.datas, time);
    if (sortedData) {
      let totalVisit = 0;
      sortedData.forEach((sortedElements: KPI) => {
        if (sortedElements.origin === origin) {
          totalVisit++;
        }
      });
      return totalVisit;
    }
    return -1;
  }

  public getWidgetallpages(time: Time) {
    const sortedData = this.sortDataByTime(this.getWidgetData(), time);
    if (sortedData) {
      let totalVisit = 0;
      let idnumber = 0;
      sortedData.forEach((sortedElements: KPI) => {
        if (
          this.Landingpagearray.includes(sortedElements.widgetLandingPage) ===
          false
        ) {
          this.Landingpagearray.push(sortedElements.widgetLandingPage);
        }
      });
    }
  }

  public getWidgetalloriginbyselectedpage(time: Time, selectedpage: any) {
    const sortedData = this.sortDataByTime(this.getWidgetData(), time);
    if (sortedData) {
      let totalVisit = 0;
      let idnumber = 0;
      sortedData.forEach((sortedElements: KPI) => {
        if (
          this.widgetorigins.includes(sortedElements.origin) === false &&
          sortedElements.widgetLandingPage == selectedpage
        ) {
          this.widgetorigins.push(sortedElements.origin);
        }
      });
    }
  }

  public refreshChart() {
    if (this.chart) this.chart.destroy();
    console.log('chart data', this.barChartData);
    this.chart = new Chart('chart', {
      type: 'horizontalBar',
      data: {
        labels: this.barChartsLabels,
        datasets: this.barChartData,
      },
      options: {
        responsive: true,
        aspectRatio: 2.5,
      },
    });
    this.chart.canvas.style.height = '100%';
    this.chart.canvas.style.width = '100%';
  }
  onChange(page: any) {
    console.log(page);
    this.widgetTabdispay = [];
    this.widgetorigins = [];
    if (this.step === Tabs.ARRAY1) {
      this.title = ' Kpi des Widgets';
      this.refreshChart();
    }
  }

  public initArray() {
    if (this.step === Tabs.ARRAY1) {
      this.title = ' Kpi des Widgets';
      const data = this.getWidgetData().filter((el) => el != undefined);
      const DisplaylandingpageSet = new Set<string>(
        data.map((el) => el.widgetLandingPage)
      );
      console.log(data.map((el) => el.widgetLandingPage));
      this.Displaylandingpagearray = [...DisplaylandingpageSet].map(
        (d, index) => {
          return { id: index, page: d };
        }
      );
    }
    if (this.step === Tabs.ARRAY2) {
      this.title = 'Activités sur Mediashow (KPI)';
      this.displayedColumnsArray = [
        'Data',
        'perHours',
        'perDays',
        'perMonths',
        'Data1',
        'Data2',
        'Datas',
        'Expand',
        'Data3',
        'Data4',
        'Data5',
        'Data6',
      ];

      this.pagesT.forEach((element) => {
        this.Larray.push({
          Data: element,
          perHours: this.getTotalVisitbyactualpage(Time.MONTHS, element),
          perDays: this.getTotalAdBlockUnablingbyactualpage(
            Time.MONTHS,
            element
          ),
          perMonths: this.getdisablingABvisitbyactualpage(Time.MONTHS, element),
          Data1: this.getTotalNbOKbyactualpage(Time.MONTHS, element),
          Data2: this.getTotalHowTobyactualpage(Time.MONTHS, element),
          Datas: this.gettotalclosedBefore1byactualpage(Time.MONTHS, element),
          Data3: this.getTotalUsefulVisitbyactualpage(Time.MONTHS, element),
          Data4: this.getTotalVideobyactualpage(Time.MONTHS, element),
          Data5: this.getTotalNbOKbyactualpage(Time.MONTHS, element),
          Data6: this.gettotalTutoOkisDonebyactualpage(Time.MONTHS, element),
        });
      });

      this.displayedDatas = this.Larray;
      console.log('Tarm', this.Larray);
    }
    if (this.step === Tabs.ARRAY3) {
      this.title = 'Tableau des usages du Widget';
      this.displayedColumnsArray = ['Data', 'perHours', 'perDays', 'perMonths'];
      this.displayedDatas = [
        {
          Data: 'Nbr visites  d’ouvertures du widget',
          perHours: this.getNBVisitsWidget(Time.HOURS),
          perDays: this.getNBVisitsWidget(Time.DAYS),
          perMonths: this.getNBVisitsWidget(Time.MONTHS),
        },
        {
          Data: 'Nb de OK',
          perHours: this.getNbOKWidget(Time.HOURS),
          perDays: this.getNbOKWidget(24),
          perMonths: this.getNbOKWidget(Time.MONTHS),
        },
        {
          Data: 'Nb de en savoir +',
          perHours: this.getNbLearnMoreWidget(Time.HOURS),
          perDays: this.getNbLearnMoreWidget(Time.DAYS),
          perMonths: this.getNbLearnMoreWidget(Time.MONTHS),
        },
        {
          Data: 'NB de fermetures par la Croix',
          perHours: this.getNbIsWidgetCrossClicked(Time.HOURS),
          perDays: this.getNbIsWidgetCrossClicked(Time.DAYS),
          perMonths: this.getNbIsWidgetCrossClicked(Time.MONTHS),
        },
        {
          Data: 'NB de départ immédiat de la page  –10 sec',
          perHours: this.getNbvisitsLessThan10secWidget(Time.HOURS),
          perDays: this.getNbvisitsLessThan10secWidget(Time.DAYS),
          perMonths: this.getNbvisitsLessThan10secWidget(Time.MONTHS),
        },
        {
          Data: 'temps sans actions',
          perHours: this.gettotalTutoHowTo(Time.HOURS),
          perDays: this.getTotalWidgetTimewithoutactions(Time.DAYS),
          perMonths: this.getTotalWidgetTimewithoutactions(Time.MONTHS),
        },
      ];
    }
  }

  public switchArray(step: Tabs): void {
    this.step = step;
  }

  constructor(
    private kpiService: KPIService,
    private pageService: PagesService,
    private route: ActivatedRoute
  ) {}

  public getKPIData(): void {
    this.kpiService.getKPIData().subscribe(
      (resp: RESTFulObject) => {
        this.datas = resp.data as KPI[];
        this.initArray()
      },
      (err) => {
        alert('Error while getting KPI Data');
      }
    );
  }

  ngOnInit(): void {
    this.pageService.getPages().subscribe((pages: Page[]) => {
      this.pageN = pages.filter((p) => !p.deleted);
      this.pageN.forEach((pages) => {
        if (pages.title != undefined) {
          this.pagesT.push(pages.title);
        }
      });
    });

    this.getKPIData();
    setTimeout(() => {
      this.booltab = false;
    });
  }

  public resetbool() {
    this.booltab = !this.booltab;
  }
}
