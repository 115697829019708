import { BackendResponse } from './../../models/response';
import { SizesService } from './../../services/sizes.service';
import { Component, OnInit } from '@angular/core';
import { Size } from '@models/size';

@Component({
  selector: 'app-sizes-editor',
  templateUrl: './sizes-editor.component.html',
  styleUrls: ['./sizes-editor.component.scss']
})
export class SizesEditorComponent implements OnInit {
  public indexofElem:number = 0
  i!:number;
  sizes?: Size[];
  currentSize?: Size;
  constructor(private sizesService: SizesService) { }
  allowedKey = [
    'ArrowRight',
    'ArrowLeft',
    'Tab',
    'Shift',
    'Backspace',
    'End',
    'Home'
  ];
  edit(size: Size): void {
    this.currentSize = size;
  }

  updateSizeActivity(active: boolean, size: Size): void {
    size.isActive = active;
    this.sizesService.changeActive(size).subscribe((response: BackendResponse) => {

    });
  }

  update(): void {
    if (this.currentSize) {
      if (this.currentSize.customID) {
        this.sizesService.updateSize(this.currentSize).subscribe();
      }
    }
  }

  addSize(): void {
    this.sizesService.addSize(this.currentSize!).subscribe(() => {
      this.sizesService.getSizes().subscribe((sizes: Size[]) => {
        this.sizes = [...sizes];
      });
    });
  }


  preventAlpha(event: KeyboardEvent) {
    if (!event.key.match(/[0-9]/g)) {
      if (!this.allowedKey.includes(event.key)) {
        event.preventDefault();
      }
    }
  }

  isNotFilled(): boolean | undefined{
    return !(this.currentSize?.title !== undefined &&
      this.currentSize.width !== undefined &&
      this.currentSize.height !== undefined) ? true : undefined ;
  }

  add() {
    this.currentSize = new Size();
  }

  delete(size:Size){
    this.sizesService.deleteSize(size).subscribe(() => {
      this.sizesService.getSizes().subscribe((sizes: Size[]) => {
        this.sizes = [...sizes];
      });
    });
  }

  ngOnInit(): void {
    this.sizesService.getSizes().subscribe((sizes: Size[]) => {
      this.sizes = [...sizes];
    });
  }

}
