import { SortPipe } from './../../../../pipes/sort.pipe';
import { NotifyService } from './../../../../services/notify.service';
import { PublicApiService } from './../../../../services/public-api.service';
import { SizesEditorComponent } from './../../../../components/sizes-editor/sizes-editor.component';
import { PagesService } from '@services/pages.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FullPage } from '@models/full-page';
import { WavesService } from '@services/waves.service';
import { BackendResponse } from '@models/response';
import { Wave } from '@models/wave';
import { Tile } from '@models/tile';
import { TilesService } from '@services/tiles.service';
import { IMenuItem } from '@interfaces/components/IMenuItem';
import { ContextMenuComponent } from '@components/context-menu/context-menu.component';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';

import { HttpConstants } from '../../../../constants/http.constants';
import { UserService } from '@services/user.service';
import { HttpEventType } from '@angular/common/http';

import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-page-edit',
  templateUrl: './page-edit.component.html',
  styleUrls: ['./page-edit.component.scss'],
  providers: [SortPipe],
})
export class PageEditComponent implements OnInit {
  isExporting: boolean = false;
  exportItem: string = '';
  exportId: string = '';
  pageTitle: string | undefined;
  waveTitle: string | undefined;
  pageId = '';
  page: FullPage;
  pageArr: Wave[] = [];
  togglePopup = false;
  toggleRenamePopup = false;
  toggleSize = false;
  title = '';
  newWaveTitle = '';
  newWaveDescription = '';
  currentWaveTitle?: string;
  currentWave!: Wave;
  exportBandeauInWave!: Wave;
  selectedTile?: Tile;
  permUpdate: boolean = false;
  permDelete: boolean = false;
  menuItems: IMenuItem[];
  EnvName: string = environment.EnvName;
  frontendEndpoint: string = environment.frontendEndPoint;
  editOrder: string[] = [];
  hasNoChange: boolean | undefined;
  private _fullscreen = false;
  @Input()
  get fullscreen(): boolean {
    return this._fullscreen;
  }
  set fullscreen(value: boolean) {
    this._fullscreen = value;
  }

  @Output() fullscreenChange = new EventEmitter<boolean>();

  get generatedUrl(): string {
    return `${this.frontendEndpoint}/page/${
      this.page.customID + '/' + encodeURI(this.page.title!)
    }`;
  }

  @ViewChild(ContextMenuComponent) contextMenu!: ContextMenuComponent;
  @ViewChild(SizesEditorComponent) sizeEditor!: SizesEditorComponent;
  @ViewChild('progressBar') progressBar!: ElementRef;
  @ViewChild('progressBarTh') progressBarTh!: ElementRef;
  @ViewChild('generatedLink') generatedLink!: ElementRef;

  previewTile?: Tile;
  tmpWave?: Wave;
  tilesFullScreen = false;
  BandeauType = [
    { value: 'image-mini', title: 'image-mini' },
    { value: 'image-full', title: 'image-full' },
  ];
  backgroundVideoHeader = '#39dbb3';
  backgroundImageBandeau = '#39dbb3';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private pageService: PagesService,
    private waveService: WavesService,
    private tileService: TilesService,
    private notify: NotifyService,
    private sortPipe: SortPipe,
    private changeDetector: ChangeDetectorRef,
    private publicApiService: PublicApiService,
    private userService: UserService
  ) {
    this.page = new FullPage();
    this.page.waves = [];

    this.route.params.subscribe((param) => {
      this.pageId = param.id;
      this.pageService.getFullPage(param.id).subscribe((page: FullPage) => {
        this.page = page;
        console.log(page);
      });
    });
    var contrib = localStorage.getItem(HttpConstants.Contributor);
    this.menuItems = [
      {
        label: 'Renomer',
        name: 'rename',
        icon: 'pen',
        callback: (wave: Wave) => {
          this.tmpWave = wave;
          this.toggleRenamePopup = true;
        },
      } as IMenuItem,
      {
        label: 'Permissions',
        name: 'perm',
        icon: 'list',
        callback: (wave: Wave) => {
          if (contrib && wave.tiersID) {
            if (
              wave.tiersID.includes(contrib) ||
              this.userService.user.profilType == 'Admin' ||
              this.checkPerm(
                wave.customID,
                'update',
                this.userService.user.customID
              )
            ) {
              this.router.navigateByUrl(
                '/perm/' + wave.customID + '/' + 'wave'
              );
            }
          }
          if (this.userService.user.profilType == 'Admin') {
            this.router.navigateByUrl('/perm/' + wave.customID + '/' + 'wave');
          }
        },
      } as IMenuItem,
      {
        label: 'Dupliquer',
        name: 'duplicate',
        callback: (wave: Wave) => {
          const clone = { ...wave };
          delete clone.customID;
          delete clone.createdAt;
          delete clone.updatedAt;
          clone.title = `${clone.title} (copie)`;
          clone.printOrder = this.countWaves();
          this.waveService
            .addWave(this.pageId, clone)
            .subscribe((response: BackendResponse) => {
              this.notify.success(`Vague dupliquée : "${clone.title}"`);
              this.pageService
                .getFullPage(this.pageId)
                .subscribe((page: FullPage) => {
                  this.page = page;
                });
            });
        },
        icon: 'copy',
      } as IMenuItem,
      {
        label: 'Exporter',
        name: 'export',
        icon: 'file-export',
        callback: (wave: Wave) => {
          console.log('Exporting, ID = ', wave.customID);
          // this.router.navigate([`export/${wave.customID}/wave`]);
          this.isExporting = true;
          this.exportId = wave.customID as string;
          this.exportItem = 'wave';
          // this.notify.success(`test`);
        },
      } as IMenuItem,
      {
        label: 'Supprimer',
        name: 'delete',
        callback: (wave: Wave) => {
          this.currentWave = undefined!;
          this.waveService.deleteWave(this.pageId, wave).subscribe(
            () => {
              this.notify.success(`Vague "${wave.title}" supprimée`);
              if (this.page.waves) {
                try {
                  for (
                    let i = this.page.waves.indexOf(wave);
                    i < this.pageArr.length;
                    i++
                  ) {
                    this.page.waves[i].printOrder -= 1;
                    this.waveService
                      .updateWave(this.page.customID || '', this.page.waves[i])
                      .subscribe(() => {
                        this.publicApiService.updateDone.next(false);
                        this.page.waves = this.sortPipe.transform(
                          this.page.waves!,
                          ['printOrder']
                        );
                      });
                  }
                } catch (error) {}
              }
              this.pageService
                .getFullPage(this.pageId)
                .subscribe((page: FullPage) => {
                  this.page = page;
                });
            },
            (error) => {
              this.notify.failed(`Erreur de suppression de la vague (Vérifiez vos droits)`);
            }
          );
        },
        icon: 'trash',
      } as IMenuItem,
    ];
  }

  updateForm(type: string): void {
    this.currentWave.bandeauType = type;
    console.log(this.currentWave);
  }

  async getFile(event: Event): Promise<void> {
    if (event.type === 'change') {
      const inputFile = event.target as HTMLInputElement;
      this.addMedia(inputFile.files![0]);
    }
  }

  async getFileBandeau(event: Event): Promise<void> {
    if (event.type === 'change') {
      const inputFile = event.target as HTMLInputElement;
      this.addMediaBandeau(inputFile.files![0]);
    }
  }
  async getFileVideoHeader(event: Event): Promise<void> {
    if (event.type === 'change') {
      const inputFile = event.target as HTMLInputElement;
      this.addMediaVideoHeader(inputFile.files![0]);
    }
  }

  async addMedia(media: File): Promise<string | undefined> {
    this.tileService.upload(media).subscribe((response) => {
      if (response.type === HttpEventType.Response) {
        if (response.body.messageType === 'File.upload.done') {
          this.currentWave.imageBandeau = response.body.message;
          this.notify.success(
            `"${this.currentWave.imageBandeau}" a été téléversé`
          );
          this.updateWave();
        } else {
          this.notify.failed(`Erreur de téléversement du média`);
        }
      }
    });
    return undefined;
  }

  async addMediaBandeau(media: File): Promise<string | undefined> {
    this.tileService.uploadBandeau(media).subscribe((response) => {
      if (response.type === HttpEventType.Response) {
        if (response.body.messageType === 'File.upload.done') {
          this.currentWave.imageBandeau = response.body.message;
          this.notify.success(
            `"${this.currentWave.imageBandeau}" a été téléversé`
          );
          this.updateWave();
        } else {
          this.notify.failed(`Erreur de téléversement du média`);
        }
      }
    });
    return undefined;
  }

  async addMediaVideoHeader(media: File): Promise<string | undefined> {
    this.tileService.uploadBandeau(media).subscribe((response) => {
      if (response.type === HttpEventType.Response) {
        if (response.body.messageType === 'File.upload.done') {
          let responsemsg = response.body.message;
          this.currentWave.videoHeader =
            'https://imagesettoutlereste.fra1.digitaloceanspaces.com/images/' +
            encodeURI(responsemsg);
          this.notify.success(`"${responsemsg}" a été téléversé`);
          this.updateWave();
        } else {
          this.notify.failed(`Erreur de téléversement du média`);
        }
      }
    });
    return undefined;
  }

  checkPerm(
    idElem: string | undefined,
    perm: string,
    tiersId: string
  ): boolean {
    var isPerm = false;
    if (tiersId === this.userService.user?.customID) {
      isPerm = true;
      return isPerm;
    }
    if (idElem) {
      isPerm = this.userService.checkPermission(idElem, perm);
    }
    return isPerm;
  }
  tilesCount(wave: Wave): string {
    return wave.tiles
      ? `${wave.tiles.length} tuile${wave.tiles.length > 1 ? 's' : ''}`
      : '&nbsp;';
  }

  countWaves(): number {
    return this.page.waves ? this.page.waves!.length + 1 : 1;
  }

  copyLink(): void {
    this.generatedLink.nativeElement.select();
    document.execCommand('copy');
    this.generatedLink.nativeElement.blur();
  }

  preview(tile: Tile): void {
    this.previewTile = tile;
  }

  addWaveDialog(): void {
    this.togglePopup = true;
  }

  waveTitleValid(title?: string): boolean {
    return title !== undefined && title.length > 3;
  }

  updateSizes(): void {}

  showContextMenu(mouseEvent: MouseEvent, wave: Wave): void {
    if (mouseEvent.type === 'mouseover') {
      this.contextMenu.X = mouseEvent.clientX;
      this.contextMenu.Y = mouseEvent.clientY;
      this.contextMenu.item = wave;
      this.contextMenu.toggle = true;
    }
  }

  refreshFront(): void {
    this.publicApiService.refreshPublicApi();
  }

  addWave(): void {
    if (this.newWaveTitle && this.newWaveTitle.length > 0) {
      const wave = new Wave();
      wave.title = this.newWaveTitle;
      wave.isShortList = true;
      wave.isRandomized = false;
      wave.isPrimary = false;
      wave.zoomWave = 50;
      wave.description = this.newWaveDescription;
      wave.printOrder = this.countWaves();
      this.waveService.addWave(this.pageId, wave).subscribe(
        (response: BackendResponse) => {
          this.notify.success(`Vague "${wave.title}" ajoutée`);
          this.pageService
            .getFullPage(this.pageId)
            .subscribe((page: FullPage) => {
              this.page = page;
            });
        },
        (error) => {
          this.notify.failed(`Erreur d'ajout de la vague`);
        }
      );
    }
  }

  moveWaveUp(event: MouseEvent, wave: Wave): void {
    event.stopPropagation();
    const previousWave = this.previousWave(wave);

    if (previousWave) {
      const mem = previousWave.printOrder;
      previousWave.printOrder = wave.printOrder;
      wave.printOrder = mem;
      this.waveService.updateWave(this.pageId, wave).subscribe(() => {
        this.waveService.updateWave(this.pageId, previousWave).subscribe(() => {
          this.publicApiService.updateDone.next(false);
          this.page.waves = this.sortPipe.transform(this.page.waves!, [
            'printOrder',
          ]);
        });
      });
    }
  }

  previousWave(wave: Wave): Wave | undefined {
    let previous: Wave | undefined;
    const index = this.page.waves!.findIndex(
      (w) => w.customID === wave.customID
    );
    if (index > 0) {
      previous = this.page.waves![index - 1];
    }
    return previous;
  }

  moveWaveDown(event: MouseEvent, wave: Wave): void {
    event.stopPropagation();
    const nextWave = this.nextWave(wave);

    if (nextWave) {
      const mem = nextWave.printOrder;
      nextWave.printOrder = wave.printOrder;
      wave.printOrder = mem;
      this.waveService.updateWave(this.pageId, wave).subscribe(() => {
        this.waveService.updateWave(this.pageId, nextWave).subscribe(() => {
          this.publicApiService.updateDone.next(false);
          this.page.waves = this.sortPipe.transform(this.page.waves!, [
            'printOrder',
          ]);
        });
      });
    }
  }

  nextWave(wave: Wave): Wave | undefined {
    let next: Wave | undefined;
    const index = this.page.waves!.findIndex(
      (w) => w.customID === wave.customID
    );
    if (index < this.page.waves!.length) {
      next = this.page.waves![index + 1];
    }
    return next;
  }

  updateOrder(value: string, wave: Wave): void {
    wave.printOrder = parseInt(value, 10);
    this.waveService.updateWave(this.pageId, wave).subscribe(() => {
      this.manageEditArray(wave.customID);
    });
  }

  edittingOrder(waveId?: string): boolean {
    if (waveId) {
      return this.editOrder.findIndex((e) => e === waveId) > -1;
    }
    return false;
  }

  private manageEditArray(waveId?: string): void {
    if (waveId) {
      const index = this.editOrder.findIndex((e) => e === waveId);
      if (index > -1) {
        this.editOrder.splice(index, 1);
      } else {
        this.editOrder.push(waveId);
      }
    }
  }

  toggleOrderEdit(event: MouseEvent, waveId?: string): void {
    event.stopPropagation();
    this.manageEditArray(waveId);
  }

  renameWave(): void {
    this.tmpWave!.title = this.currentWaveTitle;
    this.waveService.updateWave(this.pageId, this.tmpWave!).subscribe(
      () => {
        this.notify.success(`Vague "${this.currentWaveTitle!}" mise à jour`);
        this.tmpWave = undefined;
        this.toggleRenamePopup = false;
        this.publicApiService.updateDone.next(false);
      },
      (error) => {
        this.notify.failed(`Erreur de mise à jour de la vague`);
      }
    );
  }

  updateCategorieMenu(cat: any) {
    this.page.categoriePage = cat;
    this.updatePage();
  }

  updateColorsMenu(color: any) {
    this.page.menuColor = color;
    this.updatePage();
  }

  updateColorsMenuGradient(color: any) {
    this.page.menuColorGradient = color;
    this.updatePage();
  }

  updatePage(): void {
    const simplePage = { ...this.page };
    delete simplePage.waves;
    delete simplePage.createdAt;
    delete simplePage.updatedAt;

    this.pageService.updatePage(simplePage).subscribe(
      () => {
        this.notify.success(`Page "${simplePage.title}" mise à jour`);
        this.publicApiService.updateDone.next(false);
      },
      (error) => {
        this.notify.failed(`Erreur de mise à jour de la page`);
      }
    );
  }

  updatePageActivity(active: boolean): void {
    this.page.isActive = active;
    this.pageService.changeActive(this.page).subscribe();
  }

  updateWave(): void {
    console.log(this.currentWave);
    if (this.currentWave) {
      this.waveService.updateWave(this.pageId, this.currentWave).subscribe(
        () => {
          this.notify.success(`Vague "${this.currentWave.title}" mise à jour`);
          this.publicApiService.updateDone.next(false);
        },
        (error) => {
          this.notify.failed(`Erreur de mise à jour de la page`);
        }
      );
    }
  }

  switchWave(wave: Wave): void {
    if (
      this.checkPerm(wave.customID, 'read', wave.tiersID) ||
      wave.tiersID == this.userService.user.customID ||
      this.userService.user.profilType == 'Admin'
    ) {
      this.waveService.getWave(wave.customID!).subscribe((wave) => {
        this.currentWave = wave;
        this.selectedTile = undefined;
        this.waveTitle = wave.title;
      });
    } else {
      this.notify.failed(
        `Vous n'avez pas l'autorisation de consulter cette vague`
      );
    }
  }

  updateWaveActivity(active: boolean): void {
    if (
      this.checkPerm(
        this.currentWave.customID,
        'update',
        this.currentWave.tiersID
      )
    ) {
      this.currentWave.isActive = !this.currentWave.isActive;
      this.waveService
        .changeActive(this.pageId, this.currentWave)
        .subscribe((wave) => {
          const cachedWave = this.page.waves?.find(
            (w) => w.customID === this.currentWave.customID
          )!;
          cachedWave.isActive = this.currentWave.isActive;
        });
    } else {
      this.notify.failed(
        "Vous n'avez pas la permission de modifier cette vague"
      );
    }
  }

  updateWaveBandeauActivity(active: boolean): void {
    if (
      this.checkPerm(
        this.currentWave.customID,
        'update',
        this.currentWave.tiersID
      )
    ) {
      this.currentWave.bandeauIsActive = !this.currentWave.bandeauIsActive;
      this.waveService
        .changeActive(this.pageId, this.currentWave)
        .subscribe((wave) => {
          const cachedWave = this.page.waves?.find(
            (w) => w.customID === this.currentWave.customID
          )!;
          cachedWave.bandeauIsActive = this.currentWave.bandeauIsActive;
        });
    } else {
      this.notify.failed(
        "Vous n'avez pas la permission de modifier cette vague"
      );
    }
    this.updateWave();
  }

  updateWaveVideoBandeauActivity(active: boolean): void {
    if (
      this.checkPerm(
        this.currentWave.customID,
        'update',
        this.currentWave.tiersID
      )
    ) {
      this.currentWave.bandeauVideoIsActive =
        !this.currentWave.bandeauVideoIsActive;
      this.waveService
        .changeActive(this.pageId, this.currentWave)
        .subscribe((wave) => {
          const cachedWave = this.page.waves?.find(
            (w) => w.customID === this.currentWave.customID
          )!;
          cachedWave.bandeauVideoIsActive =
            this.currentWave.bandeauVideoIsActive;
        });
    } else {
      this.notify.failed(
        "Vous n'avez pas la permission de modifier cette vague"
      );
    }
    this.updateWave();
  }

  updateRandWaveActivity(active: boolean): void {
    if (
      this.checkPerm(
        this.currentWave.customID,
        'update',
        this.currentWave.tiersID
      )
    ) {
      this.currentWave.isRandomized = !this.currentWave.isRandomized;
      this.waveService
        .changeRandomized(this.pageId, this.currentWave)
        .subscribe((wave) => {
          const cachedWave = this.page.waves?.find(
            (w) => w.customID === this.currentWave.customID
          )!;
          cachedWave.isRandomized = this.currentWave.isRandomized;
        });
    } else {
      this.notify.failed(
        "Vous n'avez pas la permission de modifier cette vague"
      );
    }
    this.updateWave();
  }

  updateIsPrimary(active: boolean): void {
    if (
      this.checkPerm(
        this.currentWave.customID,
        'update',
        this.currentWave.tiersID
      )
    ) {
      this.currentWave.isPrimary = !this.currentWave.isPrimary;
      this.waveService
        .changePrimary(this.pageId, this.currentWave)
        .subscribe((wave) => {
          const cachedWave = this.page.waves?.find(
            (w) => w.customID === this.currentWave.customID
          )!;
          cachedWave.isPrimary = this.currentWave.isPrimary;
        });
    } else {
      this.notify.failed(
        "Vous n'avez pas la permission de modifier cette vague"
      );
    }
    this.updateWave();
  }

  removeWave(wave: Wave): void {
    this.waveService.deleteWave(this.pageId, wave).subscribe(
      (done) => {
        this.notify.success(`Vague "${wave.title}" supprimée`);
        this.publicApiService.updateDone.next(false);
        this.pageService.getFullPage(this.pageId).subscribe();
        this.currentWave = undefined!;
      },
      (error) => {
        this.notify.failed(`Erreur de suppression de la vague (Vérifiez vos droits)`);
      }
    );
  }

  removeBandeau(): void {
    this.currentWave.bandeau = undefined;
    this.currentWave.imageBandeau = undefined;
    this.currentWave.descriptionBandeau = undefined;
    this.currentWave.backgroundImageBandeau = undefined;
    this.updateWave();
  }

  removeBandeauVideo(): void {
    this.currentWave.videoHeader = undefined;
    this.currentWave.backgroundVideoHeader = undefined;
    this.updateWave();
  }

  editTile(tile: Tile): void {
    if (tile.customID === undefined && this.currentWave.tiles) {
      tile.printOrder = this.currentWave.tiles.length + 1;
    }
    this.selectedTile = tile;
  }

  refreshWave(tile: Tile): void {
    let change = this.currentWave.tiles.find(
      (t) => t.customID === tile.customID
    );
    change = tile;
  }

  prepareTileExport(id: string): void {
    this.exportItem = 'tile';
    this.isExporting = true;
    this.exportId = id;
    this.pageTitle = this.page.title;
    this.waveTitle = this.currentWave.title;
    console.log('AAAAAAAAAAAAAAAAAAAA', this.waveTitle);
  }

  exportChoice(options: { pageId: string; waveId?: string }): void {
    switch (this.exportItem) {
      case 'wave':
        this.waveService.duplicateWave(options.pageId, this.exportId).subscribe(
          (response: BackendResponse) => {
            this.notify.success(`Vague exportée`);
            this.pageService
              .getFullPage(this.pageId)
              .subscribe((page: FullPage) => {
                this.page = page;
                this.isExporting = false;
              });
          },
          (error) => {
            this.notify.failed(`Erreur d'export de la vague`);
            this.isExporting = false;
          }
        );
        break;
      case 'bandeau':
        if (options.waveId != undefined) {
          this.waveService
            .getWave(options.waveId)
            .toPromise()
            .then(
              (data) => {
                let newWaveWithBandeau: Wave;
                newWaveWithBandeau = data;
                newWaveWithBandeau.backgroundImageBandeau =
                  this.exportBandeauInWave.backgroundImageBandeau;
                newWaveWithBandeau.bandeau = this.exportBandeauInWave.bandeau;
                newWaveWithBandeau.bandeauIsActive = true;
                newWaveWithBandeau.bandeauType =
                  this.exportBandeauInWave.bandeauType;
                newWaveWithBandeau.descriptionBandeau =
                  this.exportBandeauInWave.descriptionBandeau;
                newWaveWithBandeau.imageBandeau =
                  this.exportBandeauInWave.imageBandeau;
                newWaveWithBandeau.backgroundVideoHeader =
                  this.exportBandeauInWave.backgroundVideoHeader;
                newWaveWithBandeau.bandeauVideoIsActive =
                  this.exportBandeauInWave.bandeauVideoIsActive;
                newWaveWithBandeau.videoHeader =
                  this.exportBandeauInWave.videoHeader;
                newWaveWithBandeau.isActive = true;
                console.log(data);
                this.waveService
                  .updateWave(options.pageId, newWaveWithBandeau)
                  .subscribe((response: BackendResponse) => {
                    this.notify.success(`Bandeau exportée`);
                    this.pageService
                      .getFullPage(this.pageId)
                      .subscribe((page: FullPage) => {
                        this.page = page;
                        this.isExporting = false;
                      });
                  });
              },
              (error) => {
                this.notify.failed(`Erreur d'export de la vague`);
                this.isExporting = false;
              }
            );
        }
        break;
      case 'tile':
        this.tileService
          .duplicateTile(
            options.pageId,
            options.waveId as string,
            this.exportId
          )
          .subscribe((response: BackendResponse) => {
            this.notify.success(`Tuile exportée`);
            this.isExporting = false;
          });
        break;
      default:
        this.isExporting = false;
        break;
    }
  }

  cancelExport(): void {
    this.isExporting = false;
  }

  ngOnInit(): void {
    this.publicApiService.updateDone.subscribe((done: boolean) => {
      this.hasNoChange = done === false ? undefined : true;
      //console.log(done, this.hasNoChange);
    });
    this.permUpdate = this.checkPerm(this.pageId, 'update', this.pageId);
    this.permDelete = this.checkPerm(this.pageId, 'delete', this.pageId);
    this.pageTitle = this.page.title;
  }

  onColorBandeauVideoChange(value: any) {
    console.log(' Value is : ', value.target.value);
    this.backgroundVideoHeader = value.target.value;
    this.currentWave.backgroundVideoHeader = this.backgroundVideoHeader;
    this.updateWave();
  }
  onColorBnadeauChange(value: any) {
    console.log(' Value is : ', value.target.value);
    this.backgroundImageBandeau = value.target.value;
    this.currentWave.backgroundImageBandeau = this.backgroundImageBandeau;
    this.updateWave();
  }

  toggleFullscreen(): void {
    this._fullscreen = !this._fullscreen;
    this.fullscreenChange.emit(this._fullscreen);
  }

  exportBandeau(wave: Wave) {
    this.exportItem = 'bandeau';
    this.isExporting = true;
    this.exportBandeauInWave = wave;
    this.pageTitle = this.page.title;
    this.waveTitle = this.currentWave.title;
    console.log(wave);
  }
}
