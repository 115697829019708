import { Status } from '@enums/status';

export class ApiNotification {
  status: Status;
  message: string;
  initialMessage: string;
  constructor() {
    this.status = Status.Unknonwn;
    this.initialMessage = this.message = '';
  }
}
