import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FooterBackground, FooterService } from '@services/footer.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit,OnDestroy,AfterViewInit {
  title = 'backoffice';
  FooterSubscription:any;

  @HostListener('window:ondrop', ['$event'])
  preventDrop(event: DragEvent) {
    event.preventDefault();
  }

  @ViewChild("BlueConnected") BlueConnected! : ElementRef
  @ViewChild("RedConnexion") RedConnexion! : ElementRef

  constructor(private FooterService:FooterService){}
  ngAfterViewInit(): void {
    this.FooterSubscription = this.FooterService.FooterModebis.subscribe( 
      data => 
      {

        if (data == FooterBackground.BlueConnected)
        {
          this.BlueConnected.nativeElement.style.display = "block"
          this.RedConnexion.nativeElement.style.display = "none";
        }
        else if (data == FooterBackground.RedConnexion)
        {
          this.RedConnexion.nativeElement.style.display = "block";
          this.BlueConnected.nativeElement.style.display = "none";
        }
      }
    )
  }
  
  ngOnDestroy(): void {
    //this.FooterSubscription.unsubscribe()
  }
  
  ngOnInit(): void {
    
  }

}
