import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[required]'
})
export class RequiredDirective implements OnInit {
  label: HTMLLabelElement | null;

  @HostListener('ngModelChange', ['$event'])
  ngModelChange(value?: any): void {
    this.check(value);
  }



  private check(value?: any): void {
    if (this.label !== null) {
      if (!value) {
        this.label!.classList.add('required');
      } else {
        this.label!.classList.remove('required');
      }
    }

  }
  constructor(private el: ElementRef, private control: NgControl) {
    this.label = (this.el.nativeElement.previousSibling) as HTMLLabelElement || (this.el.nativeElement.nextSibling) as HTMLLabelElement;

  }

  ngOnInit(): void {
    setTimeout(() => {
      this.check(this.control.value || this.el.nativeElement.value);
    });
  }

}
