import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  private FooterMode:BehaviorSubject<FooterBackground>
  FooterModebis : Observable<FooterBackground>
  constructor() {
    this.FooterMode = new BehaviorSubject<FooterBackground>(2);
    this.FooterModebis = this.FooterMode.asObservable();
  }
  
  SetFooterMode(mode:FooterBackground){
    this.FooterMode.next(mode)
  }


}

export enum FooterBackground
{
  RedConnexion = 1,
  BlueConnected = 2,
}
