<h1 *ngIf="page">Gestion des permissions : <span>{{type}} {{page.title}}</span></h1>
<table mat-table class="mat-elevation-z8" matSort [dataSource]="dataSource">

  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Prénom </th>
    <td mat-cell *matCellDef="let user"> {{user.firstName}} </td>
  </ng-container>


  <ng-container matColumnDef="lastName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
    <td mat-cell *matCellDef="let user"> {{user.lastName}} </td>
  </ng-container>

  <ng-container matColumnDef="profil">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Profil </th>
    <td mat-cell *matCellDef="let user">
      <span> {{user.profilType}}</span>
    </td>
  </ng-container>


  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
    <td mat-cell *matCellDef="let user"> {{user.email}} </td>
  </ng-container>

  <ng-container matColumnDef="companyName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom entreprise </th>
    <td mat-cell *matCellDef="let user"> {{user.companyName}} </td>
  </ng-container>


  <ng-container matColumnDef="phoneNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Téléphone </th>
    <td mat-cell *matCellDef="let user"> {{user.phoneNumber}} </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Permissions </th>
    <td mat-cell *matCellDef="let user">
<!--      <button routerLink="edit/{{user.customID}}" class="btn btn-sm btn-primary mr-1">Modifier</button>-->
      <mat-checkbox (change)="setPerm(user,'read')" [checked]="isPerm(user,'read')"><span>Lire</span></mat-checkbox>
      <mat-checkbox (change)="setPerm(user,'update')" [checked]="isPerm(user,'update')"><span>Modifier</span></mat-checkbox>
      <mat-checkbox (change)="setPerm(user,'delete')" [checked]="isPerm(user,'delete')"><span>Supprimer</span></mat-checkbox>
      <mat-checkbox (change)="setPerm(user,'import')" [checked]="isPerm(user,'import')"><span>Importer</span></mat-checkbox>
      <button (click)="checkAll(user)">Tous</button>
      <button *ngIf="canAddUser()" routerLink="/list/add" class="btn btn-sm btn-primary mr-1">Ajouter un utilisateur</button>
      <!-- (ngModelChange)="" -->
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


</table>
<mat-paginator #paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
