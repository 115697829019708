export class TileStyle {
  public static readonly styles = [
    {value:'image', title:'Image'},
    {value:'video', title:'Video'},
    {value:'iframe', title:'IFrame'},
    {value:'embedded', title:'Embarqué'}, 
    {value:'advertizer', title:'Annonceur'},
    {value:'edit', title:'Editable'}
  ]
}
