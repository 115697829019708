import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Perm } from '@models/perm';
import { NotifyService } from '@services/notify.service';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-tile-perms',
  templateUrl: './tile-perms.component.html',
  styleUrls: ['./tile-perms.component.scss']
})
export class TilePermsComponent implements OnInit {
  columnsToDisplay: string[] = ['title', "isActive", "perms"];
  tiles : any;
  waveName : any;
  user : any;

  constructor(private userService: UserService,
    private notifyService: NotifyService,
    @Inject(MAT_DIALOG_DATA) public data: {tiles: any, waveTitle: any, user:any}) {
    this.tiles = data.tiles;
    this.waveName = data.waveTitle;
    this.user = data.user;
  }

  ngOnInit(): void {
  }

  checkAll(tile: any) {
    let perms = ["read", "update", "import"];
    perms.forEach(perm => {
      this.removePerm(tile, perm);
      this.addPerm(tile, perm);
    })
  }

  checkAllTilesWithOnePerm(perm: any){
    this.tiles.forEach((tile: any) => {
      if (this.hasPerm(tile, perm)) {
      } else {
        this.addPerm(tile, perm);
      }
    })
  }

  hasPerm(tile: any, perm: any) {
    let aPerm;
    if (this.user.permissions) {
      aPerm = this.user.permissions.find((e: { customIDElem: any; }) => e.customIDElem === tile.customID);
    }
    if (aPerm) {
      if (aPerm.permissions.includes(perm)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  setPerm(tile: any, permission: any) {
    if (this.hasPerm(tile, permission)) {
      console.log("remove perm")
      this.removePerm(tile, permission)
    }
    else {
      console.log("Add perm")
      this.addPerm(tile, permission)
    }
  }

  addPerm(tile: any, permission: any) {
    var i;
    var aPerm;
    var customId;
    i = this.user.permissions.findIndex((e: { customIDElem: any; }) => e.customIDElem === tile.customID);
    aPerm = this.user.permissions.find((e: { customIDElem: any; }) => e.customIDElem === tile.customID);
    customId = tile.customID
    if (i && aPerm) {
      if (!aPerm.permissions.includes(permission)) {
        this.user.permissions[i].permissions = aPerm.permissions + permission + '_'
      }
    }
    else {
      if (customId) {
        this.user.permissions.push(new Perm(customId, permission + '_'))
      }
    }
    console.log(this.user)
    this.userService.update(this.user.customID, this.user)
      .subscribe({
        next: () => {
          this.notifyService.success("Permission " + permission + " ajoutée  ")
        },
        error: () => {
          this.notifyService.failed("Une erreur est survenue")
        }
      });
  }

  removePerm(tile: any, permission: any) {
    var i;
    var aPerm;
    i = this.user.permissions.findIndex((e: { customIDElem: any; }) => e.customIDElem === tile.customID);
    aPerm = this.user.permissions.find((e: { customIDElem: any; }) => e.customIDElem === tile.customID);
    if (i && aPerm) {
      this.user.permissions[i].permissions = this.user.permissions[i].permissions.replace(permission + '_', "")
      //user.permissions.splice(i, 1)
    }
    console.log(this.user);
    this.userService.update(this.user.customID, this.user)
      .subscribe({
        next: () => {
          this.notifyService.success("Permission " + permission + " supprimée !  ")
        },
        error: () => {
          this.notifyService.failed("Une erreur est survenue")
        }
      });
  }
}
