import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ContextMenuComponent } from '@components/context-menu/context-menu.component';
import { IMenuItem } from '@interfaces/components/IMenuItem';
import { Page } from '@models/page';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  menuItems:IMenuItem[] 

  constructor(private router: Router) { 

  this.menuItems = [
    {
      label: 'Voir la page',
      name: 'see',
      icon: 'link'
    } as IMenuItem];

  }

  @ViewChild(ContextMenuComponent) contextMenu!: ContextMenuComponent;

  ngOnInit(): void {
  }

  public redirectToHome() {
    this.router.navigate(['/pages']);
  }


  showContextMenu(mouseEvent: MouseEvent): void {
    this.contextMenu.Y = 50;
    this.contextMenu.toggle = true;
  }
}
