import { AuthService } from '@services/auth.service';
import { RESTFulObject } from '@models/restful';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IData } from '@interfaces/data';
import { ConfigService } from './config.service';
import { HttpConstants } from '../constants/http.constants';
import { BackendResponse } from '@models/response';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-type': 'application/json'
      }
    )
  };
  constructor(protected http: HttpClient,
              protected config: ConfigService,
              protected authService: AuthService,
              ) {

  }

  protected get<T>(item: string): Observable<T> {
    if (this.authService.token) {
      this.httpOptions.headers = this.httpOptions.headers.set(HttpConstants.AccessToken, this.authService.token);
    }

    return this.http.get<RESTFulObject>(`${this.config.endpoint}/${this.config.extension(item)}`, this.httpOptions).pipe(
      map((object: RESTFulObject) => {
        return object.data as T;
      })
    );
  }

  public upload<T>(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('mediaFile', file, file.name);
    let options = {
      headers: new HttpHeaders(),
      reportProgress: true,
      observe: 'events'
    } as any;

    options.headers = options.headers.set(HttpConstants.AccessToken, this.authService.token);

    return this.http.post<any>(`${this.config.endpoint}/file`, formData, options).pipe(
      tap((event: HttpEvent<any>) => {
      })
    );
  }

  public uploadBandeau<T>(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('mediaFile', file, file.name);
    let options = {
      headers: new HttpHeaders(),
      reportProgress: true,
      observe: 'events'
    } as any;

    options.headers = options.headers.set(HttpConstants.AccessToken, this.authService.token);

    return this.http.post<any>(`${this.config.endpoint}/fileBandeau`, formData, options).pipe(
      tap((event: HttpEvent<any>) => {
      })
    );
  }

  protected post<T>(item: string, body: any, header?: HttpHeaders): Observable<any> {
    if (this.authService.token) {
      this.httpOptions.headers = this.httpOptions.headers.set(HttpConstants.AccessToken, this.authService.token);
    }
    const post = this.http.post(`${this.config.endpoint}/${item}`, body, this.httpOptions);
    post.pipe(
      tap(() => this.http.get(`${this.config.endpoint}/cachereload`, this.httpOptions).subscribe())
    );
    return post;
  }

  protected put<T>(item: string, body: any, header?: HttpHeaders): Observable<any> {
    if (this.authService.token) {
      this.httpOptions.headers = this.httpOptions.headers.set(HttpConstants.AccessToken, this.authService.token);
    }
    const put = this.http.put(`${this.config.endpoint}/${item}`, body, this.httpOptions);
    put.pipe(
      tap(() => this.http.get(`${this.config.endpoint}/cachereload`, this.httpOptions).subscribe())
    );
    return put;
  }

  protected delete<T>(item: string): Observable<any> {
    if (this.authService.token) {
      this.httpOptions.headers = this.httpOptions.headers.set(HttpConstants.AccessToken, this.authService.token);
    }
    const request = this.http.delete(`${this.config.endpoint}/${item}`, this.httpOptions);
    request.pipe(
      tap(() => this.http.get(`${this.config.endpoint}/cachereload`, this.httpOptions).subscribe())
    );
    return request;
  }
}
