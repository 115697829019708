import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Credentials } from '@models/credentials';
import { AuthService } from '@services/auth.service';
import { FooterBackground, FooterService } from '@services/footer.service';
import {NotifyService} from "@services/notify.service";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  signIn:boolean = false;
  credentials: Credentials;
  error: string;
  constructor(private authService: AuthService,
              private router: Router,
              private FooterService:FooterService,
              private notify: NotifyService,
  ) {
    this.credentials = new Credentials();
    this.error  = '';
  }

  async ngOnInit(): Promise<void> {
    // if (this.authService.isLoggedIn() && await this.authService.checkTokenValidity()) {
    //   this.router.navigate(['pages']);
    // }
  }

  onSubmit(): void{
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.credentials.login)){
      this.signIn = true;
      setTimeout(() => {
        this.authService.resetPassword(this.credentials.login).then(logged => {
        });
      }, 5000);
    }else{
      this.notify.failed(`Veuillez vérifier le champ saisi`);
    }
  }
}
