export const environment = {
  production: false,
  endpoint:'https://preprod-admin.mediashow.fr',
  publicapi: 'https://preprod-api.mediashow.fr',
  frontendEndPoint: 'https://beta1.mediashow.fr',
  EnvName : 'Preprod',
  offline: false,
  path: undefined,
  extension: undefined
};
