import { ReplacePipe } from './pipes/replace.pipe';
import { JwplayerComponent } from './components/jwplayer/jwplayer.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { ContentManagerComponent } from './pages/content-manager/content-manager.component';
import { TileComponent } from './components/tile/tile.component';
import { WaveComponent } from './components/wave/wave.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { PagesComponent } from './pages/content-manager/pages/pages.component';
import { UserParamsComponent } from './components/user-params/user-params.component';
import { PageSummaryComponent } from './components/page-summary/page-summary.component';
import { PopupComponent } from './components/popup/popup.component';
import { YesnoComponent } from './components/yesno/yesno.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { PageEditComponent } from '@pages/content-manager/pages/page-edit/page-edit.component';
import { ChipComponent } from './components/chip/chip.component';
import { TileListComponent } from './components/tile-list/tile-list.component';
import { TileEditorComponent } from './components/tile-editor/tile-editor.component';
import { TilePreviewComponent } from './components/tile-preview/tile-preview.component';
import { SafePipe } from './pipes/safe.pipe';
import { TermFilterPipe } from './pipes/term-filter.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { SizesEditorComponent } from './components/sizes-editor/sizes-editor.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { NotifierComponent } from './components/notifier/notifier.component';
import { KPIComponent } from './components/kpi/kpi.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { RequiredDirective } from './required.directive';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RegisterComponent } from './pages/register/register.component';
import { ListComponent } from './components/users/list/list.component';
import { JwtInterceptor } from './http-interceptor';
import { AddEditComponent } from '@components/users/add-edit/add-edit.component';
import { RegisterproComponent } from '@pages/register/registerpro.component';
import { AlertComponent } from '@components/alert/alert.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { ListPermComponent } from './components/users/listPerm/list-perm/list-perm.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ExportChoiceComponent } from '@components/export-choice/export-choice.component';
import { UserPermsComponent } from './components/user-perms/user-perms.component';
import { WavePermsComponent } from './components/wave-perms/wave-perms.component';
import { TilePermsComponent } from './components/tile-perms/tile-perms.component';
import { ConfTemplateComponent } from './components/conf-template/conf-template.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { GroupRadioButtonsStyleComponent } from './components/group-radio-buttons-style/group-radio-buttons-style.component';
import { OverlayFactoryComponent } from './components/overlay-factory/overlay-factory.component';
import { BoxComponent } from './components/box/box.component';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ResetPasswordConfirmationComponent } from './components/reset-password-confirmation/reset-password-confirmation.component';
import { RegisterConfirmationEmailComponent } from './components/register-confirmation-email/register-confirmation-email.component';
import { BandeauVagueComponent } from './components/bandeau-vague/bandeau-vague.component';
// import * as CanvasJSAngularChart from '../assets/canvasjs.angular.component';
// var CanvasJSChart = CanvasJSAngularChart.CanvasJSChart;
// import { NgChartsModule } from 'ng2-charts';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ContentManagerComponent,
    TileComponent,
    WaveComponent,
    HeaderComponent,
    PagesComponent,
    UserParamsComponent,
    // CanvasJSChart,
    // NgChartsModule,
    PageSummaryComponent,
    PopupComponent,
    YesnoComponent,
    LogoutComponent,
    PageEditComponent,
    ContextMenuComponent,
    ChipComponent,
    TileListComponent,
    TileEditorComponent,
    TilePreviewComponent,
    SafePipe,
    TermFilterPipe,
    SortPipe,
    SizesEditorComponent,
    SpinnerComponent,
    NotifierComponent,
    KPIComponent,
    JwplayerComponent,
    RequiredDirective,
    ReplacePipe,
    FeedbackComponent,
    RegisterComponent,
    RegisterproComponent,
    ListComponent,
    AddEditComponent,
    AlertComponent,
    ListPermComponent,
    ExportChoiceComponent,
    UserPermsComponent,
    WavePermsComponent,
    TilePermsComponent,
    ConfTemplateComponent,
    GroupRadioButtonsStyleComponent,
    OverlayFactoryComponent,
    BoxComponent,
    SanitizeHtmlPipe,
    ResetPasswordComponent,
    ResetPasswordConfirmationComponent,
    RegisterConfirmationEmailComponent,
    BandeauVagueComponent,
  ],
  entryComponents: [PopupComponent, YesnoComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatTabsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatDialogModule,
    AngularEditorModule,
  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    Storage,
    RegisterComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
