import { AuthService } from '@services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FooterBackground, FooterService } from '@services/footer.service';
import {UserService} from "@services/user.service";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private authService: AuthService,private FooterService:FooterService) {
    this.authService.logout();
  }

  ngOnInit(): void {
    this.FooterService.SetFooterMode(FooterBackground.RedConnexion);
  }

}
