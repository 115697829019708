<div *ngIf="tile" class="layout">
  <div class="header">

    <div class="edit"><label>Nom de la tuile</label><input required type="text" (ngModelChange)="updateTitle($event)"
        [ngModel]="tile.title" class="overedit"><i class="fa fa-pen"></i>
      <i *ngIf="tile.customID" (click)="preview()" class="round fa fa-eye"></i>
    </div>

    <div class="tools">
      <i *ngIf="tile.customID" (click)="deleteTile($event, tile)" class="remove fa fa-trash"></i>
    </div>
  </div>
  <div #custom class="row">
    <label>Code personnalisé de la tuile</label>
    <input type="text" class="overedit" [(ngModel)]="tile.customCode" /><i class="fa fa-pen"></i>
  </div>
  <div #target class="row">
    <label>Couleur de fond</label>
    <div class="colorTools">
      <input type="color" (ngModelChange)="updateColor($event)" [ngModel]="tile.backgroundColor" />
      <i (click)="resetColor($event)" class="fa fa-times"></i>
    </div>

  </div>
  <div #blured class="row">
    <label>Fond flouté</label>
    <label class="switch">
      <input (ngModelChange)="updateBlur($event)" [ngModel]="tile.backgroundBlurred" type="checkbox">
      <span class="slider round"></span>
    </label>
  </div>
  <div #isYoutubeEmbed class="row" *ngIf="canEnableYtEmbed()">
    <label>Embed Youtube</label>
    <label class="switch">
      <input (ngModelChange)="updateYtEmbed($event)" [ngModel]="tile.isYoutubeEmbed" type="checkbox">
      <span class="slider round"></span>
    </label>
  </div>
  <div #type class="row">
    <label>Type de la tuile</label>
    <select required (ngModelChange)="updateForm($event)" [ngModel]="tile.type">
      <option [selected]="tile.type === style.value" [value]="style.value" *ngFor="let style of TileStyle.styles">
        {{style.title}}</option>
    </select>
  </div>
  <div #jwplayer class="row">
    <label>Lecteur JW player ou Navigateur</label>
    <div class="labeled">
      <div class="switch-label">{{tile.useJWPlayer ? 'JW Player' : 'Navigateur'}}</div>
      <label class="switch">
        <input [(ngModel)]="tile.useJWPlayer" type="checkbox">
        <span class="slider round"></span>
      </label>
    </div>
  </div>
  <div #iframeVideo class="row">
    <label>Contenu Vidéo</label>
    <label class="switch">
      <input [(ngModel)]="tile.iframeVideo" type="checkbox">
      <span class="slider round"></span>
    </label>
  </div>
  <div style="display: none;" class="row">
    <label for="">Description</label>
    <input type="text" [(ngModel)]="tile.description" class="overedit">
    <i class="fa fa-pen"></i>
  </div>
  <div #format class="row">
    <label>Format de la tuile</label>
    <select required (ngModelChange)="updateTileSize($event)" [ngModel]="tile.sizeID">
      <option [selected]="size.customID === tile.sizeID" [value]="size.customID" *ngFor="let size of sizes">
        {{size.title}}</option>
    </select>
  </div>
  <div #target class="row">
    <label>URL annonceur</label>
    <input required type="text" class="overedit" [(ngModel)]="tile.target" /><i class="fa fa-pen"></i>
  </div>
  <div #url class="row">
    <label>URL Vue détail</label>
    <input required type="text" class="overedit" [(ngModel)]="tile.url" /><i class="fa fa-pen"></i>
  </div>
  <div #editTemplate class="row">
    <label>Configuration du template</label>
    <button (click)="ConfigurationEditTemplatePopUp()">Ouvrir</button>
    <ng-container #containerConfigurationEditTemplate></ng-container>
  </div>
  <div #picture class="group">

    <div #mediapath class="row">
      <label>Chemin du média</label>
      <a *ngIf="hasTargetMedia()" target="_blank" href="{{tile.fileName}}">{{tile.fileName}}</a>
    </div>
    <div #fileInput class="row">
      <label>Importer le contenu de la tuile</label>
      <input [attr.disabled]="!checkIntegrity() ? false : undefined" (change)="getFile($event)" type="file" /><i
        class="fa fa-floppy"></i>
    </div>
    <div #thumbnail class="row">
      <label>Convertir en miniature</label>
      <label class="switch">
        <input [(ngModel)]="tile.resize" type="checkbox">
        <span class="slider round"></span>
      </label>
    </div>
    <div *ngIf="fileProgress > -1" class="row">
      <div #progressBar class="progress">
        {{fileProgress}}%
      </div>
    </div>
    <div #dropImageContent [attr.disabled]="!checkIntegrity() ? false : undefined"
      [ngClass]="{'dragged':fileDragged, 'forbidden':badformat}" (dragend)="stopDrag()" (dragleave)="stopDrag()"
      (dragover)="dragOverHandler($event)" (drop)="dropFile($event)" [ngClass]="{'video':tile.type === 'video'}"
      class="row drop">
      <div class="message" *ngIf="!badformat else forbidden">
        Glissez votre fichier ici
      </div>
      <ng-template #forbidden><i class="fa fa-ban"></i></ng-template>
    </div>
    <div #fileThInput class="row">
      <label>Importer une miniature</label>
      <input [attr.disabled]="!checkIntegrity() ? false : undefined" (change)="getThFile($event)" type="file" /><i
        class="fa fa-floppy"></i>
    </div>
    <div *ngIf="fileProgressTh > -1" class="row">
      <div #progressBarTh class="progress">
        {{fileProgressTh}}%
      </div>
    </div>
    <div #urlth class="row">
      <label>URL Image tuile / miniature</label>
      <input type="text" class="overedit" [(ngModel)]="tile.thFileName" /><i class="fa fa-pen"></i>
    </div>
  </div>
  <div #embedded class="row embedded">
    <label>Code embarqué</label>
    <textarea (input)="SrcModif($event.target)" value="{{tile.embedded}}"></textarea>
  </div>
  <div class="row">
    <label>Ajouter une surcouche</label>
    <button (click)="ConfigurationOverlayPopUp()">Ouvrir</button>
    <ng-container #containerOverlayConfiguration></ng-container>
  </div>
</div>
<app-yesno [disabled]="!checkIntegrity()" (onCancel)="cancel()" (onValidate)="updateTile()"></app-yesno>
<app-spinner *ngIf="waiting"></app-spinner>
