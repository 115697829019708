import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Credentials } from '@models/credentials';
import { AuthService } from '@services/auth.service';
import { FooterBackground, FooterService } from '@services/footer.service';
import { NavigationEnd } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpConstants } from '../../constants/http.constants';
import { map } from 'rxjs/operators';
import { ConfigService } from '@services/config.service';
import jwt_decode from 'jwt-decode';
import { NotifyService } from '@services/notify.service';

@Component({
  selector: 'app-reset-password-confirmation',
  templateUrl: './reset-password-confirmation.component.html',
  styleUrls: ['./reset-password-confirmation.component.scss'],
})
export class ResetPasswordConfirmationComponent implements OnInit {
  signIn: boolean = false;
  ValidToken = false;
  token: string | null;
  cleanStr = '';
  credentials: any;
  error: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private FooterService: FooterService,
    private http: HttpClient,
    private notify: NotifyService,
    private config: ConfigService,
    private route: ActivatedRoute
  ) {
    this.error = '';
    this.cleanStr = router.url;
    this.credentials = {};
    this.credentials.password = '';
    this.credentials.passwordConfirm="";

    // @ts-ignore
    const result = this.route.snapshot.paramMap.get('token');

    this.token = this.route.snapshot.paramMap.get('token');

    // if (this.token != null) {
    //   let tokenInfo = this.getDecodedAccessToken(this.token)
    //   this.email = tokenInfo.sub;
    //   console.log(this.email);
    //   localStorage.setItem('access-token', this.token);
    // }
  }

  // getDecodedAccessToken(token: string): any {
  //   try{
  //     return jwt_decode(token);
  //   }
  //   catch(Error){
  //     return null;
  //   }
  // }

  async ngOnInit(): Promise<void> {
    if (this.token != null) {
      // localStorage.setItem('access-token', this.token);
      console.log('oui');
    }

    // setTimeout(() => {

    //   //let tokenInfo = this.getDecodedAccessToken(this.token)
    //   //localStorage.setItem('', tokenInfo.contributor);
    //   this.checkTokenValidity()
    // }, 3000)
  }

  // async checkTokenValidity(): Promise<boolean> {
  //   let isValid = this.ValidToken ;
  //   if(!this.ValidToken && this.token != null) {
  //     const options = { headers: new HttpHeaders() };
  //     options.headers = options.headers.append(HttpConstants.AccessToken, this.token);
  //     try {
  //       await this.http.post<string>(`${this.config.endpoint}/token`, {}, options).toPromise().then(data => {
  //         console.log(data)
  //       })
  //     } catch(e) {
  //       console.log(e)
  //       console.log('le token n\'est pas valide');
  //     }
  //     console.log('le token est valide');
  //   }
  //   return isValid;
  // }

  onSubmit(): void {
    this.error = '';
    if(this.credentials.password != this.credentials.passwordConfirm) {
      this.error = "Le mot de passe et la confirmation du mot de passe sont different"
      return;
    }
    this.authService
      .resetPasswordConfirmation(this.token || '', this.credentials.password)
      .then((logged) => {
        console.log(logged);
        this.notify.success(`Mot de passe modifié avec succès`);
        setTimeout(() => {
          this.router.navigateByUrl('/login');
        }, 1500);
      })
      .catch((err) => {
        this.error = err;
      });

  }
}
