import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-group-radio-buttons-style',
  templateUrl: './group-radio-buttons-style.component.html',
  styleUrls: ['./group-radio-buttons-style.component.scss']
})
export class GroupRadioButtonsStyleComponent implements OnInit {

  @Input("data") DataIn:RadioButtonValue[] = [];
  @Output() SendOutput = new EventEmitter<any>()

  constructor() { }

  ngOnInit(): void {
    this.DataIn.map( x => x.value = x.value == undefined ? x.text : x.value)
  }

  InputSelect(event:any){
    this.SendOutput.emit(event)
  }

}

export interface RadioButtonValue
{
  text:string;
  value?:string;
}
