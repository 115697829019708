import { Injectable } from '@angular/core';
import { Config } from '@models/config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  config: Config;

  isOffline() {
    return this.config.offline;
  }

  get endpoint(): string {
    return this.config.endpoint;
  }

  extension(path: string) {
    if(this.config.offline) {
      path += this.config.extension ? this.config.extension : '';
    }
    return path;
  }
  constructor() {
    this.config = new Config(environment.endpoint, environment.EnvName, environment.production, environment.path, environment.extension);
    this.config.offline = environment.offline;
  }
}
