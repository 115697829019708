
<div class="header">
    <div>
        <div class="burgerbutton"><i class="fas fa-bars"></i></div>
      </div>
      <div>
        <div class="logo"></div>
      </div>
      <div class="button_header">
      <button (click)="initdb()" class="header_connect">init</button>
        <button class="header_connect">Connexion</button>
        <button (click)="initdb" class="header_connect">init</button>
        <button routerLink="/register" class="header_register">S'inscrire</button>
    </div>


</div>

    <!-- <img class="leftwave" src="../../../../assets/image/LeftWave_Red.svg">
    <img class="rightwave" src="../../../../assets/image/RightWave_Red.svg">
    <img class="bird" src="../../../../assets/image/Bird_Red.svg"> -->

<div class="layout">
    <div class="container">
        <form #f="ngForm" (ngSubmit)="onSubmit()" class="form" *ngIf="signIn == false;">

            <div class="Connixon_Text">
                Connexion
            </div>
            <div class="row">
                <label for="login">Nom d'utilisateur ou adresse e-mail</label>
                <input class="signin"name="email" [(ngModel)]="credentials.login"  [ngModelOptions]="{standalone: true}" type="text">
            </div>
            <div class="row">
                <label for="password">Mot de passe</label>
                <input class="signin" name="password" [ngModelOptions]="{standalone: true}" [(ngModel)]="credentials.password" type="password">
            </div>
            <div class="row">
              <span class="error">{{error}}</span>
            </div>
            <div style="display:flex;justify-content:space-between;">
                <label>Nouveau sur Médiashow?  <a routerLink="/register"  id="register">  S'inscrire</a></label>
              <label><a routerLink="/resetPassword">Mot de passe oublié</a></label>

            </div>
            <div class="row2">
                <button class="row_right"><span style="font-family:'SF_PRO_DISPLAY'">Connexion</span></button>
            </div>
          <!-- <a routerLink="/register" class="btn btn-link">S'inscrire</a> -->
        </form>
    </div>
</div>


