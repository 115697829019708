import { Component, OnInit } from '@angular/core';
import { Credentials } from '@models/credentials';
import { AuthService } from '@services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '@services/config.service';
import jwt_decode from 'jwt-decode';
import { HttpConstants } from '../../constants/http.constants';
import { FooterService } from '@services/footer.service';
import { RegisterComponent } from '@pages/register/register.component';
import { UserService } from '@services/user.service';
import { first } from 'rxjs/operators';
import { AlertService } from '@services/alert.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifyService } from '@services/notify.service';

@Component({
  selector: 'app-register-confirmation-email',
  templateUrl: './register-confirmation-email.component.html',
  styleUrls: ['./register-confirmation-email.component.scss'],
})
export class RegisterConfirmationEmailComponent implements OnInit {
  signIn: boolean = false;
  error: string;
  token: string = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private FooterService: FooterService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private notify: NotifyService,
    private accountService: UserService,
    private config: ConfigService,
    private alertService: AlertService
  ) {
    // this.credentials = new Credentials();
    this.error =
      'Veuillez patienter pendant la verification de votre email ...';
    // this.cleanStr = router.url;

    this.token = this.route.snapshot.paramMap.get('token') || '';
  }
  ngOnInit() {
    if (this.token != null && this.token != '') {
      this.authService
        .confirmEmail(this.token)
        .then((data) => {
          console.log(data);
          this.error = 'Votre Adresse email a été confirmée';
        })
        .catch((err) => {
          console.log(err);
          this.error =
            "une Erreur s'est produite lors de la confirmation de l'adresse email";
        });
    }
  }
}
