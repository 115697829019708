import { Injectable } from '@angular/core';
import {environment} from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RESTFulObject } from '@models/restful';

export interface Feedback {
  createdAt  : string;
  message    : string;
  timepassed : number;
  actualpage : string;
}
export class Feedback {

  constructor() {
    this.message    = "";
    this.createdAt  = "";
    this.timepassed = 0;
    this.actualpage = "";
   }

   public message    !: string;
   public createdAt  !: string;
   public timepassed !: number;
   public actualpage !: string;
}


@Injectable({
  providedIn: 'root'
})

export class FeedbackServices{
  constructor(private httpClient: HttpClient) {}

  private endpoint = environment.endpoint + "/feedbacks";
  private options = {
      headers: {
          accept: 'application/json',
          // Authorization: `Bearer ${this.authToken}`
      }
  };
  public getFeedbackData(): Observable<RESTFulObject> {
    return this.httpClient.get<RESTFulObject>(this.endpoint, this.options);
  }
}
