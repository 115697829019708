import { NotifyService } from './notify.service';
import { AuthService } from '@services/auth.service';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { Page } from '@models/page';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { FullPage } from '@models/full-page';
import { BackendResponse } from '@models/response';
import { catchError, map } from 'rxjs/operators';
import { FooterBackground, FooterService } from './footer.service';

@Injectable({
  providedIn: 'root'
})
export class PagesService extends ApiService {

  public getPages(): Observable<Page[]> {
    return this.get<Page[]>('pages');
  }

  public getPage(pageId: string): Observable<FullPage> {
    return this.get<Page>(`pages/${pageId}`).pipe(
      map((response:any) => {
        return response['page'];
      })
    );
  }

  public deletePage(pageId: string) {
      return this.delete<Page>(`pages/${pageId}`);
  }

  public getFullPage(pageId: string): Observable<Page> {
    return this.get<FullPage>(`pages/${pageId}`).pipe(
      map((response:any) => {
        return response['page'];
      })
    );
  }

  /*
  public duplicate(page: Page): Observable<Page> {
    this.getFullPage(page.id).subscribe((page: FullPage) => {
      const clonePage = {...page};
      delete clonePage.customID;
      const waves = [...clonePage.waves!.map(w => {
        delete w.customID;
        w.tiles.forEach(t => {
          delete t.customID;
        });
      })];
      delete clonePage.waves;

    });
  }
  */
  public addPage(page: Page): Observable<BackendResponse> {
    return this.post<BackendResponse>('pages', page);
  }

  public duplicatePage(pageId: string): Observable<BackendResponse> {
    return this.post<BackendResponse>(`d/pages/${pageId}`, null);
  }

  public changeActive(page: Page): Observable<BackendResponse> {
    return this.post<BackendResponse>(`pages/${page.customID}/${page.isActive ? 'enable' : 'disable'}`, {});
  }

  public updatePage(page: Page): Observable<BackendResponse> {
    return this.post<BackendResponse>(`pages/${page.customID}`, page);
  }
  // public addTiers(email: String): Observable<BackendResponse> {
  //   return this.post<BackendResponse>(`pages/${page.customID}`, page);
  // }
}
