import { Observable } from 'rxjs';
import { Size } from './../models/size';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { AuthService } from './auth.service';
import { BackendResponse } from '@models/response';

@Injectable({
  providedIn: 'root'
})
export class SizesService extends ApiService {

  constructor(http: HttpClient, config: ConfigService, auth: AuthService) {
    super(http, config, auth);
  }

  getSizes(): Observable<Size[]>{
    return this.get<Size[]>('sizes');
  }

  updateSize(size: Size): Observable<any> {
    return this.post<Size>(`sizes/${size.customID}`, size);
  }

  deleteSize(size: Size): Observable<any> {
    return this.delete<Size>(`sizes/${size.customID}`);
  }

  changeActive(size: Size): Observable<BackendResponse> {
    return this.post<BackendResponse>(`sizes/${size.customID}/${size.isActive ? 'enable' : 'disable'}`, {});
  }

  addSize(size: Size): Observable<BackendResponse> {
    return this.post<Size>(`sizes`, size);
  }

}
