import { Pipe, PipeTransform } from '@angular/core';
import { Tile } from '@models/tile';

let sortby!: string;

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
  sort(a: any, b: any): number {
    if (a[sortby] < b[sortby]) {
      return -1;
    }
    if (a[sortby] > b[sortby]) {
      return 1;
    }
    return -1;
  }
  transform(value: any[], ...args: any[]): any[] {
    sortby = args[0];
    if (sortby) {
      const sorted = value.sort(this.sort);
      return sorted;
    }
    else {
      return value;
    }
  }

}
