import { PublicApiService } from './../../services/public-api.service';
import { SortPipe } from './../../pipes/sort.pipe';
import { WavesService } from '@services/waves.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { Tile } from '@models/tile';
import { TilesService } from '@services/tiles.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { LayoutMode } from '@enums/layout-mode';
import { Wave } from '@models/wave';
import { BackendResponse } from '@models/response';
import {UserService} from "@services/user.service";
import {HttpConstants} from "../../constants/http.constants";
import {Router} from "@angular/router";

@Component({
  selector: 'app-tile-list',
  templateUrl: './tile-list.component.html',
  styleUrls: ['./tile-list.component.scss'],
  providers: [SortPipe]
})
export class TileListComponent implements OnInit {
  public currentWave!:Wave
  private _wave!: string;
  public currentTile?: Tile;
  private _fullscreen = false;

  @Input()
  get fullscreen(): boolean {
    return this._fullscreen;
  }
  set fullscreen(value: boolean) {
    this._fullscreen = value;
  }

  @Output() fullscreenChange = new EventEmitter<boolean>();

  @ViewChild('searchInput') searchInput!: ElementRef;

  @Input() tiles: Tile[] = [];
  previewTile?: Tile;
  allowGrab = false;
  editOrder: string[] = [];
  search?: string;
  @Input()
  get wave(): string {
    return this._wave;
  }
  set wave(value: string) {
    this._wave = value;
    this.waveService.getWave(this.wave!).subscribe(async (wave: Wave) => {
      this.currentWave = wave
      if (wave.tiles) {
        this.tiles = await this.tileService.updateSize(wave.tiles);
        this.tiles.forEach((tile: Tile, index: number) => {
          if (!tile.printOrder) {
            tile.printOrder = index;
          }
        });
      } else {
        this.tiles = [];
      }
    });
  }
  @Input() page?: string;
  @Output() tileChange = new EventEmitter<Tile>();
  @Output() showPreview = new EventEmitter<Tile>();
  @Output() export = new EventEmitter<string>();
  public layout: LayoutMode = LayoutMode.LIST;
  term$ = new Subject<string>();

  constructor(private tileService: TilesService,
              private sortPipe: SortPipe,
              private waveService: WavesService,
              private publicApi: PublicApiService,
              private userService: UserService,
              private router: Router) {
    this.tileService.tileUpdated.subscribe((tile: Tile) => {
      this.wave = this.wave;
    });
  }

  getType(tile: Tile): string {
    let tileType = '';
    switch (tile.type) {
      case 'image':
        tileType = 'images';
        break;
      case 'video':
        tileType = 'film';
        break;
      default:
        tileType = 'question';
        break;
    }
    return tileType;
  }

  updateTerms(event: KeyboardEvent): void {
    this.term$.next((event.target as HTMLInputElement).value);
  }

  updateTileActivity(tile: Tile, active: boolean): void {
    tile.isActive = active;
    this.tileService.changeActive(this.page!, this.wave, tile).subscribe();
    this.publicApi.updateDone.next(false);
  }

  checkPerm(id:string | undefined, perm:string, tiersId : string | undefined):boolean {

    var isPerm = false
    if (tiersId != undefined) {
      if (tiersId === this.userService.user.customID){
        isPerm = true;
        return isPerm
      }
    }
    if (tiersId == '') {
      if(this.currentWave){
        tiersId = this.currentWave.tiersID
        if (tiersId === this.userService.user.customID){
          isPerm = true;
          return isPerm
        }
      }
    }



    if (id){
      isPerm = this.userService.checkPermission(id,perm)
    }
    return isPerm
  }

  createTile(): void {
    const tile = new Tile();
    tile.printOrder = this.tiles.length + 1;
    this.tileChange.emit(tile);
  }

  setCurrentTile(tile: Tile): void {
    this.tileChange.emit(tile);
    this.currentTile = tile;
  }

  toggleFullscreen(): void {
    this._fullscreen = !this._fullscreen;
    this.fullscreenChange.emit(this._fullscreen);
  }

  clone(event: MouseEvent, tile: Tile): void {
    event.stopPropagation();
    tile.title = `${tile.title} (copie)`;
    this.tileService.clone(this.page!, this.wave, tile).subscribe((clone: Tile) => {
      this.currentTile = tile;
      this.tileService.tileUpdated.next(clone);
      this.publicApi.updateDone.next(false);
    });
  }

  exportTile(event: MouseEvent, tile: Tile): void {
    event.stopPropagation();
    this.export.emit(tile.customID);
    console.log("BBBBBBBBBB", this.wave, this.page)
  }

  deleteTile(event: MouseEvent, tile: Tile): void {
    event.stopPropagation();
    this.currentTile = undefined;
    this.tileService.deleteTile(this.page!, this.wave, tile).subscribe((done: BackendResponse) => {
      if (done.status === 200) {
        this.wave = this.wave;
        this.publicApi.updateDone.next(false);
      }
    });
  }

  updateOrder(value: string, tile: Tile): void {
    tile.printOrder = parseInt(value, 10);
    this.tileService.updateTile(this.page as string, this.wave, tile).subscribe(() => {
      this.manageEditArray(tile.customID);
      this.publicApi.updateDone.next(false);
    });
  }

  preview(event: MouseEvent, tile: Tile): void {
    event.stopPropagation();
    // this.previewTile = tile;
    this.showPreview.emit(tile);
  }

  listPerm(event: MouseEvent, tile: Tile): void {
    event.stopPropagation();
    var contrib = localStorage.getItem(HttpConstants.Contributor)
    if (contrib && tile.tiersID) {
      if (tile.tiersID.includes(contrib) || this.userService.user.profilType == "Admin" || this.checkPerm(tile.customID,'update',this.userService.user.customID) ) {
        this.router.navigateByUrl('/perm/' + tile.customID + "/" + "tile");
      }
    }
    if (this.userService.user.profilType == "Admin"){
      this.router.navigateByUrl('/perm/' + tile.customID + "/" + "tile");
    }
  }

  changeOrder(event: MouseEvent, tile: Tile): void {
    event.stopPropagation();
    if (this.allowGrab) {
    }
  }

  getOrder(event: MouseEvent, tile: Tile): void {
    event.stopPropagation();
    this.allowGrab = false;
  }

  grab(event: MouseEvent, tile: Tile): void {
    event.stopPropagation();
    this.allowGrab = true;
  }

  resetSearch(): void {
    this.term$.next('');
    this.searchInput.nativeElement.value = '';

  }

  edittingOrder(tileId: string): boolean {
    return this.editOrder.findIndex(e => e === tileId) > -1;
  }

  toggleOrderEdit(event: MouseEvent, tileId: string): void {
    event.stopPropagation();
    // this.editOrder = !this.editOrder;
    this.manageEditArray(tileId);
  }

  private manageEditArray(tileId?: string): void {
    if (tileId) {
      const index = this.editOrder.findIndex(e => e === tileId);
      if (index > -1) {
        this.editOrder.splice(index, 1);
      } else {
        this.editOrder.push(tileId);
      }
    }
  }

  isNotOnTop(tile: Tile): boolean {
    return tile.printOrder !== 1;
  }

  isNotOnBottom(tile: Tile): boolean {
    return tile.printOrder !== this.tiles.length;
  }

  moveUp(event: MouseEvent, tile: Tile): void {
    event.stopPropagation();
    const previousTile = this.previousTile(tile);

    if (previousTile) {
      const mem = previousTile.printOrder;
      previousTile.printOrder = tile.printOrder;
      tile.printOrder = mem;
      this.tileService.updateTile(this.page as string, this.wave, tile).subscribe(() => {
        this.tileService.updateTile(this.page as string, this.wave, previousTile).subscribe(() => {
          this.publicApi.updateDone.next(false);
          this.tiles = this.sortPipe.transform(this.tiles, ['printOrder']);
        });
      });
    }
  }

  previousTile(tile: Tile): Tile | undefined {
    let previous: Tile | undefined;
    const index = this.tiles.findIndex(t => t.customID === tile.customID);
    if (index > 0) {
      previous = this.tiles[index - 1];
    }
    return previous;
  }

  moveDown(event: MouseEvent, tile: Tile): void {
    event.stopPropagation();
    const nextTile = this.nextTile(tile);

    if (nextTile) {
      const mem = nextTile.printOrder;
      nextTile.printOrder = tile.printOrder;
      tile.printOrder = mem;
      this.tileService.updateTile(this.page as string, this.wave, tile).subscribe(() => {
        this.tileService.updateTile(this.page as string, this.wave, nextTile).subscribe(() => {
          this.publicApi.updateDone.next(false);
          this.tiles = this.sortPipe.transform(this.tiles, ['printOrder']);
        });
      });
    }
  }

  nextTile(tile: Tile): Tile | undefined {
    let next: Tile | undefined;
    const index = this.tiles.findIndex(t => t.customID === tile.customID);
    if (index < this.tiles.length) {
      next = this.tiles[index + 1];
    }
    return next;
  }

  ngOnInit(): void {
    this.term$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
    ).subscribe(searchTerm => {
      if (searchTerm.length > 2) {
        this.search = searchTerm;
      } else {
        this.search = '';
      }
    });
  }

}
