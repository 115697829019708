
<div class="container">
  <div class="container">
    <h1 *ngIf="isAddMode">Ajouter un utilisateur</h1>
    <h1 *ngIf="!isAddMode">Editer un utilisateur</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-row">
    <div class="form-group col">
      <label for="firstName">Prénom</label>
      <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
      <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
        <div *ngIf="f.firstName.errors.required">First Name is required</div>
      </div>
    </div>
    <div class="form-group col">
      <label for="lastName">Nom</label>
      <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
      <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
        <div *ngIf="f.lastName.errors.required">Last Name is required</div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group col">
      <label for="email">Email</label>
      <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
        <div *ngIf="f.email.errors.required">Email is required</div>
      </div>
    </div>
    <div class="form-group col">
      <label for="password">
        Mot de passe
        <em *ngIf="!isAddMode"></em>
      </label>
      <input type="password" formControlName="password" placeholder="À remplir si vous voulez le changer uniquement" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
        <div *ngIf="f.password.errors.required && !isEdit">Password is required</div>
        <div *ngIf="f.password.errors && !isEdit">
          <p>Le mot de passe doit contenir au moins :</p>
          <tr>8 caractères</tr>
          <tr>un nombre</tr>
          <tr>une majuscule</tr>
          <tr>un caractère spécial</tr>
        </div>
      </div>
    </div>
    <div class="form-group col">
      <label>Rôle
        <select style="margin-top: 7%;margin-bottom: 7%;" formControlName="profilType" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.profilType.errors }">
          <option value=""></option>
          <option *ngIf="userProfil == 'Admin'" value="Admin">Admin</option>
          <option *ngIf="userProfil == 'Admin'" value="Moderateur">Moderateur</option>
          <option *ngIf="userProfil == 'Admin'" value="Contributor">Contributor</option>
          <option value="Advertiser">Advertiser</option>
        </select>
      </label>
      <div *ngIf="submitted && f.profilType.errors" class="invalid-feedback">
        <div *ngIf="f.profilType.errors.required">Profil is required</div>
      </div>
    </div>
  </div>
  <div class="form-group">
    <button [disabled]="loading" class="btn btn-primary">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      Save
    </button>
    <a style="margin-left: 30%;" routerLink="/list" class="btn btn-link">Cancel</a>
  </div>
</form>
  </div>
</div>
