import { Component, ElementRef, HostBinding, HostListener, Input, OnInit, Output, ViewChild, EventEmitter, Type } from '@angular/core';
import { Tile } from '@models/tile';
import { SafePipe } from 'src/app/pipes/safe.pipe';
import { TileType } from '@enums/type-type';
import { TilesService } from '@services/tiles.service';

@Component({
  selector: 'app-tile-preview',
  templateUrl: './tile-preview.component.html',
  styleUrls: ['./tile-preview.component.scss'],
  providers: [SafePipe]
})
export class TilePreviewComponent implements OnInit {
  TileType = TileType;
  public show = false;
  embeddedScript: any;
  @HostBinding('class.show') get shown(): boolean { return this.show; }
  @ViewChild('backgroundTile') backgroundTile!: ElementRef;
  @Output() closed = new EventEmitter<boolean>();

  selfEmbedded?: string;

  private _tile?: Tile;
  @Input()
  get tile(): Tile {
    return this._tile!;
  };
  set tile(value: Tile) {
    this._tile = value;
    this.show = value !== undefined;
    if (this._tile) {
      this.prepareEmbedded().then(hasEmbedded => {
        if (hasEmbedded) {
          this.selfEmbedded = this._tile?.embedded!;
        } else {
          this.selfEmbedded = '';
        }

        const tileGui = this.el.nativeElement.querySelector('.tile-gui');
        if (tileGui) {
          if (this._tile!.backgroundColor) {
            tileGui.style.setProperty('--tile-background-color', `${this._tile!.backgroundColor}`);
          } else {
            tileGui.style.removeProperty('--tile-background-color');
          }
          tileGui.style.setProperty('--tile-width', `${this._tile!.size?.width}px`);
          tileGui.style.setProperty('--tile-height', `${this._tile!.size?.height}px`);
          this.setImage();
        }

      });
    }

  }
  constructor(private el: ElementRef,
    private safePipe: SafePipe,
    private tilesService: TilesService) {
    this.tilesService.tilePreview.subscribe((tile: Tile) => {
      if (this.show) {
        this.tile = tile;
      }
    });
  }

  close(): void {
    this.show = false;
    this.closed.emit(true);
  }

  showThumb(): boolean {
    return this._tile!.type === TileType.IMAGE || (this._tile!.type === TileType.VIDEO && this._tile!.resize)!;
  }


  isMedia(tile?: Tile): boolean {
    return tile!.type === TileType.IMAGE || tile!.type === TileType.VIDEO;
  }

  setImage(): void {
    setTimeout(() => {
      if (this.isMedia(this._tile)) {
        const wallpaper = (this._tile!.thFileName ? this._tile!.thFileName : this._tile!.fileName)?.replace(/\s/gi, '%20');
        //console.log(wallpaper);
        this.el.nativeElement.style.setProperty('--background-image', `url(${wallpaper})`);
      } else {
        this.el.nativeElement.style.setProperty('--background-image', `none`);
      }
    });
  }

  isEmbedded() {
    return this.tile.type === TileType.EMBEDDED || this.tile.type === TileType.ADVERTIZER ;
  }

  async prepareEmbedded(): Promise<boolean> {
    let hasEmbedded = false;
    if (this._tile) {
      if (this._tile.embedded && this.tile.type === TileType.EMBEDDED) {
        const regex = /(src=)(\".*\")/;
        const script = this._tile.embedded.match(regex);
        if (script && script.length > 1) {
          const node = document.createElement('script');
          const src = script[2].replace(/\"/g, '');
          if (src.match(/(.*)\s/) && src.match(/(.*)\s/)!.length > 1) {
            node.src = src.match(/(.*)\s/)![1];
          } else {
            node.src = src;
          }

          if (node.src.match('.js')) {
            const body = document.querySelector('body');
            if (body) {
              body.append(node);
            }
            hasEmbedded = true;
          }
        }
      } else if(this._tile.embedded && this.tile.type === TileType.ADVERTIZER) {
        const body = document.querySelector('body');
        const adv = document.createElement('div');
        let node = document.createElement('script');
        adv.innerHTML = this._tile.embedded;
        
        adv.childNodes.forEach((n:ChildNode) => {
          // console.log(n.nodeName, n.nodeType);
          const el = (<Element>n) ;
          if(el.tagName === 'SCRIPT') {
            const src = el.getAttribute('src');
            if(src) {
              node.setAttribute('src',src);
            }
            if(el.innerHTML) {
              node.textContent = `setTimeout(() => {${el.innerHTML}})`;
            } else {
              if(n.textContent) {
                this.selfEmbedded +=  n.textContent ;
              }
            }
          }
        });
        if (body) {
          body.append(node);
        }
        hasEmbedded = true;
      }
    }
    return hasEmbedded;
  }

  openTarget(tile: Tile): void {

  }

  replaceStyle(innerHtml: string) {
    return innerHtml.replace(/style=\".*\"/g, `style="min-width:${this._tile?.size?.width}px"`);
  }

  ngOnInit(): void {

  }

}
