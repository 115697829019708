<div class="layout">
    <div class="info" *ngIf="step !== 2">
        <label *ngIf="step === 1" (click)="setStep(0)">Retour au choix de page</label>
        <label *ngIf="step === 1">Page: {{pageTitle}}</label>
        <label>Choisir dans quelle {{stepName[step]}} exporter la {{itemType}}</label>
    </div>

    <div *ngIf="step === 0" class="layout">
        <div (click)="choosePage(page)" *ngFor="let page of pages" class="item">
            <div class="tools">
                <app-chip [active]="page.isActive" [text]="page.isActive ? 'Activée' : 'Désactivée'"></app-chip>
            </div>
            <div class="content">
                <label>{{page.title}}</label>
              </div>
        </div>
    </div>
    <div *ngIf="step === 1" class="layout">
        <div (click)="chooseWave(wave)" *ngFor="let wave of waves" class="item">
            <div class="tools">
                <app-chip [active]="wave.isActive" [text]="wave.isActive ? 'Activée' : 'Désactivée'"></app-chip>
            </div>
            <div class="content">
                <label>{{wave.title}}</label>
                </div>
        </div>
    </div>
    <div class="layout" style="flex-direction: column;justify-content: space-between;"  *ngIf="step === 2">
        <h1 style="color: var(--link-color, #282828);position: absolute;top: 20%;left: 12%;font-size: 29px;">{{confirmText}}</h1>
        <div >
            <div style="position: absolute;top: 29%;width: 182px;height: 182px;text-align: center;" class="item">
                <div class="content">
                    <label>De</label> <br>
                    <label>Page : {{pageTitle}}</label> <br>
                    <label *ngIf="tileowave===true">Vague : {{waveTitle}}</label>
                    </div>
            </div>
            <i class="fas fa-arrow-circle-right"></i>
            <div style="position: absolute;right: 40%;top: 29%;width: 182px;height: 182px;text-align: center;" class="item">
                <div class="content">
                    <label>vers</label> <br>
                    <label>Page : {{PageTitles}}</label> <br>
                    <label *ngIf="tileowave===true">Vague : {{WaveTitles}}</label>
                    </div>
            </div>
            
            
        </div>
        <app-yesno style="position: absolute;left: 25%;top: 71%;"  (onCancel)="cancel()" (onValidate)="confirm()" (onClose)="setStep(-1)">
        </app-yesno>
    </div>
    
    <!-- <app-yesno [labels]="{cancel: 'Annuler'}" (onCancel)="cancel"></app-yesno> -->
</div>
