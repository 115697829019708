import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'termFilter'
})
export class TermFilterPipe implements PipeTransform {

  transform(items: any[], ...args: string[]): any {
    if (!items || !args) {
      return items;
    }
    if (args[0] === undefined || args[0]!.length === 0) {
      return items;
    }
    return items.filter((item: any) => {
      return item.title.toLowerCase().match(args[0].toLowerCase()) !== null;
    });;
  }

}
