import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-yesno',
  templateUrl: './yesno.component.html',
  styleUrls: ['./yesno.component.scss']
})
export class YesnoComponent implements OnInit {


  // tslint:disable-next-line: no-output-on-prefix
  @Output() onValidate = new EventEmitter<boolean>();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onCancel = new EventEmitter<boolean>();

  @Input() disabled = false;

  @Input() labels: { cancel: string | undefined, valid: string | undefined } = {
    cancel: 'Annuler',
    valid: 'Valider'
  }

  @HostListener('window:keydown', ['$event'])
  onKeyPress(key: KeyboardEvent): void {
    if(!this.disabled) {
      if (key.key === 'Enter') {
        //this.onValidate.emit(true);
      }
      if (key.key === 'Escape') {
        this.onCancel.emit(true);
      }
    }

  }
  constructor() { }

  valid() {
    if (!this.disabled) {
      this.onValidate.emit(true);
    }
  }

  cancel() {
    this.onCancel.emit(true);
  }

  ngOnInit(): void {
  }



}
