<div>
  <h1 class="title">Permissions de l'utilisateur {{user.firstName}} {{user.lastName}} sur les pages</h1>
  <table mat-table class="mat-elevation-z8" [dataSource]="dataSource">
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef> Nom de la page </th>
      <td mat-cell *matCellDef="let page" (click)="openDialog(page.customID)"> {{page.title}} </td>
    </ng-container>
    <ng-container matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef> Activité </th>
      <td mat-cell *matCellDef="let page"> {{page.isActive == true ? 'Activée': 'Désactivée'}} </td>
    </ng-container>
    <ng-container matColumnDef="perms">
      <th mat-header-cell *matHeaderCellDef> Permissions
        <button (click)="checkAllPagesWithOnePerm('read')">Lire tous</button>
        <button (click)="checkAllPagesWithOnePerm('update')">Modifier tous</button>
        <button (click)="checkAllPagesWithOnePerm('delete')">Supprimer tous</button>
        <button (click)="checkAllPagesWithOnePerm('import')">Importer tous</button>
      </th>
      <td mat-cell *matCellDef="let user; let page">
        <mat-checkbox (change)="setPerm(page,'read')" [checked]="hasPerm(page, 'read')"><span>Lire</span></mat-checkbox>
        <mat-checkbox (change)="setPerm(page,'update')" [checked]="hasPerm(page, 'update')"><span>Modifier</span>
        </mat-checkbox>
        <mat-checkbox (change)="setPerm(page,'delete')" [checked]="hasPerm(page, 'delete')"><span>Supprimer</span>
        </mat-checkbox>
        <mat-checkbox (change)="setPerm(page,'import')" [checked]="hasPerm(page, 'import')"><span>Importer</span>
        </mat-checkbox>
        <button (click)="checkAll(page)">Toutes</button>
        <button routerLink="/list/add" class="btn btn-sm btn-primary mr-1">Ajouter un utilisateur</button>
      </td>
    </ng-container>

    <!-- ajouter les contributeurs de chaque page en tant que colonne -->

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
  </table>
</div>
