import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss']
})
export class BoxComponent implements AfterViewInit{

  @ViewChild("ToolManager")ToolManager!:ElementRef

  constructor(public el:ElementRef){}

  @Input("text") textcontent!:string;

  set SetTextContent(value:string){
    if (!this.Transform){
      setTimeout( () => this.textcontent = value)
    }
  }

  get GetTextContent(){
    return this.textcontent
  }
  
  private mouseSave:MouseSave = {X:0,Y:0,mouse:false};
  private Recalibrage:number[] = [0,0];
  private Transform:boolean = false

  set SetTransform(value:boolean){
    this.Transform = value
    if (value){
      // On sort du mode editable en fixant la div
      this.el.nativeElement.style.setProperty('--border-color', 'gray');
      this.el.nativeElement.style.setProperty('--backgroud-color', 'gray');
      this.el.nativeElement.style.setProperty('--display-circle', 'none');
      this.el.nativeElement.style.setProperty('--cursor-center','default');
    }
    else{
      // On rentre dans le mode editable en rendant la div modifiable
      this.el.nativeElement.style.setProperty('--border-color', '#4286f4');
      this.el.nativeElement.style.setProperty('--backgroud-color', 'white');
      this.el.nativeElement.style.setProperty('--display-circle', 'block');
      this.el.nativeElement.style.setProperty('--cursor-center','crosshair');
    }
  }

  get GetTransform(){
    return this.Transform
  }
  
  @Input("StyleTransform")StyleTransform:string = "translate(0px,0px)"
  set SetStyleTransform(value:string){
    this.el.nativeElement.style.transform = value;
  }
  get GetStyleTransform():string{
    return this.el.nativeElement.style.transform
  }

  private _sizebox:string = "display:block;"
  set SetSizeBox(value:string){
    this._sizebox = value
  }

  @HostListener("mousedown",["$event"])
  MouseDown(event:MouseEvent){
    
    if((event as any).path[0] != this.el.nativeElement.querySelector("div.resizer.bottom-right") && !this.Transform){
      this.Recalibrage = this.el.nativeElement.style.transform.match(/[0-9-,]/g).join("").split(",").map( (x:any) => x = Number(x) )
      this.mouseSave = {X:event.clientX,Y:event.clientY,mouse:true}
    }

  }

  @HostListener("mousemove",["$event"])
  MouseMove(event:MouseEvent){
    if (this.mouseSave.mouse){
      this.el.nativeElement.style.transform = "translate(" + Number((event.clientX - this.mouseSave.X)+this.Recalibrage[0])+"px, " + Number((event.clientY - this.mouseSave.Y)+this.Recalibrage[1]) + "px)"
    }
  }

  @HostListener("document:mouseup",["$event"])
  MouseUp(event:MouseEvent){
    this.mouseSave.mouse = false;
  }

  ngAfterViewInit(): void {
    if (this.StyleTransform != "translate(0px,0px)" && this.GetStyleTransform != undefined){
      this.SetTransform = true;
    }
    else{
      //this.SetStyleTransform = "translate(0px,0px)"
    }
    this.ToolManager.nativeElement.style = this._sizebox
    this.makeResizableDiv('.resizable')
  }



  makeResizableDiv(div:any) {
    const element = this.el.nativeElement.querySelector(div);
    const resizers = this.el.nativeElement.querySelectorAll(div + ' .resizer')
    const minimum_size = 20;
    let original_width = 0;
    let original_height = 0;
    let original_x = 0;
    let original_y = 0;
    let original_mouse_x = 0;
    let original_mouse_y = 0;
    for (let i = 0;i < resizers.length; i++) {
      const currentResizer = resizers[i];
      currentResizer.addEventListener('mousedown', function(e:MouseEvent) {
        e.preventDefault()
        original_width = parseFloat(getComputedStyle(element, null).getPropertyValue('width').replace('px', ''));
        original_height = parseFloat(getComputedStyle(element, null).getPropertyValue('height').replace('px', ''));
        original_x = element.getBoundingClientRect().left;
        original_y = element.getBoundingClientRect().top;
        original_mouse_x = e.pageX;
        original_mouse_y = e.pageY;
        window.addEventListener('mousemove', resize)
        window.addEventListener('mouseup', stopResize)
      })
      
      function resize(e:any) {
        if (currentResizer.classList.contains('bottom-right')) {
          const width = original_width + (e.pageX - original_mouse_x);
          const height = original_height + (e.pageY - original_mouse_y)
          if (width > minimum_size) {
            element.style.width = width + 'px'
          }
          if (height > minimum_size) {
            element.style.height = height + 'px'
          }
        }
        else if (currentResizer.classList.contains('bottom-left')) {
          const height = original_height + (e.pageY - original_mouse_y)
          const width = original_width - (e.pageX - original_mouse_x)
          if (height > minimum_size) {
            element.style.height = height + 'px'
          }
          if (width > minimum_size) {
            element.style.width = width + 'px'
            element.style.left = original_x + (e.pageX - original_mouse_x) + 'px'
          }
        }
        else if (currentResizer.classList.contains('top-right')) {
          const width = original_width + (e.pageX - original_mouse_x)
          const height = original_height - (e.pageY - original_mouse_y)
          if (width > minimum_size) {
            element.style.width = width + 'px'
          }
          if (height > minimum_size) {
            element.style.height = height + 'px'
            element.style.top = original_y + (e.pageY - original_mouse_y) + 'px'
          }
        }
        else {
          const width = original_width - (e.pageX - original_mouse_x)
          const height = original_height - (e.pageY - original_mouse_y)
          if (width > minimum_size) {
            element.style.width = width + 'px'
            element.style.left = original_x + (e.pageX - original_mouse_x) + 'px'
          }
          if (height > minimum_size) {
            element.style.height = height + 'px'
            element.style.top = original_y + (e.pageY - original_mouse_y) + 'px'
          }
        }
      }
      
      function stopResize() {
        window.removeEventListener('mousemove', resize)
      }
    }
  }

}

interface MouseSave
{
  X:number,
  Y:number,
  mouse:boolean,
}