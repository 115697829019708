import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {


  @Input() active = false ;
  @Output() activeChange = new EventEmitter<boolean>();
  @Input() text = '';

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.stopPropagation();
    this.active = !this.active;
    this.activeChange.emit(this.active);
  }

  constructor() { }

  ngOnInit(): void {
  }

}
