<div (click)="close()" class="overlay">
  <i (click)="close()" class="fa round fa-times"></i>
</div>
<div  *ngIf="tile" class="tile-gui">
  <div *ngIf="tile.backgroundBlurred" class="backgroundImage"></div>

  <div class="embd" [innerHtml]="selfEmbedded | safe:'html'" *ngIf="isEmbedded()"></div>
  
  <iframe style="overflow: hidden;" width="100%" height="100%" frameborder="0" [src]="tile.url | safe:'resourceUrl'" *ngIf="tile.type ===TileType.IFRAME" class="content"></iframe>
  <div *ngIf="tile.type ==='video'" class="video">
    <video [width]="tile.size!.width" [height]="tile.size!.height" controls [src]="tile.fileName | safe:'url'"></video>
  </div>
  <div *ngIf="tile.type == 'edit'" [innerHTML]="tile.edit.html" ></div>
  <div [style.display]="isMedia(tile) ? 'flex' : 'none'" #backgroundTile (click)="openTarget(tile)" class="image">&nbsp;</div>
  <ng-content></ng-content>
</div>
