import { IData } from '../interfaces/data';
import { Tile } from './tile';
export class Wave implements IData {
  id?: string;
  customID?: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  isActive: boolean;
  isShortList: boolean;
  isRandomized: boolean;
  isPrimary: boolean;
  deleted?: boolean;
  zoomWave?: number;
  gutterSize?: number;
  gutterSizeMobile?:number;
  owner?: string;
  description?: string;
  title: string | undefined;
  start: Date | undefined;
  end: Date | undefined;
  bandeau: string | undefined;
  imageBandeau?: string;
  videoHeader?: string;
  descriptionBandeau: string | undefined;
  bandeauType : string | undefined;
  backgroundVideoHeader : string | undefined;
  backgroundImageBandeau : string | undefined;
  bandeauIsActive!: boolean;
  bandeauVideoIsActive!: boolean;
  tiles: Tile[];
  printOrder = 1;
  customCode?: string;
  tiersID: string;
  constructor() {
    this.tiles = [];
    this.isActive = true;
    this.tiersID = '';
    this.description = '';
    this.isShortList = true;
    this.isRandomized = false;
    this.isPrimary = false;
    this.zoomWave = 50;
    this.imageBandeau = '';
  }
}
