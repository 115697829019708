<p *ngIf="this.size">{{this.size.width}}x{{this.size.height}}</p>
<button (click)="Close()">Fermer</button>
<button (click)="SaveAndClose()">Enregistrer et Fermer</button>
<div class="container">
    <div #template1 class="template1">
        <div #template1content1 class="center">
            
        </div>
    </div>
    <div>
        <div style="display: flex;align-items: center;height: 8%;">
            <button (click)="Test()">Test</button>
            <input type="color" (change)="SetBackgroundcolor($event)">
            <app-group-radio-buttons-style 
                [data]='[{text:"Violet",value:"#D554A4"},{text:"Orange",value:"#FA965B"},{text:"Bleu",value:"#498DE0"},{text:"Vert",value:"#6ACA5E"}]'
                (SendOutput)="SetBackgroundcolor($event)">
            </app-group-radio-buttons-style>
        </div>
        <angular-editor [(ngModel)]="htmlContent" [config]="editorConfig"></angular-editor>
    </div>
</div>