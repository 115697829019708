<div mat-dialog-content>
  <h1 *ngIf="page" class="title">Vagues de la page : {{page.title}} <button mat-dialog-close>X</button> </h1>
  <table mat-table class="mat-elevation-z8" [dataSource]="dataSource">
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef> Nom de la vague</th>
      <td mat-cell *matCellDef="let wave" (click)="openDialog(wave.customID)"> {{wave.title}} </td>
    </ng-container>
    <ng-container matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef> Activité </th>
      <td mat-cell *matCellDef="let wave"> {{wave.isActive == true ? 'Activée': 'Désactivée'}} </td>
    </ng-container>
    <ng-container matColumnDef="perms">
      <th mat-header-cell *matHeaderCellDef> Permissions 
        <button (click)="checkAllWavesWithOnePerm('read')">Lire tous</button>
        <button (click)="checkAllWavesWithOnePerm('update')">Modifier tous</button>
        <button (click)="checkAllWavesWithOnePerm('delete')">Supprimer tous</button>
        <button (click)="checkAllWavesWithOnePerm('import')">Importer tous</button>
      </th>
      <td mat-cell *matCellDef="let user; let wave">
        <mat-checkbox (change)="setPerm(wave,'read')" [checked]="hasPerm(wave, 'read')"><span>Lire</span></mat-checkbox>
        <mat-checkbox (change)="setPerm(wave,'update')" [checked]="hasPerm(wave, 'update')"><span>Modifier</span></mat-checkbox>
        <mat-checkbox (change)="setPerm(wave,'delete')" [checked]="hasPerm(wave, 'delete')"><span>Supprimer</span></mat-checkbox>
        <mat-checkbox (change)="setPerm(wave,'import')" [checked]="hasPerm(wave, 'import')"><span>Importer</span></mat-checkbox>
        <button (click)="checkAll(wave)">Toutes</button>
        <button routerLink="/list/add" class="btn btn-sm btn-primary mr-1">Ajouter un utilisateur</button>
      </td>
    </ng-container>

    <!-- ajouter les contributeurs de chaque page en tant que colonne -->

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
  </table>
</div>
