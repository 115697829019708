
<div class="header">
  <div>
    <div class="burgerbutton"><i class="fas fa-bars"></i></div>
  </div>
  <div>
    <div class="logo"></div>
  </div>
  <div class="button_header">
    <button class="header_connect">Connexion</button>
    <button routerLink="/register" class="header_register">S'inscrire</button>
  </div>


</div>

<!-- <img class="leftwave" src="../../../../assets/image/LeftWave_Red.svg">
<img class="rightwave" src="../../../../assets/image/RightWave_Red.svg">
<img class="bird" src="../../../../assets/image/Bird_Red.svg"> -->
<app-notifier></app-notifier>

<div class="layout">
  <div class="container">
    <form #f="ngForm" (ngSubmit)="onSubmit()" class="form" *ngIf="signIn == false">

      <div class="Connixon_Text">
        Entrez l'adresse e-mail avec laquelle vous êtes inscrits.<br><br>
          Nous allons vous envoyer un message pour réinitialiser votre mot de passe
      </div>
      <div class="row">
        <label for="login">adresse e-mail</label>
        <input class="signin" name="email" [(ngModel)]="credentials.login"  [ngModelOptions]="{standalone: true}" type="text">
      </div>
      <div class="row">
        <span class="error">{{error}}</span>
      </div>

      <div class="row2">
        <button class="row_right"><span style="font-family:'SF_PRO_DISPLAY'">Envoyer le mail de récuperation</span></button>
      </div>
    </form>
    <div *ngIf="signIn == true;">
      <div class="Connixon_Text">
        E-mail envoyé
        <br>
        <br>
        Vous recevrez prochainement un mail de réinitialisation de mot de passe si l'adresse <span style="color: #28c0d7">{{credentials.login}}</span> est reliée à un compte existant
      </div>
    </div>
  </div>
</div>


