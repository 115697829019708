import { Injectable } from '@angular/core';
import {environment} from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RESTFulObject } from '@models/restful';


export class KPI {
  constructor(origin: string) {
    this.origin = origin;
    this.nbClick = 0;
    this.timePassed = 0;
    this.tabOnDisplay = false;
    this.actions = [];
    this.popInClosed = false;
    this.popInOkIsDone = false;
    this.popInHowTo = false;
    this.popInAccessVideo = false;

    //KPI Widget
    this.nBClickWidget = 0;
    this.widgetOk = false;
    this.widgetLearnMore = false;
    this.widgetClosed = false;
    this.widgettimePassedwithoutaction = 0;
    this.widgetLandingPage = '';

    //KPI Front
    this.closedBefore10 = false;
    this.desactivatedAdblocker = false;
    this.tutoOkisDone = false;
    this.tutoHowto = false;
    this.isWindowInactive = false;
    this.nbTimesUserCome = 0;
    this.actualPage = '';
    this.actualPageID = '';
  }
  public origin!: string;
  public tabOnDisplay!: boolean;
  public nbClick!: number;
  public timePassed!: number;
  public actions!: string[];
  public popInClosed!: boolean;
  public popInOkIsDone!: boolean;
  public popInHowTo!: boolean;
  public popInAccessVideo!: boolean;
  public createdAt!: string;

  //KPI Widget
  public widgetName !: string;
  public widgetLoaded !: boolean;
  public widgetOpen!: boolean;
  public widgetOk!: boolean;
  public widgetLearnMore!: boolean;
  public widgetClosed!: boolean;
  public widgetClosedBefor10!: boolean;
  public nBClickWidget!: number;
  public widgetPlateform !: string;
  public widgetABEnabled!: boolean;

  public widgettimePassedwithoutaction!: number;
  public widgetLandingPage!: string;
  public widgettiersID!: string;
  public timePassedwithoutaction!: number;

  //Kpi Front
  public closedBefore10!: boolean; // users leave the page in less than 10 seconds
  public desactivatedAdblocker!: boolean; // users that desactivate their adblockers.
  public tutoOkisDone!: boolean; //Clicking in tuto "Ok is Done" button
  public tutoHowto!: boolean; //Clicking on tuto "How to" button
  public isWindowInactive!: boolean; //if page is inactive.
  public nbTimesUserCome!: number; // Number of time the same user come in a week.
  public actualPage!: string; // String or Id of the actual page.
  public actualPageID!: string; // ID of the actual page.
}

@Injectable({
  providedIn: 'root'
})

export class KPIService {
  constructor(private httpClient: HttpClient) {}

  private endpoint = environment.endpoint + "/kpi";
  private options = {
      headers: {
          accept: 'application/json',
          // Authorization: `Bearer ${this.authToken}`
      }
  };

  public addKPIData(kpi: KPI) {
      return this.httpClient.put<KPI>(this.endpoint, kpi, this.options);
  }

  public getKPIData(): Observable<RESTFulObject> {
      return this.httpClient.get<RESTFulObject>(this.endpoint, this.options);
  }
}
