import { ApiNotification } from '../../models/api-notification';
import { NotifyService } from './../../services/notify.service';
import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Status } from '@enums/status';

@Component({
  selector: 'app-notifier',
  templateUrl: './notifier.component.html',
  styleUrls: ['./notifier.component.scss']
})
export class NotifierComponent implements OnInit {

  private closing = false;
  public notification: ApiNotification;
  @Input() timer = 5000;
  @HostBinding('class.closing') get isClosing(): boolean { return this.closing; }
  @HostBinding('class') get status(): string | undefined {
    if (this.notification.status !== Status.Unknonwn) {
      return this.notification.status;
    }
    return undefined;
  }
  constructor(private notificationService: NotifyService) {
    this.notification = new ApiNotification();
    this.notificationService.notification.subscribe((notif: ApiNotification) => {
      this.notification = notif;
      if (this.notification.status !== Status.Unknonwn) {
        this.fireCooldown();
      }
    });
  }

  fireCooldown(): void {
    setTimeout(() => {
      this.close();
    }, this.timer);
  }

  close(): void {
    this.closing = true;
    setTimeout(() => {
      this.notification.status = Status.Unknonwn;
      this.closing = false;
    }, 500);
  }

  ngOnInit(): void {
  }

}
