import { HttpConstants } from './../constants/http.constants';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from './config.service';
import { map } from 'rxjs/operators';


import jwt_decode from 'jwt-decode';
import {UserService} from "@services/user.service";
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedIn = false;
  token !: string;

  constructor(private router: Router,
              private http: HttpClient,
              private config: ConfigService,
              protected userService: UserService) {
    setTimeout(() => {
    const token = localStorage.getItem(HttpConstants.AccessToken);
    this.token = token ? token : '';
      console.log(token)
    },500)
  }
  async initdb(){
    return this.http.get(`${this.config.endpoint}/init`).toPromise();
  }
  async isLoggedIn(): Promise<boolean> {
    return await this.checkLoggedIn();
  }

  async login(login: string, password: string): Promise<boolean> {
    const loginBody = { login: btoa(login), password: btoa(password) };
    try {
      const token = await this.http.post<string>(`${this.config.endpoint}/login?email=${loginBody.login}&password=${loginBody.password}`, {}).toPromise();
      if(token) {
        this.token = token;
        console.log('le token example : ' + this.token)
        localStorage.setItem(HttpConstants.AccessToken, this.token);
        let tokenInfo = this.getDecodedAccessToken(token)
        localStorage.setItem(HttpConstants.Contributor, tokenInfo.contributor);
        this.loggedIn = token !== undefined;
        if (tokenInfo.contributor)
        this.userService.getById(tokenInfo.contributor).subscribe(
          (user:any) => {
          this.userService.user = user['data']
        })
      }
    } catch(e) {
      this.loggedIn = false;
    }
    return this.loggedIn;
  }

  async resetPassword(login: string) {
    this.logout();
    await this.http.post(`${this.config.endpoint}/resetPasswordBackoffice`, {email: login}
    ).toPromise();
  }

  async checkTokenValidity(): Promise<boolean> {
    let isValid = this.loggedIn ;
    if(!this.loggedIn) {
      const options = { headers: new HttpHeaders() };
      options.headers = options.headers.append(HttpConstants.AccessToken, this.token);
      try {
        isValid = await this.http.post<string>(`${this.config.endpoint}/token`, {}, options).pipe(
          map(response => {
            return response !== undefined;
          })
        ).toPromise();
      } catch(e) {
        this.logout();
      }
    }
    return isValid;
  }

  getDecodedAccessToken(token: string): any {
    try{
      return jwt_decode(token);
    }
    catch(Error){
      return null;
    }
  }
  async checkLoggedIn(): Promise<boolean> {
    const token = localStorage.getItem(HttpConstants.AccessToken);
    if (token && await this.checkTokenValidity()) {
      return true;
    }
    return false;
  }
  confirmEmail(token:string){
    return this.http.post(`${this.config.endpoint}/confirmEmail`,{token}).toPromise();
  }

  logout()
  {
    this.loggedIn = false ;
    this.token = '';
    localStorage.removeItem(HttpConstants.AccessToken);
    localStorage.removeItem(HttpConstants.Contributor);
    localStorage.removeItem(HttpConstants.Profil);
    this.userService.logout()
  }

  async resetPasswordConfirmation(token: string, password: string) {
    this.logout();
    await this.http.post(`${this.config.endpoint}/resetPasswordConfirmation`, { password,token }).toPromise();
  }

  async registerConfirmationEmail(email: string, password: string) {
    await this.http.post(`${this.config.endpoint}/register`, {Email: email, Password: password}).toPromise();
  }
}
