import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import {AuthService} from "@services/auth.service";
import {UserService} from "@services/user.service";
import {User} from "@models/user";
import {AlertService} from "@services/alert.service";
import {BehaviorSubject, Observable} from "rxjs";
import {NotifyService} from "@services/notify.service";


@Component({
  selector: 'app-register',
  templateUrl: 'register.component.html',
              styleUrls: ['register.component.scss','../login/login.component.scss']})
export class RegisterComponent implements OnInit {
  form!: FormGroup;
  loading = false;
  submitted = false;
  particulier = true;
  user!:User;
  isAdd = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: UserService,
    private notify: NotifyService,
    private alertService: AlertService,
    private service: AuthService
  ) {
  }

  // ngOnDestroy(): void {
  //   this.isEmailConfirmed.unsubscribe();
  // }

  ngOnInit() {
    if(this.router.url == "/list/add")
    {
      this.isAdd = true;
    }
    this.form = this.formBuilder.group({
      particulier: [''],
      phoneNumber:[''],
      email: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8),
        Validators.pattern(/\d/),
        Validators.pattern(/[A-Z]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/[!@#$%^&*()_+-={};':"|,.<>/?+-=]/)]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      if (this.form.invalid) {
        return;
      }
      this.form.value.particulier = this.particulier
      this.form.value.permissions = []
      this.form.value.profilType = "Utilisateur"
      console.log(this.form.value)
      this.loading = true;
      this.service.registerConfirmationEmail(this.form.value.email, this.form.value.password).then(data => {
        console.log(data);
        this.notify.success(`Un mail de confirmation vous a été envoyé`);
      });

      // console.log('LETS GOO')
      // this.accountService.register(this.form.value)
      //   .pipe(first())
      //   .subscribe({
      //     next: () => {
      //       this.router.navigate(['/login'], {relativeTo: this.route});
      //     },
      //     error: error => {
      //       this.alertService.error("Email déjà utilisé");
      //       this.loading = false;
      //     }
      //   });
  }
}
