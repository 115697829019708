import { NotifyService } from './notify.service';
import { AuthService } from '@services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { Size } from '@models/size';
import { Tile } from '@models/tile';
import { ConfigService } from './config.service';
import { BackendResponse } from '@models/response';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ThrowStmt } from '@angular/compiler';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TilesService extends ApiService {


  tileUpdated = new Subject<Tile>();
  tilePreview = new Subject<Tile>();
  sizes: Size[] = [];
  async getSize() {
    return await this.get<Size[]>('sizes').pipe(
      map((sizes: Size[]) => {
        return sizes.filter(s => s.isActive === true);
      })
    ).toPromise();
  }
  public getTileById(tileId: string): Observable<Tile> {
    return this.get<Tile>(`tiles/${tileId}`);
  }
  public getTile(pageId: string, waveId: string, tileId: string): Observable<Tile> {
    return this.get<Tile>(`tiles/${tileId}`);
  }

  public changeActive(pageId: string, waveId: string, tile: Tile): Observable<BackendResponse> {
    return this.post<BackendResponse>(`pages/${pageId}/waves/${waveId}/tiles/${tile.customID}/${tile.isActive ? 'enable' : 'disable'}`, {});
  }

  public addTile(pageId: string, waveId: string, tile: Tile): Observable<BackendResponse> {
    const clone = { ...tile };
    delete clone.size;
    return this.post<BackendResponse>(`pages/${pageId}/waves/${waveId}/tiles`, clone);
  }

  public clone(pageId: string, waveId: string, tile: Tile): Observable<any> {
    const clone = { ...tile };
    delete clone.customID;
    delete clone.createdAt;
    delete clone.updatedAt;
    delete clone.size;

    return this.post<BackendResponse>(`pages/${pageId}/waves/${waveId}/tiles`, clone);
  }

  duplicateTile(pageId: string, waveId: string, tileId: string): Observable<BackendResponse> {
    return this.put<BackendResponse>(`pages/${pageId}/waves/${waveId}/tiles/${tileId}`, null);
  }

  deleteTile(pageId: string, waveId: string, tile: Tile): Observable<BackendResponse> {
    return this.delete<BackendResponse>(`pages/${pageId}/waves/${waveId}/tiles/${tile.customID}`);
  }

  updateTile(pageId: string, waveId: string, tile: Tile): Observable<BackendResponse> {
    const clone = { ...tile };
    delete clone.size;
    return this.post<BackendResponse>(tile.customID ? `d/pages/${pageId}/waves/${waveId}/tiles/${tile.customID}` : 'tiles', clone);
  }

  async updateSize(tiles: Tile[]): Promise<Tile[]> {
    if (this.sizes.length === 0) {
      this.sizes = await this.getSize();
    }
    tiles.map(tile => {
      tile.size = this.sizes.find(s => s.customID === tile.sizeID);
    });
    return tiles;
  }

}
