<div class="blue-line"></div>
<div class="big-title">
<h2 class="nlue-title">Tuiles</h2><i (click)="toggleFullscreen()" class="fa fa-{{fullscreen ? 'compress' : 'expand'}}"></i></div>
<div class="search-bar">
  <div class="searchTool">
    <input #searchInput (keyup)="updateTerms($event)" placeholder="Rechercher" type="text" />
    <i (click)="resetSearch()" class="reset fa fa-times"></i>
  </div>
  <button *ngIf="checkPerm(wave,'update', '')" (click)="createTile()">Ajouter</button>
</div>
<div class="tile-list" class="{{layout}}">
  <div (drag)="changeOrder($event, tile)" [draggable]="allowGrab" (drop)="getOrder($event, tile)"
    [ngClass]="{'selected':(currentTile ? tile.customID === currentTile.customID : false) }"
    (click)="setCurrentTile(tile)" class="tile" *ngFor="let tile of tiles | termFilter: search! | sort:'printOrder'">
    <div class="tile-content">
      <div class="description noselect">
        <i class="fa fa-{{getType(tile)}}"></i>
        <label>{{tile.title}}</label>
        <span [style.backgroundColor]="tile.backgroundColor || 'transparent'" class="round color"></span>
        <div class="size" *ngIf="tile.size">
          {{tile.size?.title}}
        </div>
      </div>
      <div class="tools">
        <i title="Permissions" (click)="listPerm($event, tile)" class="round fa fa-list"></i>
        <i title="Preview" (click)="preview($event, tile)" class="round fa fa-eye"></i>
        <i title="Duppliquer" (click)="clone($event, tile)" class="round fa fa-copy"></i><i title="Exporter"(click)="exportTile($event, tile)" class="round fa fa-file-export"></i>
        <i title="Supprimer" (click)="deleteTile($event, tile)" class="round remove fa fa-trash"></i>
        <app-chip (activeChange)="updateTileActivity(tile, $event)" [active]="tile.isActive"
          [text]="tile.isActive ? 'Active' : 'Inactive'"></app-chip>
        <div class="updown">
          <i *ngIf="isNotOnTop(tile)" (click)="moveUp($event, tile)" class="fa fa-chevron-up"></i>
          <i *ngIf="isNotOnBottom(tile)" (click)="moveDown($event, tile)" class="fa fa-chevron-down"></i>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="id">
        {{tile.customID}}
      </div>
      <div class="position">
        <input *ngIf="edittingOrder(tile.customID);else showOrder" type="text" (click)="$event.stopPropagation()" (ngModelChange)="updateOrder($event, tile)" [ngModel]="tile.printOrder">
        <ng-template #showOrder>{{tile.printOrder}}</ng-template>
        <i (click)="toggleOrderEdit($event, tile.customID)" class=" round fa fa-pen"></i>
      </div>
    </div>
  </div>
</div>
