import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Credentials } from '@models/credentials';
import { AuthService } from '@services/auth.service';
import { FooterBackground, FooterService } from '@services/footer.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  signIn: boolean = false;
  credentials: Credentials;
  error: string;
  constructor(
    private authService: AuthService,
    private router: Router,
    private FooterService: FooterService
  ) {
    this.credentials = new Credentials();
    this.error = '';
  }

  async ngOnInit(): Promise<void> {
    if (
      this.authService.isLoggedIn() &&
      (await this.authService.checkTokenValidity())
    ) {
      this.router.navigate(['pages']);
    }
  }
  
  onSubmit(): void{
    this.error = '';
    this.authService
      .login(this.credentials.login, this.credentials.password)
      .then((logged) => {
        if (!logged) {
          this.error = 'email ou mot de passe erroné';
        } else {
          this.FooterService.SetFooterMode(FooterBackground.BlueConnected);
          this.router.navigate(['pages']);
        }
      });
  }
  initdb() {
    this.authService.initdb();
  }
}
