import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from "@services/auth.service";
import {first, map} from "rxjs/operators";
import {User} from "@models/user";
import {UserService} from "@services/user.service";
import {Observable, Subscription} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import { MatTableModule } from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import { BackendResponse } from '@models/response';

import {ActivatedRoute, Router} from "@angular/router";
import {NotifyService} from "@services/notify.service";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, AfterViewInit {
  public users:User[] =[];
  displayedColumns: string[] = ['firstName', 'lastName', 'profil', 'email', 'phoneNumber', 'companyName', 'action'];
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource!:MatTableDataSource<User>
  private idColumn = 'id';
  public roleSelect = "all";
  public isParticulier = "all";
  constructor(private accountService: UserService,private cdr: ChangeDetectorRef,
              private route: ActivatedRoute,
              private notifyService: NotifyService,) {
  }


  ngOnInit() {
    this.accountService.getAll()
      .subscribe(
        (users: User[]) => {
          // @ts-ignore
          this.users = users['data'];
          this.dataSource = new MatTableDataSource<User>(this.users);
          this.cdr.detectChanges();
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort
          console.log(this.users)
        }
    )
  }
  ngAfterViewInit() {

  }
  deleteUser(id: string) {
    this.accountService.delete(id)
      .pipe(first())
      .subscribe(result => {
        this.notifyService.success("Utilisateur supprimé")
        this.users = this.users.filter(x => x.customID !== id)
        this.deleteRowDataTable(id)
      },
      error => {
        this.notifyService.success("Echec lors de la suppression de l'utilisateur")
    });


  }

  getAll(){
    this.accountService.getAll()
      .subscribe(
        (users: User[]) => {
          // @ts-ignore
          this.dataSource.data = users['data'];
        });
  }
  private deleteRowDataTable (id:string) {
    const itemIndex = this.dataSource.data.findIndex(obj => obj["customID"] === id);
    this.dataSource.data.splice(itemIndex, 1);
    this.dataSource.paginator = this.paginator;
  }
  canAddUser():boolean{
    if(this.accountService.user?.profilType == "Admin" || this.accountService.user?.profilType == "Contributor")
    {
      return true;
    }
    else{
      return false;
    }

  }
  applyFilter(){
      this.accountService.getByRole(this.roleSelect, this.isParticulier)
      .subscribe(
        (users: User[]) => {
          // @ts-ignore
          this.users = users['data'];
          console.log(typeof this.users)
          if (this.users){
            this.dataSource = new MatTableDataSource<User>(this.users);
          }else{
            this.dataSource = new MatTableDataSource<User>();
          }
          
          this.cdr.detectChanges();
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort
        }
    )
    }
  roleChange(role: string) {
    this.roleSelect = role
  }
  isParticulierChange(type : string){
    if (type == "all"){
      this.isParticulier = "all";
    }else if (type == "true"){
      this.isParticulier = "true";
    }else{
      this.isParticulier = "false";
    }

  }
  

}
