import { AfterViewInit, Component, ElementRef, Input, OnInit, Output, ViewChild ,EventEmitter} from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Size } from '@models/size';
import { EditType } from '@models/tile';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-conf-template',
  templateUrl: './conf-template.component.html',
  styleUrls: ['./conf-template.component.scss']
})
export class ConfTemplateComponent implements AfterViewInit,OnInit {
  HTMLOUT: EditType = {html:""};

  constructor(private el:ElementRef){
    this.size = new Size();
    this.size.width = 300;
    this.size.height = 600;
  }

  htmlContent:string = ""
  backgroundcolor:string="white"
  
  @Input("text") text:EditType = {html:""};
  @Input("size") size: Size | undefined;
  @ViewChild("template1") template1!:ElementRef
  @ViewChild("template1content1") template1content1!:ElementRef
  @Output() SendOutput = new EventEmitter<EditType | string>()

  ngAfterViewInit(): void {
    if (this.size && this.size.width && this.size.height){
      this.template1.nativeElement.style.width = this.size.width + "px";
      this.template1.nativeElement.style.height = this.size.height + "px";
    }
    setTimeout( () => this.Test(),250);
  }

  ngOnInit(): void {
    if (this.text){
      this.htmlContent = this.text.html
    }
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  Test(){
    this.template1content1.nativeElement.innerHTML = this.ReSize(document.getElementsByClassName("angular-editor-textarea")[0].innerHTML);
    this.template1content1.nativeElement.style.backgroundColor = this.backgroundcolor
    this.template1.nativeElement.style.backgroundColor = this.backgroundcolor
  }

  SetBackgroundcolor(event:any) {
    this.backgroundcolor = event.target.value;
    this.Test()
  }
  
  ReSize(html:any){
    var size = [...html.matchAll(/size=\"/g)].map(data => data.index+6)
    var tamp = html
    html = html.replaceAll(/size=\".\"/g,"style=\"font-size:@@;\"")
    var res = tamp;
    Array.from(html).map( (x,index,tab) => {
        if (tab[index]=="@" && tab[index+1]=="@"){
            tab.splice(index+1,1)
            console.log(Number(tamp[size[0]])*8.5+100)
            tab[index] = tamp[size[0]] + "rem;line-height:"+Number(Number(tamp[size[0]])*2+100)+"%;"
            res = tab.join('')
            size.shift()
        }
    })

    this.HTMLOUT.html = res
    return res
  }

  Close(){
    this.SendOutput.emit("close")
  }

  SaveAndClose(){
    // var out = "<div style=\"height: 80%;width: 70%;overflow: hidden;\">"+this.HTMLOUT+"</div>"
    // console.log(out)
    this.HTMLOUT.params = this.backgroundcolor;
    this.SendOutput.emit(this.HTMLOUT)
    this.Close();
  }

}
