import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TilePermsComponent } from '@components/tile-perms/tile-perms.component';
import { PagesService } from '@services/pages.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Perm } from '@models/perm';
import { UserService } from '@services/user.service';
import { NotifyService } from '@services/notify.service';

@Component({
  selector: 'app-wave-perms',
  templateUrl: './wave-perms.component.html',
  styleUrls: ['./wave-perms.component.scss']
})
export class WavePermsComponent implements OnInit {

  tilesPermsComponent = TilePermsComponent;
  page: any;
  dataSource: any;
  columnsToDisplay: string[] = ['title', "isActive", "perms"];
  user: any;

  constructor(public dialog: MatDialog,
    private pageService: PagesService,
    private userService: UserService,
    private notifyService: NotifyService,
    @Inject(MAT_DIALOG_DATA) public data: { pageId: any, user: any }
  ) {
    this.user = this.data.user;
  }

  openDialog(waveId: any) {

    let transferedWave = this.dataSource.filter((data: { customID: any; }) => data.customID == waveId)[0];

    const dialogRef = this.dialog.open(this.tilesPermsComponent, {
      data: {
        tiles: transferedWave.tiles,
        waveTitle: transferedWave.title,
        user: this.user
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  ngOnInit(): void {

    this.pageService.getFullPage(this.data.pageId).subscribe((page: any) => {
      this.page = page;
      this.dataSource = page.waves;
    });
  }

  checkAll(wave: any) {
    let perms = ["read", "update", "import"];
    perms.forEach(perm => {
      this.removePerm(wave, perm);
      this.addPerm(wave, perm);
    })
  }

  checkAllWavesWithOnePerm(perm: any){
    this.page.waves.forEach((wave: any) => {
      if (this.hasPerm(wave, perm)) {
      } else {
        this.addPerm(wave, perm);
      }
    })
  }

  hasPerm(wave: any, perm: any) {
    let aPerm;
    if (this.user.permissions) {
      aPerm = this.user.permissions.find((e: { customIDElem: any; }) => e.customIDElem === wave.customID);
    }
    if (aPerm) {
      if (aPerm.permissions.includes(perm)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  setPerm(wave: any, permission: any) {
    if (this.hasPerm(wave, permission)) {
      console.log("remove perm")
      this.removePerm(wave, permission)
    }
    else {
      console.log("Add perm")
      this.addPerm(wave, permission)
    }
  }

  addPerm(wave: any, permission: any) {
    var i;
    var aPerm;
    var customId;
    i = this.user.permissions.findIndex((e: { customIDElem: any; }) => e.customIDElem === wave.customID);
    aPerm = this.user.permissions.find((e: { customIDElem: any; }) => e.customIDElem === wave.customID);
    customId = wave.customID
    if (i && aPerm) {
      if (!aPerm.permissions.includes(permission)) {
        this.user.permissions[i].permissions = aPerm.permissions + permission + '_'
      }
    }
    else {
      if (customId) {
        this.user.permissions.push(new Perm(customId, permission + '_'))
      }
    }
    console.log(this.user)
    this.userService.update(this.user.customID, this.user)
      .subscribe({
        next: () => {
          this.notifyService.success("Permission " + permission + " ajoutée  ")
        },
        error: () => {
          this.notifyService.failed("Une erreur est survenue")
        }
      });
  }

  removePerm(wave: any, permission: any) {
    var i;
    var aPerm;
    i = this.user.permissions.findIndex((e: { customIDElem: any; }) => e.customIDElem === wave.customID);
    aPerm = this.user.permissions.find((e: { customIDElem: any; }) => e.customIDElem === wave.customID);
    if (i && aPerm) {
      this.user.permissions[i].permissions = this.user.permissions[i].permissions.replace(permission + '_', "")
      //user.permissions.splice(i, 1)
    }
    console.log(this.user);
    this.userService.update(this.user.customID, this.user)
      .subscribe({
        next: () => {
          this.notifyService.success("Permission " + permission + " supprimée !  ")
        },
        error: () => {
          this.notifyService.failed("Une erreur est survenue")
        }
      });
  }
}
