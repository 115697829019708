import { LogoutComponent } from './pages/logout/logout.component';
import { AuthService } from '@services/auth.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentManagerComponent } from '@pages/content-manager/content-manager.component';
import { LoginComponent } from '@pages/login/login.component';
import { AuthGuard } from './auth.guard';
import { PagesComponent } from '@pages/content-manager/pages/pages.component';
import { PageEditComponent } from '@pages/content-manager/pages/page-edit/page-edit.component';
import { KPIComponent } from '@components/kpi/kpi.component';
import { FeedbackComponent } from '@components/feedback/feedback.component';
import { RegisterComponent } from "@pages/register/register.component";
import { ListComponent } from "@components/users/list/list.component";
import { AddEditComponent } from "@components/users/add-edit/add-edit.component";
import { ListPermComponent } from "@components/users/listPerm/list-perm/list-perm.component";
import { UserPermsComponent } from '@components/user-perms/user-perms.component';
import { ResetPasswordComponent } from "@components/reset-password/reset-password.component";
import { ResetPasswordConfirmationComponent } from "@components/reset-password-confirmation/reset-password-confirmation.component";
import { RegisterConfirmationEmailComponent } from "@components/register-confirmation-email/register-confirmation-email.component";

const routes: Routes = [
  {
    path: '',
    component: ContentManagerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'pages',
        component: PagesComponent,
        data: { title: '' },
      },
      {
        path: 'pages/:id/edit',
        component: PageEditComponent,
        data: { title: 'Edition des pages' }
      },
      {
        path: 'kpi',
        component: KPIComponent,
        data: { title: '' }
      },
      {
        path: 'feedback',
        component: FeedbackComponent,
        data: { title: '' }
      },
      {
        path: 'list',
        component: ListComponent,
        data: { title: 'Liste des utilisateurs' },
      },
      
      {
        path: 'list/add',
        component: AddEditComponent

      },
      {
        path: 'list/edit/:customID',
        component: AddEditComponent,
        data: { title: 'Ajout utilisateur' },

      },

      {
        path: 'perm/:customID/:type',
        component: ListPermComponent,
        data: { title: 'Modification des permissions de la ' },
      },

      {
        path: 'userPerms/:customID',
        component: UserPermsComponent,
        data: { title: 'Modification des permissions utilisateur' },
      },
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'resetPassword',
    component: ResetPasswordComponent
  },
  {
    path: 'registerConfirmationEmail/:token',
    component: RegisterConfirmationEmailComponent
  },
  {
    path: 'resetPasswordConfirmation/:token',
    component: ResetPasswordConfirmationComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },

  {
    path: 'logout',
    component: LogoutComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthService]
})
export class AppRoutingModule { }
