import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import {AuthService} from "@services/auth.service";
import {UserService} from "@services/user.service";
import {User} from "@models/user";
import {Perm} from "@models/perm";




@Component({
  selector: 'app-registerpro',
  templateUrl: 'registerpro.component.html',
  styleUrls: ['register.component.scss','../login/login.component.scss']})
export class RegisterproComponent implements OnInit {
  form!: FormGroup;
  loading = false;
  submitted = false;
  particulier = false;
  isAdd = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: UserService
  ) { }

  ngOnInit() {

    if(this.router.url == "/list/add")
    {
      this.isAdd = true;
    }
    this.form = this.formBuilder.group({
      particulier: [''],
      firstName: ['', Validators.required],
      phoneNumber:['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      companyName: ['', Validators.required],
      comment: [''],
      password: ['', [Validators.required, Validators.minLength(8),
        Validators.pattern(/\d/),
        Validators.pattern(/[A-Z]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/[!@#$%^&*()_+-={};':"|,.<>/?+-=]/)]]

    });
  }

  // convenience getter for easy access to form fields**
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;
    console.log(this.form.value.email)
    //this.accountService.getByEmail(this.form)
    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.form.value.profilType = "Utilisateur"
    this.form.value.particulier = this.particulier
    this.form.value.permissions = []
    this.form.value.permissions.push(new Perm("basique perm", "read_update_delete_import"))
    console.log(this.form.value)
    this.loading = true;
    this.accountService.register(this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.router.navigate(['/login'], { relativeTo: this.route });
        },

      });
  }
}
