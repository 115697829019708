<div class="nav">
  <a [routerLink]="'/pages'"><i class="fa fa-chevron-left"></i> <span class="ViewPages">Voir toutes les pages</span></a>
  <a class="title_page">Edition de Page</a>
</div>
<div class="layout" *ngIf="!isExporting">
  <div class="waves">
    <h2>Vagues</h2>
    <div (click)="addWaveDialog()" class="addItem" *ngIf="checkPerm(page.customID,'update', page.tiersID)">
      <i class="fas fa-plus-circle"></i>
    </div>
    
    <div *ngFor="let wave of page.waves">
      <div (click)="switchWave(wave)" [ngClass]="{'item-active':wave.isActive,
                                                'item-inactive':!wave.isActive,
                                                'selected':wave.customID === currentWave?.customID}" class="wave"
        style="padding-top: 10%;padding-bottom: 5%;">
        <svg style="display: flex; position:absolute;top:0; left:91%;" (mouseleave)="showContextMenu($event, wave)"
          (mouseover)="showContextMenu($event, wave)" width="4" height="22" viewBox="0 0 5 23" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="2.5" cy="20.5" r="2.5" fill="#6E6D7A" />
          <circle cx="2.5" cy="11.5" r="2.5" fill="#6E6D7A" />
          <circle cx="2.5" cy="2.5" r="2.5" fill="#6E6D7A" />
        </svg>
        
        <div class="waveTitle">{{wave.title}}
          <!-- <i class="fa fa-ellipsis-v" (mouseleave)="showContextMenu($event, wave)"
          (mouseover)="showContextMenu($event, wave)"></i> -->
        </div>
        <div class="wave-tools">
          <div class="tilesCount" [innerHTML]="tilesCount(wave)"></div>
          <div class="updown">
            <i (click)="moveWaveUp($event, wave)" style="position:absolute;top:33%;" class="fa fa-chevron-up"></i>
            <i (click)="moveWaveDown($event, wave)" style="position:absolute;top:56%;" class="fa fa-chevron-down"></i>
          </div>
        </div>

        <div class="bottom">
          <div class="id">
            <!-- {{wave.customID}} -->

          </div>
          <div class="position">
            <input *ngIf="edittingOrder(wave.customID);else showOrder" type="text" (click)="$event.stopPropagation()"
              (ngModelChange)="updateOrder($event, wave)" [ngModel]="wave.printOrder">
            <ng-template (click)="toggleOrderEdit($event, wave.customID)" #showOrder>
              <div id="WaveOrder">{{wave.printOrder}}</div>
            </ng-template>
            <!-- <i (click)="toggleOrderEdit($event, wave.customID)" class=" round fa fa-pen"></i> -->
            <svg width="20" style="margin-left:5%;" (click)="toggleOrderEdit($event, wave.customID)" height="20"
              viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M3.33398 16.6666H6.66732L15.4173 7.91663C15.8593 7.47461 16.1077 6.87509 16.1077 6.24997C16.1077 5.62485 15.8593 5.02533 15.4173 4.5833C14.9753 4.14127 14.3758 3.89294 13.7507 3.89294C13.1255 3.89294 12.526 4.14127 12.084 4.5833L3.33398 13.3333V16.6666Z"
                stroke="#6E6D7A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M11.25 5.41663L14.5833 8.74996" stroke="#6E6D7A" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <app-context-menu [menuItems]="menuItems"></app-context-menu>
  </div>
  <div class="edit">
    <div *ngIf="!tilesFullScreen" class="page-area">
      <div class="title-tools">
        <h2 style="color: var(--secondary-color);">Page</h2>
        <div class="page-edit-tools">

          <button *ngIf="checkPerm(page.customID,'update',page.tiersID)" (click)="toggleSize = true"><i
              class="far fa-object-ungroup"></i>Modifier les tailles</button>
          <button id="AffChange" [attr.disabled]="hasNoChange" (click)="refreshFront()"><i
              class="fa fa-sync"></i>Affecter les changement sur le front</button>
        </div>
      </div>

      <div class="header" *ngIf="page">
        <div class="title row">
          <label>Titre de la page</label>
          <!-- <mat-form-field appearance="legacy">
            <mat-label>Input</mat-label>
            <input matInput>
          </mat-form-field> -->
          <!-- <input (change)='updatePage()' type="text" class="overedit" [(ngModel)]="page.title"><i class="fa fa-pen"></i> -->
          <span style="margin-left: 33%; margin-right: auto;">{{page.title}}</span>

          <app-chip *ngIf="checkPerm(page.customID,'update', page.tiersID)" (activeChange)="updatePageActivity($event)"
            [active]="page.isActive" [text]="page.isActive ? 'Active' : 'Inactive'"></app-chip>
        </div>

      </div>
      <div class="description row">
        <label>Description</label>
        <textarea (change)='updatePage()' type="text" class="overedit page-description" [(ngModel)]="page.description"
          [disabled]="!checkPerm(page.customID,'update', page.tiersID)">
        </textarea>
        <i class="fa fa-pen"></i>
      </div>
      <div class="description row">
        <label style="text-align: start;">Code personnalisé</label><input (change)='updatePage()' type="text"
          class="overedit" [disabled]="!checkPerm(page.customID,'update', page.tiersID)" [(ngModel)]="page.customCode"
          style="margin-left:20px"><i class="fa fa-pen"></i>
      </div>
      <div class="description row">
        <label>Lien de la page</label>
        <select style="border: 0px;border-bottom: 1px solid;border-color: #C4C4C4;">
          <option >{{EnvName}}</option>
        </select>
        <input #generatedLink type="text" [value]="generatedUrl"
          style="border: 0px;border-bottom: 1px solid;border-color: #C4C4C4;" />
        <i (click)="copyLink()" style="margin-right: 20px;margin-left: 10px;" class="_blank"><img
            src="../../../../../assets/image/Group 191.svg"></i>
        <a class="buttonlink" target="_blank" href="{{generatedUrl}}">
          <!-- <i class="round fa fa-link"></i> --><img src="../../../../../assets/image/Group 192.svg"> </a>
      </div>
      <div class="description row">
        <label>Catégorie menu</label>
        <select name="" id="" [(ngModel)]="page.categoriePage" (change)="updateCategorieMenu(page.categoriePage)"
          style="margin-left: 5px;">
          <option value="General">General</option>
          <option value="Tendances">Tendances</option>
          <option value="Invisible">Invisible</option>
        </select>
      </div>
      <div class="description row">
        <label>Couleur menu 1</label>
        <input type="color" style="margin: 5px" [(ngModel)]="page.menuColor"
          (change)="updateColorsMenu(page.menuColor)">
        <label>Couleur menu 2</label>
        <input type="color" style="margin: 5px" [(ngModel)]="page.menuColorGradient"
          (change)="updateColorsMenuGradient(page.menuColorGradient)">
      </div>
    </div>
    <div *ngIf="currentWave" class="wave-edit">
      <div *ngIf="tilesFullScreen" class="summary">
        <div class="row">
          <label>Page</label>
          <span style="margin-left:41.6%">{{page.title}}</span>
        </div>
        <div class="row">
          <label>Vague</label>
          <span style="margin-left:40%">{{currentWave.title}}</span>
        </div>
      </div>
      <div class="big-title row" style="justify-content: center ; border-bottom:1px solid #f0f0f0;">
        <h2 style="color: var(--secondary-color);">Vague</h2>
      </div>

      <div *ngIf="!tilesFullScreen" class="wave-attributes">
        <div class="wave-detail" style="display: flex; justify-content: space-between">
          <div class="title" style="margin: 0px;padding:0px">
            <label>Titre de la vague</label><input (change)='updateWave()' type="text" class="overedit"
              [disabled]="!checkPerm(currentWave.customID,'update', currentWave.tiersID)"
              [(ngModel)]="currentWave.title"><i class="fa fa-pen"></i>
          </div>
          <div class="row">
            <label style="text-align: start;">Code personnalisé</label><input (change)='updateWave()' type="text"
              class="overedit" [(ngModel)]="currentWave.customCode"
              [disabled]="!checkPerm(currentWave.customID,'update', currentWave.tiersID)"><i class="fa fa-pen"></i>
          </div>
          <div class="tools row">
            <i *ngIf="checkPerm(currentWave.customID, 'delete', currentWave.tiersID)" (click)="removeWave(currentWave)"
              class="remove"><img src="../../../../../assets/image/Group 194.svg"></i>
            <app-chip (activeChange)="updateWaveActivity($event)" [active]="currentWave.isActive"
              [text]="currentWave.isActive ? 'Active' : 'Inactive'" class="ml"></app-chip>
          </div>
        </div>
        <div class="row" style="justify-content: space-between;">
          <div class="row">
          <label>Description</label><textarea (change)="updateWave()" type="text" class="overedit wave-description"
            [(ngModel)]="currentWave.description"
            [disabled]="!checkPerm(currentWave.customID,'update', currentWave.tiersID)"></textarea><i
            class="fa fa-pen"></i>
          </div>
        <div class="row">
          <label style="padding-right: 25px;">Tuiles Aléatoires</label>
        
        <app-chip (change)='updateWave()' (activeChange)="updateRandWaveActivity($event)"
        [active]="currentWave.isRandomized" [text]="currentWave.isRandomized ? 'Active' : 'Inactive'">
        </app-chip>
      </div>
      <div class="row">
        <label style="padding-right: 25px;">Tuile primaire</label>
        
        <app-chip (change)='updateWave()' (activeChange)="updateIsPrimary($event)"
        [active]="currentWave.isPrimary" [text]="currentWave.isPrimary ? 'Active' : 'Inactive'">
        </app-chip>
    </div>
      </div>
        <div class="blue-line"></div>
        <div #type class="row" style="justify-content: space-between;">
          <div class="row" style="margin-right: 150px; ">
            <label style="text-align: start;">Bandeau image</label>
            <select required (ngModelChange)="updateForm($event)" [ngModel]="currentWave.bandeauType">
              <option [selected]="currentWave.bandeauType === style.value" [value]="style.value"
                *ngFor="let style of BandeauType">
                {{style.title}}</option>
            </select>
          </div>
          <div class="row">
            <label>Titre du bandeau</label><input (change)='updateWave()' type="text" class="overedit"
              [disabled]="!checkPerm(currentWave.customID,'update', currentWave.tiersID)"
              [(ngModel)]="currentWave.bandeau"><i class="fa fa-pen"></i>
          </div>

          <div class="tools row">
            <div>
              <i *ngIf="checkPerm(currentWave.customID, 'delete', currentWave.tiersID)" (click)="removeBandeau()"
                class="remove"><img src="../../../../../assets/image/Group 194.svg"></i>
            </div>
            <div>
              <i *ngIf="currentWave.customID != undefined && currentWave.customID != null" title="Exporter" (click)="exportBandeau(currentWave)" class="round fa fa-file-export"></i>
            </div>
            <div class="ml">
              <app-chip (change)='updateWave()' (activeChange)="updateWaveBandeauActivity($event)"
                [active]="currentWave.bandeauIsActive" [text]="currentWave.bandeauIsActive ? 'Active' : 'Inactive'">
              </app-chip>
            </div>

          </div>

        </div>

        <br>
        <div *ngIf="currentWave.bandeauType == 'image-mini'">
          <div class="description row">
            <label style="text-align: start;">Description du bandeau</label><textarea (change)='updateWave()'
              type="text" class="overedit" [disabled]="!checkPerm(currentWave.customID,'update', currentWave.tiersID)"
              [(ngModel)]="currentWave.descriptionBandeau"></textarea><i class="fa fa-pen"></i>
          </div>
        </div>
        <div #fileInput class="row">
          <label style="text-align: start;">image du bandeau</label>
          <button style="margin-left:1.5%" (click)="imageBandeau.click()">Select File</button>
          <input #imageBandeau class="noWidht" (change)="getFileBandeau($event)" type="file" title="" />
          <i class="fa fa-floppy"></i>
          <p class="inputHexa">
            <label>Couleur hexa</label><input style="width:100px" class="overedit" name="group1" type="text"
              id="pickcolor4" value="" (change)="onColorBnadeauChange($event)" />
          </p>
          <p>
            <input name="group1" type="radio" id="pickcolor7" value="#8cde9b" (change)="onColorBnadeauChange($event)" />
            <label for="pickcolor7" style="width:50px">Vert</label>
          </p>
          <p>
            <input name="group1" type="radio" id="pickcolor5" value="#8cdedc" (change)="onColorBnadeauChange($event)" />
            <label for="pickcolor5" style="width:50px">Bleu</label>
          </p>
          <p>
            <input name="group1" type="radio" id="pickcolor6" value="#c96d6d" (change)="onColorBnadeauChange($event)" />
            <label for="pickcolor6" style="width:50px">Rouge</label>
          </p>
        </div>
        <br>
        <div class="blue-line"></div>
        <div #fileInput class="row">
          <label style="width:108px">Bandeau video</label>
          <button style="width:107px; margin-left:42px" (click)="videoHeader.click()">Select File</button>
          <input class="noWidht" #videoHeader (change)="getFileVideoHeader($event)" type="file" title="" />
          <i class="fa fa-floppy"></i>
          <p class="inputHexa">
            <label>Couleur hexa</label><input style="width:100px" class="overedit" name="group1" type="text"
              id="pickcolor1" value="" (change)="onColorBandeauVideoChange($event)" />
          </p>
          <p>
            <input name="group1" type="radio" id="pickcolor0" value="#8cde9b"
              (change)="onColorBandeauVideoChange($event)" />
            <label for="pickcolor0" style="width:50px">Vert</label>
          </p>
          <p>
            <input name="group1" type="radio" id="pickcolor2" value="#8cdedc"
              (change)="onColorBandeauVideoChange($event)" />
            <label for="pickcolor5" style="width:50px">Bleu</label>
          </p>
          <p>
            <input name="group1" type="radio" id="pickcolor3" value="#c96d6d"
              (change)="onColorBandeauVideoChange($event)" />
            <label for="pickcolor6" style="width:50px; margin-right:1em">Rouge</label>
          </p>
          <div class="tools row">
            <div>
              <i *ngIf="checkPerm(currentWave.customID, 'delete', currentWave.tiersID)" (click)="removeBandeauVideo()"
                class="remove"><img src="../../../../../assets/image/Group 194.svg"></i>
            </div>
            <div class="ml">
              <app-chip (change)='updateWave()' (activeChange)="updateWaveVideoBandeauActivity($event)"
                [active]="currentWave.bandeauVideoIsActive"
                [text]="currentWave.bandeauVideoIsActive ? 'Active' : 'Inactive'"></app-chip>
            </div>
          </div>
        </div>
        <br>
        <br>
        <div class="row" style="justify-content: space-between">
          <div>
            <section class="example-section">
              <mat-checkbox style="margin-top: 0px !important;" (change)="updateWave()" name="currentWave.isShortList"
                [(ngModel)]="currentWave.isShortList" class="overedit">Bouton en voir plus</mat-checkbox>
            </section>
          </div>
          <div class="row">
            <label>Taille de la goutière web</label><input (change)='updateWave()' style="border: 0;" type="number"
              class="overedit" [(ngModel)]="currentWave.gutterSize">

          </div>
          <div class="row">
            <label>Taille de la goutière Mobile</label><input (change)='updateWave()' style="border: 0;" type="number"
              class="overedit" [(ngModel)]="currentWave.gutterSizeMobile">
          </div>
        </div>
      </div>

      <app-tile-list [ngClass]="{'fullscreen': tilesFullScreen}" [(fullscreen)]="tilesFullScreen"
        (showPreview)="preview($event)" (tileChange)="editTile($event)" [page]="this.pageId"
        [wave]="currentWave.customID!" (export)="prepareTileExport($event)">
      </app-tile-list>

    </div>

  </div>
  <app-tile-editor (showPreview)="preview($event)" *ngIf="this.currentWave" [page]="this.pageId"
    [wave]="this.currentWave.customID!" [(tile)]="selectedTile"></app-tile-editor>
  <app-tile-preview [ngClass]="{'editmode':selectedTile !== undefined}" (closed)="previewTile = undefined!"
    [tile]="previewTile!"></app-tile-preview>
  <app-popup [(toggled)]="toggleRenamePopup" (onValidate)="renameWave()" [disabled]="!waveTitleValid(currentWaveTitle)"
    [title]="'Renommer la page'">
    <label>Nom de la vague</label>
    <input type="text" [(ngModel)]="currentWaveTitle">
  </app-popup>
  <app-popup [(toggled)]="togglePopup" (onValidate)="addWave()" [disabled]="!waveTitleValid(newWaveTitle)"
    [title]="'Ajout d\'une nouvelle vague'" class="wave-creator">
    <div class="line">
      <label>Nom de la vague</label>
      <input type="text" [(ngModel)]="newWaveTitle">
    </div>
    <div class="line">
      <label>Description de la vague</label>
      <input type="text" [(ngModel)]="newWaveDescription">
    </div>
  </app-popup>
  <app-popup [labels]="{cancel: 'Fermer', valid:undefined}" [(toggled)]="toggleSize" (onValidate)="updateSizes()"
    [title]="'Modification des tailles'">
    <app-sizes-editor #sizesEditor></app-sizes-editor>
  </app-popup>
</div>
<app-export-choice *ngIf="isExporting" [waveTitle]="waveTitle" [pageTitle]="pageTitle" [itemType]="exportItem"
  (onCancel)="cancelExport()" (onConfirm)="exportChoice($event)"></app-export-choice>
