<div mat-dialog-content>
  <h1 class="title">Tuiles de la vague : {{waveName}} <button mat-dialog-close>X</button></h1>
  <table mat-table class="mat-elevation-z8" [dataSource]="tiles">
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>Nom de la tuile </th>
      <td mat-cell *matCellDef="let tile of tiles"> {{tile.title}} </td>
    </ng-container>
    <ng-container matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef> Activité </th>
      <td mat-cell *matCellDef="let tile of tiles"> {{tile.isActive == true ? 'Activée': 'Désactivée'}} </td>
    </ng-container>
    <ng-container matColumnDef="perms">
      <th mat-header-cell *matHeaderCellDef> Permissions 
        <button (click)="checkAllTilesWithOnePerm('read')">Lire tous</button>
        <button (click)="checkAllTilesWithOnePerm('update')">Modifier tous</button>
        <button (click)="checkAllTilesWithOnePerm('delete')">Supprimer tous</button>
        <button (click)="checkAllTilesWithOnePerm('import')">Importer tous</button>
      </th>
      <td mat-cell *matCellDef="let user; let tile of tiles">
        <!--      <button routerLink="edit/{{user.customID}}" class="btn btn-sm btn-primary mr-1">Modifier</button>-->
        <mat-checkbox (change)="setPerm(tile,'read')" [checked]="hasPerm(tile, 'read')"><span>Lire</span></mat-checkbox>
        <mat-checkbox (change)="setPerm(tile,'update')" [checked]="hasPerm(tile, 'update')"><span>Modifier</span></mat-checkbox>
        <mat-checkbox (change)="setPerm(tile,'delete')" [checked]="hasPerm(tile, 'delete')"><span>Supprimer</span></mat-checkbox>
        <mat-checkbox (change)="setPerm(tile,'import')" [checked]="hasPerm(tile, 'import')"><span>Importer</span></mat-checkbox>
        <button (click)="checkAll(tile)">Toutes</button>
        <button routerLink="/list/add" class="btn btn-sm btn-primary mr-1">Ajouter un utilisateur</button>
      </td>
    </ng-container>

    <!-- ajouter les contributeurs de chaque page en tant que colonne -->

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
  </table>
</div>
