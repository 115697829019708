import { IData } from '../interfaces/data';
import { Wave } from './wave';
export class FullPage implements IData {
  id?: string;
  customID?: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  isActive: boolean;
  deleted?: boolean;
  owner?: string;
  description?: string;
  title?: string;
  waves?: Wave[];
  tiersID!: string;
  customCode?: string;
  categoriePage?: string;
  menuColor?: string;
  menuColorGradient?: string;
  constructor() {
    this.isActive = false ;
    this.deleted = false ;
    this.waves = [];
  }
}
