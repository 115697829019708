import { AuthService } from '@services/auth.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-content-manager',
  templateUrl: './content-manager.component.html',
  styleUrls: ['./content-manager.component.scss']
})
export class ContentManagerComponent implements OnInit {

  title = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService) {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/pages']);
    }
  }

  ngOnInit(): void {
  }

}
