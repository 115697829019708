import { YesnoComponent } from './../yesno/yesno.component';
import { Component, Input, OnInit, Output, EventEmitter, HostBinding, HostListener, Host, ViewChild } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  private _toggled = false;

  @Input()
  get toggled(): boolean {
    return this._toggled;
  }
  set toggled(value: boolean) {
    if (!value) {
      this.isClosing = true;
      setTimeout(() => {
        this._toggled = false;
        this.isClosing = false;
        this.onClose.emit(true);
        this.toggledChange.emit(this._toggled)
      }, 300);
    } else {
      this._toggled = value;
      this.toggledChange.emit(this._toggled)
    }
  }
  @Output() toggledChange = new EventEmitter<boolean>();
  @Input() modal = true;
  @Input() title = '';
  @Input() closable = true;
  @Input() labels: { cancel: string | undefined, valid: string | undefined } = {
    cancel: 'Annuler',
    valid: 'Valider'
  };

  @Input() disabled = false;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onValidate = new EventEmitter<boolean>();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onCancel = new EventEmitter<boolean>();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onClose = new EventEmitter<boolean>();
  isClosing = false;

  @ViewChild(YesnoComponent) yesNo!: YesnoComponent;

  @HostBinding('class.closing') get close(): boolean { return this.isClosing; }
  @HostBinding('class.opened') get opened(): boolean { return this.isClosing || this.toggled; }


  constructor() { }
  valid(): void {
    if (!this.disabled) {
      this.onValidate.emit(true);
      this.toggled = false;
    }
  }

  cancel(): void {
    this.onCancel.emit(true);
    this.toggled = false;
  }

  ngOnInit(): void {
    if (this.labels) {
      setTimeout(() => {
        if (this.yesNo) {
          this.yesNo.labels = this.labels;
        }

      });
    }
  }

}
