import { Component, OnInit, HostListener, HostBinding, ElementRef, Output, EventEmitter, ChangeDetectorRef, Input } from '@angular/core';
import { IMenuItem } from '../../interfaces/components/IMenuItem';
@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss', './context-menu.animations.scss']
})
export class ContextMenuComponent implements OnInit {

  @Input() toggle = false ;
  @Input() menuItems: IMenuItem[] = [];
  public item: any;

  public inside = false;
  @Output() click = new EventEmitter<IMenuItem>();
  @Output() toggled = new EventEmitter<boolean>();

  set X(value: number) {
    if (!this.toggle) {
      this.el.nativeElement.style.left = (value - 5) + 'px';
    }
  }

  set Y(value: number) {
    if (!this.toggle) {
      this.el.nativeElement.style.top = (value - 8) + 'px';
    }
  }

  set width(value: number) {
    if (!this.toggle) {
      this.el.nativeElement.style.width = value + 'px';
    }
  }

  @HostBinding('class.toggled') get onShow() {
    return this.toggle;
  }

  @HostListener('mouseenter', ['$event'])
  onOver(event: MouseEvent): void {
    this.inside = true;
    this.el.nativeElement.classList.remove('fadeout');
  }

  @HostListener('mouseleave', ['$event'])
  onLeave(event: MouseEvent): void {
    this.inside = false;
    this.el.nativeElement.classList.add('fadeout');
    setTimeout(() => {
      if (!this.inside) {
        this.toggle = false;
        this.el.nativeElement.classList.remove('fadeout');
      }
    }, 150);
  }


  hasAtLeastOneIcon(): boolean {
    return this.menuItems.findIndex(mi => mi.icon !== undefined) !== -1;
  }

  itemClicked(item: IMenuItem) {
    if (item.callback) {
      item.callback(this.item);
    }
    this.click.emit(item);
  }

  constructor(public el: ElementRef,
              private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

}
