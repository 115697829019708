import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Page } from '@models/page';
import { PagesService } from '@services/pages.service';
import { MatTableDataSource } from "@angular/material/table";
import { UserService } from '@services/user.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WavePermsComponent } from '@components/wave-perms/wave-perms.component';
import { NotifyService } from '@services/notify.service';
import { Perm } from '@models/perm';


@Component({
  selector: 'app-user-perms',
  templateUrl: './user-perms.component.html',
  styleUrls: ['./user-perms.component.scss']
})
export class UserPermsComponent implements OnInit {

  public pages: Page[] = [];
  columnsToDisplay: string[] = ['title', "isActive", "perms"];
  dataSource!: MatTableDataSource<Page>
  user: any;
  userCustomId: any;
  wavePermsComponent = WavePermsComponent;

  constructor(private pageService: PagesService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private userService: UserService,
    private notifyService: NotifyService) {
    this.pageService.getPages().subscribe((pages: Page[]) => {
      this.pages = pages.filter(p => !p.deleted);
      this.dataSource = new MatTableDataSource<Page>(this.pages);
    });
  }

  openDialog(pageId: any) {

    const dialogRef = this.dialog.open(this.wavePermsComponent, {
      data: {
        pageId: pageId,
        user: this.user
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  ngOnInit(): void {
    this.userCustomId = this.route.snapshot.paramMap.get('customID');
    this.user = this.userService.getById(this.userCustomId).subscribe(
      (data => {
        this.user = data;
        this.user = this.user.data;
      })
    );
  }

  checkAll(page: any) {
    let perms = ["read", "update", "import"];
    perms.forEach(perm => {
      this.removePerm(page, perm);
      this.addPerm(page, perm);
    })
  }

  checkAllPagesWithOnePerm(perm: any){
    this.pages.forEach(page => {
      if (this.hasPerm(page, perm)) {
      } else {
        this.addPerm(page, perm);
      }
    })
  }

  hasPerm(page: any, perm: any) {
    let aPerm;
    if (this.user.permissions) {
      aPerm = this.user.permissions.find((e: { customIDElem: any; }) => e.customIDElem === page.customID);
    }
    if (aPerm) {
      if (aPerm.permissions.includes(perm)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  setPerm(page: any, permission: any) {
    if (this.hasPerm(page, permission)) {
      console.log("remove perm")
      this.removePerm(page, permission)
    }
    else {
      console.log("Add perm")
      this.addPerm(page, permission)
    }
  }

  addPerm(page: any, permission: any) {
    var i;
    var aPerm;
    var customId;
    i = this.user.permissions.findIndex((e: { customIDElem: any; }) => e.customIDElem === page.customID);
    aPerm = this.user.permissions.find((e: { customIDElem: any; }) => e.customIDElem === page.customID);
    customId = page.customID
    if (i && aPerm) {
      if (!aPerm.permissions.includes(permission)) {
        this.user.permissions[i].permissions = aPerm.permissions + permission + '_'
      }
    }
    else {
      if (customId) {
        this.user.permissions.push(new Perm(customId, permission + '_'))
      }
    }
    console.log(this.user)
    this.userService.update(this.user.customID, this.user)
      .subscribe({
        next: () => {
          this.notifyService.success("Permission " + permission + " ajoutée  ")
        },
        error: () => {
          this.notifyService.failed("Une erreur est survenue")
        }
      });
  }

  removePerm(page: any, permission: any) {
    var i;
    var aPerm;
    i = this.user.permissions.findIndex((e: { customIDElem: any; }) => e.customIDElem === page.customID);
    aPerm = this.user.permissions.find((e: { customIDElem: any; }) => e.customIDElem === page.customID);
    if (i && aPerm) {
      this.user.permissions[i].permissions = this.user.permissions[i].permissions.replace(permission + '_', "")
      //user.permissions.splice(i, 1)
    }
    console.log(this.user);
    this.userService.update(this.user.customID, this.user)
      .subscribe({
        next: () => {
          this.notifyService.success("Permission " + permission + " supprimée !  ")
        },
        error: () => {
          this.notifyService.failed("Une erreur est survenue")
        }
      });
  }
}
