<ul>
  <li id="{{item.name}}" (click)="itemClicked(item)" *ngFor="let item of menuItems">
    <i *ngIf="item.icon ; else blank" class="fa fa-{{item.icon}}"></i>
    <ng-template #blank>
      <span *ngIf="hasAtLeastOneIcon()" class="blank"></span>
    </ng-template>

    {{item.label}}
  </li>
</ul>
