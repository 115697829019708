import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';


import {UserService} from "@services/user.service";
import {ConfigService} from "@services/config.service";
import {HttpConstants} from "./constants/http.constants";
import {AuthService} from "@services/auth.service";


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private accountService: UserService,private config:ConfigService, private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url === 'api/refresh-token') {
      return next.handle(request);
    }
    // add auth header with jwt if user is logged in and request is to the api url

    const token = localStorage.getItem(HttpConstants.AccessToken)
    const isLoggedIn = this.authService.isLoggedIn()
    const isApiUrl = request.url.startsWith(this.config.endpoint);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          'access-token': `${token}`
        }
      });
    }

    return next.handle(request);
  }
}
