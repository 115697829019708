import { BehaviorSubject } from 'rxjs';
import { environment } from './../../environments/environment';
import { BackendResponse } from './../models/response';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PublicApiService extends ApiService {

  updateDone = new BehaviorSubject<boolean>(true);
  refreshPublicApi(): void {
    this.http.get<BackendResponse>(`${environment.publicapi}/cachereload`).subscribe(() => {
      this.updateDone.next(true);
    });
  }

}
