<div class="card">
    <app-tile-preview
        [tile]="previewTile!"
        style="width: fit-content;height: unset;position: relative;z-index: 0;">
        <ng-container #preview></ng-container>
    </app-tile-preview>
    <div>
        <angular-editor (keyup)="changeText()" [(ngModel)]="htmlContent" [config]="editorConfig"></angular-editor>
        <button (click)="changeView()">Change View</button>
        <button (click)="CreateNewBanniere()">Create New Banniere</button>
        <div class="buttonsend">
            <button (click)="SaveAndQuit()">Enregistrer et Fermer</button>
            <button (click)="Quit()">Fermer</button>
        </div>
    </div>
</div>