import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PagesService } from '@services/pages.service';
import { Page } from '@models/page';
import { Wave } from '@models/wave';
import { FullPage } from '@models/full-page';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyService } from '@services/notify.service';
import { WavesService } from '@services/waves.service';
import { BackendResponse } from '@models/response';

@Component({
  selector: 'app-export-choice',
  templateUrl: './export-choice.component.html',
  styleUrls: ['./export-choice.component.scss']
})
export class ExportChoiceComponent implements OnInit {
  // exportId: string = "";
  step: number = 0;
  pages: Page[] = [];
  waves?: Wave[] = [];
  waveSelectedID: string | undefined = "";
  pageSelectedID: string | undefined = "";
  tileowave:boolean = false;
  fromPage: string = "";
  fromWave: string = "";
  confirmText: string = "";
  PageTitles: string | undefined;
  WaveTitles: string | undefined;
  @Input() waveTitle : string |undefined;
  @Input() pageTitle: string | undefined;
  stepName = [
    "page",
    "vague",
    ""
  ]

  private _itemType: string = ""
  @Input()
  set itemType(value: string) {
    this._itemType = value;
    this.pageService.getPages().subscribe((pages: Page[]) => {
      this.pages = pages.filter(p => !p.deleted);
    });
  }
  get itemType(): string {
    return this._itemType;
  }
  @Output() onCancel = new EventEmitter<boolean>();
  @Output() onConfirm = new EventEmitter<{pageId: string, waveId?: string}>();

  constructor(
    private pageService: PagesService,
    private router: Router,
    private route: ActivatedRoute,
    private notify: NotifyService,
    private waveService: WavesService
  ) {
  }

  ngOnInit(): void {
  }

  cancel(): void {
    console.log('export -> cancel')
    this.onCancel.emit(true);
  }

  confirm(): void {
    console.log('export -> confirm')
    // let id = this.itemType === "tile" ? this.waveSelectedID : this.pageSelectedID;
    this.onConfirm.emit({pageId: this.pageSelectedID as string, waveId: this.waveSelectedID});
  }

  choosePage(page: Page): void {
    this.pageSelectedID = page.customID;
    this.PageTitles = page.title;
    if (this.itemType === "tile" || this.itemType === "bandeau") {
      this.step = 1;
      this.tileowave = true;
      this.pageService.getPage(page.customID as string).subscribe((fullpage: FullPage) => {
        this.waves = fullpage.waves?.filter(w => !w.deleted);
      });
    } else {
      this.stepName[2] = "page";
      this.step = 2;
      this.confirmText = `Exporter la vague dans la page '${this.PageTitles}' ?`;
    }
  }

  chooseWave(wave: Wave): void {
    this.waveSelectedID = wave.customID;
    this.stepName[2] = "vague";
    this.step = 2;
    this.WaveTitles = wave.title;
    if (this.itemType === "tile") {
      this.confirmText = `Exporter la tuile dans la vague '${wave.title}' de la page '${this.PageTitles}' ?`;
    } else {
      this.confirmText = `Exporter le bandeau dans la vague '${wave.title}' de la page '${this.PageTitles}' ?`;
    }
  }

  setStep(step: number): void {
    switch(step) {
      case -1:
        this.step = this.itemType === "tile" ? 1 : 0;
        break;
      case 0:
        this.step = 0;
        break;
      default:
        this.step = step;
        break;
    }
  }
}
