import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiNotification } from '@models/api-notification';
import { Status } from '@enums/status';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  notification = new Subject<ApiNotification>();
  failed(message: string, initialMessage?: string): void {
    const notification = new ApiNotification();
    notification.status = Status.Failed;
    notification.message = message;
    if (initialMessage) {
      notification.initialMessage = initialMessage;
    }
    this.notification.next(notification);
  }
  success(message: string, initialMessage?: string): void {
    const notification = new ApiNotification();
    notification.status = Status.Success;
    notification.message = message;
    if (initialMessage) {
      notification.initialMessage = initialMessage;
    }
    this.notification.next(notification);
  }
  constructor() { }
}
