<div class="icons">
  <div title="Liste utilisateur" (click)="listUserClick()" class="user">
    <i class="fa fa-list"></i>
  </div>
  <div title="KPI" (click)="kpiClick()" class="kpi">
    <i class="far fa-chart-bar"></i>
  </div>
  <div *ngIf="isAdmin || isModerator" title="Feedback" (click)="feedbackClick()" class="feedback">
    <i class="fas fa-comment"></i>
  </div>
  <div title="Déconnexion" (click)="userClick()" class="user">
    <i class="fas fa-user"></i>
    <span>{{fullName}}</span>
  </div>
</div>
