<div class="container">
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="form-row">
    <div class="form-group row">
      <label for="firstName">Nom de l'entreprise*</label>
      <input type="text" formControlName="companyName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.companyName.errors }" />
    </div>
    <div *ngIf="submitted && f.companyName.errors" class="invalid-feedback">
      <div *ngIf="f.companyName.errors.required">Nom de l'entreprise requis</div>
    </div>
    <div class="form-group row">
      <label for="firstName">Prénom*</label>
      <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
    </div>
    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
      <div *ngIf="f.firstName.errors.required">Prénom requis</div>
    </div>
    <div class="form-group row">
      <label for="lastName">Nom*</label>
      <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
    </div>
    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
      <div *ngIf="f.lastName.errors.required">Nom requis</div>
    </div>
  </div>
  <div class="form-row">
    <div class="form-group row">
      <label for="email">Email*</label>
      <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
    </div>
    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
      <div *ngIf="f.email.errors.required">Email requis</div>
    </div>
    <div class="form-group row">
      <label for="password">Mot de passe*</label>
      <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
    </div>
    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
      <div *ngIf="f.password.errors.required">Mot de passe requis</div>
      <div *ngIf="f.password.errors.minlength">
        <p>Le mot de passe doit contenir au moins :</p>
        <tr>8 caractères</tr>
        <tr>un nombre</tr>
        <tr>une majuscule</tr>
        <tr>un caractère spécial</tr>
      </div>
    </div>
    <div class="form-group row">
      <label for="phoneNumber">Téléphone*</label>
      <input type="text" formControlName="phoneNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }" />
    </div>
    <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
      <div *ngIf="f.phoneNumber.errors.required">Téléphone requis</div>
    </div>
    <div class="form-group row">
      <label for="comment">Commentaire</label>
      <input type="text" formControlName="comment" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.comment.errors }" />
      </div>
    <div *ngIf="submitted && f.comment.errors" class="invalid-feedback">
    </div>
  </div>
  <div class="row right">
    <button *ngIf="!isAdd" class="btn btn-primary">
      S'inscrire
    </button>
    <button *ngIf="isAdd" class="btn btn-primary">
      Ajouter le compte
    </button>
  </div>
  <a *ngIf="!isAdd" routerLink="/login" class="btn btn-link">Se connecter</a>
</form>
</div>
