import { AuthService } from '@services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { FooterBackground, FooterService } from '@services/footer.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router,private footerService:FooterService) {

  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const logged = await this.authService.isLoggedIn();
    if (!logged) {
      this.footerService.SetFooterMode(FooterBackground.RedConnexion)
      return this.router.parseUrl('/login');
    }
    this.footerService.SetFooterMode(FooterBackground.BlueConnected)
    return logged;
  }

}
