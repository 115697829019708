<div class="actions">
  <button (click)="add()">Ajouter</button>
</div>
<div class="editor">
  <div class="size-list">
    <ul>
      <li (click)="edit(size)" *ngFor="let size of sizes;let i = index" [attr.data-index]="i"><span>{{size.title}}</span>
        <div>
          <i (click)="delete(size);" class="fa fa-trash" aria-hidden="true" style="float: left;margin-right: -15px;left: -16px;position: relative;"></i>
          <app-chip (activeChange)="updateSizeActivity($event, size)" [active]="size.isActive!"
                    [text]="size.isActive ? 'On' : 'Off'"></app-chip>
        </div>
      </li>
    </ul>
  </div>
  <div *ngIf="currentSize" class="form">
    <div class="group">
    <div class="row">
      <label>Titre</label>
      <input type="text" class="overedit" (blur)="update()" [(ngModel)]="currentSize.title" /><i class="fa fa-pen"></i>
    </div>
    <div class="row">
      <label>Largeur</label>
      <input type="number" class="overedit" (keydown)="preventAlpha($event)" (blur)="update()" [(ngModel)]="currentSize.width" /><i class="fa fa-pen"></i>
    </div>
    <div class="row">
      <label>Hauteur</label>
      <input type="number" class="overedit" (keydown)="preventAlpha($event)" (blur)="update()" [(ngModel)]="currentSize.height" /><i class="fa fa-pen"></i>
    </div>
  </div>
  <button *ngIf="currentSize && currentSize.customID === undefined" (click)="addSize()" [attr.disabled]="isNotFilled()">Valider</button>
  </div>

</div>
