import { NotifyService } from './../../../services/notify.service';
import { PublicApiService } from './../../../services/public-api.service';
import { ContextMenuComponent } from './../../../components/context-menu/context-menu.component';
import { IMenuItem } from './../../../interfaces/components/IMenuItem';
import { BackendResponse } from './../../../models/response';
import { ActivatedRoute, Router } from '@angular/router';
import { PagesService } from '@services/pages.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FullPage } from '@models/full-page';
import { Page } from '@models/page';
import {HttpConstants} from "../../../constants/http.constants";
import {UserService} from "@services/user.service";

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  public pages: Page[] = [];
  newPageTitle?: string;
  currentPageName?: string;
  togglePopup = false;
  toggleRenamePopup = false;
  togglePermPopup = false;
  currentPage?: Page;
  disabledPage = true;
  @ViewChild(ContextMenuComponent) contextMenu!: ContextMenuComponent;
  menuItems: IMenuItem[];
  dev = true;

  constructor(private pageService: PagesService,
              private route: ActivatedRoute,
              private router: Router,
              private publicApiService: PublicApiService,
              private notify: NotifyService,
              private userService: UserService) {

    this.pageService.getPages().subscribe((pages: Page[]) => {
      // this.pages = pages.filter(p => !p.deleted && p.isActive === true);
      this.pages = pages.filter(p => !p.deleted);
    });

    var contrib = localStorage.getItem(HttpConstants.Contributor)
    this.menuItems = [

      {
        label: 'Voir la page',
        name: 'see',
        icon: 'link',

        callback: (page: Page) => {
          window.open(`https://${this.dev ? 'dev-' : ''}frontoffice.mediashow.fr/?page=${page.title}`, '_blank');
        }
      } as IMenuItem,
      {
        label: 'Permissions',
        name: 'perms',
        icon: 'list',
        callback: (page: Page) => {
          if (contrib && page.tiersID){
            if (page.tiersID.includes(contrib) || this.checkPerm(page.customID,'update',this.userService.user.customID)) {
              this.router.navigateByUrl('/perm/' + page.customID + "/" + "page");
            }

          }
          if (this.userService.user.profilType == "Admin"){
            this.router.navigateByUrl('/perm/' + page.customID + "/" + "page");
          }

        },

      } as IMenuItem,
      {
        label: 'Renommer',
        name: 'rename',
        icon: 'pen',
        callback: (page: Page) => {
          this.currentPage = page;
          this.toggleRenamePopup = true;
        }
      } as IMenuItem,
      {
        label: 'Dupliquer', name: 'duplicate', callback: (page: Page) => {
          if (page.customID) {
            this.pageService.duplicatePage(page.customID).subscribe((response: BackendResponse) => {
              this.pageService.getPage(response.message || '').subscribe((p: Page) => {
                this.pages.push(p);
                this.publicApiService.refreshPublicApi();
                this.notify.success(`Page dupliquée : ${p.title}`);
              })
            },
            error => {
              this.notify.failed('Erreur de duplication de la page');
            })
          }
        }, icon: 'copy'
      } as IMenuItem,
      {
        label: 'Supprimer', name: 'delete',
        callback: (p: Page) => {
          if (contrib && p.tiersID) {
            if (p.tiersID.includes(contrib) || this.userService.user.profilType == "Admin") {
              this.pageService.deletePage(p.customID!).subscribe(() => {
                  this.pageService.getPages().subscribe((pages: Page[]) => {
                    this.pages = pages.filter(p => !p.deleted);
                    this.publicApiService.refreshPublicApi();
                    this.notify.success(`Page ${p.title} supprimée`);
                  });
                },
                error => {
                  this.notify.failed('Erreur de suppression de la page');
                });
            }
          }
        }


        , icon: 'trash'
      } as IMenuItem];

  }

  closePopup() {
    this.togglePopup = false;
    this.newPageTitle = undefined;
  }

  addPageDialog(): void {
    this.togglePopup = true;
  }

  pageTitleValid(pageTitle?: string): boolean {
    return (pageTitle !== undefined && pageTitle.length > 3);
  }

  editPage(page: FullPage): void {
    this.router.navigate([`pages/${page.customID}/edit`]);
  }

  renamePage() {
    this.currentPage!.title = this.currentPageName;
    this.pageService.updatePage(this.currentPage!).subscribe(() => {
      this.publicApiService.refreshPublicApi();
      this.toggleRenamePopup = false;
      this.currentPage = undefined;
      this.notify.success(`Page renomée`);
    },
    error => {
      this.notify.failed('Erreur de mise à jour de la page');
    });
  }

  addTiers(){

    this.pageService.updatePage(this.currentPage!).subscribe(() => {
        this.publicApiService.refreshPublicApi();
        this.toggleRenamePopup = false;
        this.currentPage = undefined;
        this.notify.success(`Page renomée`);
      },
      error => {
        this.notify.failed('Erreur de mise à jour de la page');
      });
  }
  addPage(): void {
    if (this.newPageTitle && this.newPageTitle.length > 0) {
      const page = new Page();
      page.title = this.newPageTitle;
      page.isActive = false;
      this.pageService.addPage(page).subscribe((response: BackendResponse) => {
        this.pageService.getPage(response.message || '').subscribe((page: Page) => {
          this.publicApiService.refreshPublicApi();
          this.notify.success(`Page ${page.title} ajoutée`);
          if (!page.deleted) {
            this.pages.push(page);
          }
        });
      },
      error => {
        this.notify.failed('Erreur de la création de la page');
      });
    }
  }
  checkPerm(idElem:string | undefined, perm:string, tiersId : string):boolean {
    var isPerm = false
    if (tiersId === this.userService.user.customID){
      isPerm = true;
      return isPerm
    }
    if (idElem){
      isPerm = this.userService.checkPermission(idElem,perm)
    }
    return isPerm

  }
  changePageStatus(page: Page) {
    page.isActive = !page.isActive;
    this.pageService.changeActive(page).subscribe(() => {
      this.publicApiService.refreshPublicApi();
    });
  }

  showContextMenu(mouseEvent: MouseEvent, page: Page): void {
      this.contextMenu.X = mouseEvent.clientX;
      this.contextMenu.Y = mouseEvent.clientY;
      this.contextMenu.item = page;
      this.contextMenu.toggle = true;
  }

  ngOnInit(): void {
  }

}
