import {Perm} from "@models/perm";

export class User {
  email!: string;
  lastName!: string;
  firstName!: string;
  password!: string;
  customID!: string;
  role: any;
  particulier!: boolean
  profilType!: string
  phoneNumber!: string
  companyName!: string
  comment!: string
  permissions!: Perm[]

}
