
<div class="header">
  <div>
    <div class="burgerbutton"><i class="fas fa-bars"></i></div>
  </div>
  <div>
    <div class="logo"></div>
  </div>
  <div class="button_header">
    <button class="header_connect">Connexion</button>
    <button routerLink="/register" class="header_register">S'inscrire</button>
  </div>


</div>
<app-notifier></app-notifier>

<!-- <img class="leftwave" src="../../../../assets/image/LeftWave_Red.svg">
<img class="rightwave" src="../../../../assets/image/RightWave_Red.svg">
<img class="bird" src="../../../../assets/image/Bird_Red.svg"> -->

<div class="layout">
  <div class="container">
      <div class="Connixon_Text">
        {{error}}
      </div>
  </div>
</div>


