<div class="title">
  <p>Les pages</p>
</div>
<div class="layout">

  <div (click)="addPageDialog()" class="addItem">
    <!-- <i class="fas fa-plus-circle"></i> -->

    <div class="textFlex">
      <svg style="position: relative;right: -40%;margin-bottom: 5px;background-color: #28C0D7; border-radius:50%;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.875 12.5H17.125" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.5 6.875V18.125" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

      <div>
        Ajouter une nouvelle Page
      </div>
    </div>

  </div>

  <div (click)="editPage(page)" *ngFor="let page of pages" class="page">
    <div class="tools">
      <app-chip (activeChange)="changePageStatus(page)" [active]="page.isActive" [text]="page.isActive ? 'Activée' : 'Désactivée'"></app-chip>
      <!-- Svg code for the 3points -->
      <svg (mouseover)="showContextMenu($event, page)" width="4" height="22" viewBox="0 0 5 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="2.5" cy="20.5" r="2.5" fill="#6E6D7A"/>
        <circle cx="2.5" cy="11.5" r="2.5" fill="#6E6D7A"/>
        <circle cx="2.5" cy="2.5" r="2.5" fill="#6E6D7A"/>
      </svg>  
    </div>
    <div class="content">
      <label style="margin-top: 40px;line-height: 20px;">{{page.title}}</label>
    </div>
  </div>

</div>
<!-- <img class="leftwave" src="../../../../assets/image/LeftWave_Blue.svg">
<img class="bird" src="../../../../assets/image/Blue_Bird.svg">
<img class="rightwave" src="../../../../assets/image/RightWave_Blue.svg"> -->

<app-popup [(toggled)]="toggleRenamePopup" (onValidate)="renamePage()" [disabled]="!pageTitleValid(currentPageName)"
  [title]="'Renomer la page'">
  <label>Nom de la page</label>
  <input type="text" [(ngModel)]="currentPageName">
</app-popup>
<app-popup [(toggled)]="togglePermPopup" (onValidate)="renamePage()"
           [title]="'Donner les permissions à un email'">
  <label>Email</label>
  <input type="email">
</app-popup>
<app-popup [(toggled)]="togglePopup" (onValidate)="addPage()" [disabled]="!pageTitleValid(newPageTitle)"
  [title]="'Ajout d\'une nouvelle page'">
  <label>Nom de la page</label>
  <input type="text" [(ngModel)]="newPageTitle">
</app-popup>
<app-context-menu  [menuItems]="menuItems"></app-context-menu>
