<div #ToolManager style="display: block;" class='resizable'>
    <div class='resizers'>
      <div class="contentMain">
        <div class="content" [innerHTML]="textcontent | sanitizeHtml">
          <!-- {{textcontent}} -->
        </div>
      </div>
      <!-- <div class='resizer top-left'></div>
      <div class='resizer top-right'></div>
      <div class='resizer bottom-left'></div> -->
      <div class='resizer bottom-right'></div>
    </div>
</div>