export class Perm{


  customIDElem?:string;
   permissions: string;

  constructor(customIDElem: string, permissions: string) {
    this.customIDElem = customIDElem;
    this.permissions = permissions;
  }

}
