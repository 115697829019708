import { Injectable } from '@angular/core';
import { BackendResponse } from '@models/response';
import { Wave } from '@models/wave';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WavesService extends ApiService {
  getWave(waveId: string) {
    return this.get<Wave>(`waves/${waveId}`).pipe(
      map((response: any) => {
        return response['wave'];
      })
    );
  }
  addWave(pageId: string, wave: Wave): Observable<BackendResponse> {
    return this.post<BackendResponse>(`pages/${pageId}/waves`, wave);
  }

  updateWave(pageId: string, wave: Wave): Observable<BackendResponse> {
    // return this.post<BackendResponse>(`pages/${pageId}/waves/${wave.customID}`, wave);
    return this.post<BackendResponse>(`d/pages/${pageId}/waves/${wave.customID}`, wave);
  }

  duplicateWave(pageId?: string, waveId?: string): Observable<BackendResponse> {
    return this.put<BackendResponse>(`pages/${pageId}/waves/${waveId}`, null);
  }

  changeActive(pageId: string, wave: Wave): Observable<BackendResponse> {
    return this.post<BackendResponse>(`pages/${pageId}/waves/${wave.customID}/${wave.isActive ? 'enable' : 'disable'}`, {});
  }

  changePrimary(pageId: string, wave: Wave): Observable<BackendResponse> {
    return this.post<BackendResponse>(`pages/${pageId}/waves/${wave.customID}/${wave.isPrimary ? 'enable' : 'disable'}/primary`, {});
  }

  changeRandomized(pageId: string, wave: Wave): Observable<BackendResponse> {
    return this.post<BackendResponse>(`pages/${pageId}/waves/${wave.customID}/${wave.isRandomized ? 'enable' : 'disable'}/randomized`, {});
  }

  deleteWave(pageId: string, wave: Wave): Observable<BackendResponse> {
    return this.delete<BackendResponse>(`pages/${pageId}/waves/${wave.customID}`);
  }
}
