import { AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, Query, QueryList, Renderer2, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Tile } from '@models/tile';
import { TilesService } from '@services/tiles.service';
import { BoxComponent } from '../box/box.component';

@Component({
  selector: 'app-overlay-factory',
  templateUrl: './overlay-factory.component.html',
  styleUrls: ['./overlay-factory.component.scss']
})
export class OverlayFactoryComponent implements AfterViewInit{

  constructor(private el:ElementRef,
    private renderer: Renderer2,
    private componentFactoryResolver:ComponentFactoryResolver,
    private tilesService: TilesService)
  {
    //(window as any)['test'] = this;
  }

  ngAfterViewInit(): void {
    if (!this.previewTile.SurCoucheInfo)
      this.previewTile.SurCoucheInfo = [];
    for(let i=0 ; i<this.previewTile.SurCoucheInfo.length ; i++){
      const dynamicComponentFactory = this.componentFactoryResolver.resolveComponentFactory(BoxComponent);
      this.componentRef.push(this.previewContainer.createComponent(dynamicComponentFactory));
      this.componentRef[this.componentRef.length - 1].instance.SetTextContent = this.previewTile.SurCoucheInfo[i].textcontent;
      this.componentRef[this.componentRef.length - 1].instance.SetStyleTransform = this.previewTile.SurCoucheInfo[i].style;
      this.componentRef[this.componentRef.length - 1].instance.SetSizeBox = this.previewTile.SurCoucheInfo[i].sizebox
    }
  }

  @Input("previewTile")previewTile!:Tile;
  @ViewChild("preview",{ read: ViewContainerRef }) previewContainer!:ViewContainerRef;
  @Output() SendOutput = new EventEmitter<Array<box>>()

  BoxList:box[] = []
  htmlContent:string = ""
  componentRef:Array<ComponentRef<BoxComponent>>=[]
  SelectedIndexBox: number = -1;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };


  @HostListener("click",["$event"])
  OnClick(event:PointerEvent){
    let tab = event.composedPath()
    let stop = false
    for(let i=0 ; i<tab.length ; i++){
      for(let k=0 ; k<this.componentRef.length ; k++){
        if (this.componentRef[k].instance.el.nativeElement == tab[i]){
          this.SelectedIndexBox = k
          stop = true
          console.log("ID Box Selected: " + this.SelectedIndexBox)
          this.htmlContent = this.componentRef[this.SelectedIndexBox].instance.GetTextContent == undefined ? "":this.componentRef[this.SelectedIndexBox].instance.GetTextContent;
          break;
        }
      }
      if (stop){
        break;
      }
    }
  }

  CreateNewBanniere(){
    const dynamicComponentFactory = this.componentFactoryResolver.resolveComponentFactory(BoxComponent);
    this.componentRef.push(this.previewContainer.createComponent(dynamicComponentFactory));
    this.SelectedIndexBox = this.componentRef.length - 1;
    this.componentRef[this.SelectedIndexBox].instance.SetStyleTransform = "translate(0px,0px)";
  }

  changeText(){
    if (this.SelectedIndexBox != -1){
      console.log(this.el.nativeElement.getElementsByClassName("angular-editor-textarea")[0].innerHTML)
      console.log(this.ReSize(this.el.nativeElement.getElementsByClassName("angular-editor-textarea")[0].innerHTML))
      let temp = this.ReSize(this.el.nativeElement.getElementsByClassName("angular-editor-textarea")[0].innerHTML)
      this.componentRef[this.SelectedIndexBox].instance.SetTextContent = temp;
    }
  }

  changeView(){
    if (this.SelectedIndexBox != -1){
      this.componentRef[this.SelectedIndexBox].instance.SetTransform = !this.componentRef[this.SelectedIndexBox].instance.GetTransform;
    }
  }

  SaveAndQuit(){
    let tamp:box;
    var myarray = this.el.nativeElement.querySelector("app-tile-preview").querySelectorAll("app-box")
    for (let i=0 ; i<myarray.length ; i++){
      //console.log(myarray[i].querySelector("div.resizable").style)
      tamp =
        {
          style:myarray[i].style.transform,
          textcontent:myarray[i].querySelector('div.content').innerHTML,
          sizebox:myarray[i].querySelector("div.resizable").style.cssText
        }
      this.BoxList.push(tamp)
    }
    //console.log(this.BoxList)
    this.SendOutput.emit(this.BoxList)
  }

  Quit(){
    this.SendOutput.emit([])
  }

  private ReSize(html:any){
    var size = [...html.matchAll(/size=\"/g)].map(data => data.index+6)
    var tamp = html
    html = html.replaceAll(/size=\".\"/g,"style=\"font-size:@@;\"")
    var res = tamp;
    Array.from(html).map( (x,index,tab) => {
        if (tab[index]=="@" && tab[index+1]=="@"){
            tab.splice(index+1,1)
            console.log(Number(tamp[size[0]])*8.5+100)
            tab[index] = tamp[size[0]] + "rem;line-height:"+Number(Number(tamp[size[0]])*2+100)+"%;"
            res = tab.join('')
            size.shift()
        }
    })

    return res
  }

}

export interface box
{
  style:string,
  textcontent:string,
  sizebox:string,
}
