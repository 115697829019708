<button *ngIf="canAddUser()" routerLink="add" class="btn btn-sm btn-primary mr-1">Ajouter un utilisateur</button>

<select #roles class="form-control" (change)="roleChange(roles.value)">
  <option default value="all">Tous les utilisateurs</option>
  <option value="Admin">Admin</option>
  <option value="Moderateur">Moderateur</option>
  <option value="Contributor">Contributor</option>
  <option value="Advertiser">Advertiser</option>
  <option value="Visitor">Visitor</option>
</select>

<select #type class="form-control" (change)="isParticulierChange(type.value)">
  <option default value="all">Tous les profils</option>
  <option value="true">Particulier</option>
  <option value="false">Professionnel</option>
</select>

<button (click)="applyFilter()" class="btn btn-sm btn-primary mr-1">Filtrer</button>

<table mat-table class="mat-elevation-z8" matSort [dataSource]="dataSource">

  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Prénom </th>
    <td mat-cell *matCellDef="let user"> {{user.firstName}} </td>
  </ng-container>


  <ng-container matColumnDef="lastName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom </th>
    <td mat-cell *matCellDef="let user"> {{user.lastName}} </td>
  </ng-container>

<!--  <ng-container matColumnDef="particulier">-->
<!--    <th mat-header-cell *matHeaderCellDef mat-sort-header> Type de compte </th>-->
<!--    <td mat-cell *matCellDef="let user">-->
<!--      <span *ngIf="user.particulier">Particulier</span>-->
<!--      <span *ngIf="!user.particulier">Professionnel</span>-->
<!--    </td>-->
<!--  </ng-container>-->

  <ng-container matColumnDef="profil">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Profil </th>
    <td mat-cell *matCellDef="let user">
      {{user.profilType}}
    </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
    <td mat-cell *matCellDef="let user"> {{user.email}} </td>
  </ng-container>

  <ng-container matColumnDef="companyName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nom entreprise </th>
    <td mat-cell *matCellDef="let user"> {{user.companyName}} </td>
  </ng-container>


  <ng-container matColumnDef="phoneNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Téléphone </th>
    <td mat-cell *matCellDef="let user"> {{user.phoneNumber}} </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
    <td mat-cell *matCellDef="let user">
      <button routerLink="edit/{{user.customID}}" class="btn btn-sm btn-primary mr-1">Modifier</button>
      <button (click)="deleteUser(user.customID)" class="btn btn-sm btn-danger btn-delete-user">
        <span>Supprimer</span>
      </button>
      <button routerLink="/userPerms/{{user.customID}}">Gerer les permissions</button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


</table>
<mat-paginator #paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>



