<div class="title">
  <p>ACTIVITES ET KPI</p>
</div>
<div [routerLink]="'/kpi'" class="array">
  <h1 style="font-size: 26px;">{{title}}</h1>
  <!-- <div class="step" style="transform-style: preserve-3d;" *ngIf="this.step == 1">
    <div class="card">
      <table mat-table [dataSource]="displayedDatas" class="mat-elevation-z8">
         Note that these columns can be defined in any order.
                                            The actual rendered columns are set as a property on the row definition"


        <div class="Front">
          <ng-container matColumnDef="Data">
            <th mat-header-cell *matHeaderCellDef> Landing page URL</th>
            <td mat-cell *matCellDef="let element"> {{element.Data}} </td>
          </ng-container>

          <ng-container matColumnDef="perHours">
            <th mat-header-cell *matHeaderCellDef> visites totales </th>
            <td mat-cell *matCellDef="let element"> {{element.perHours}} </td>
          </ng-container>
          <ng-container matColumnDef="perDays">
            <th mat-header-cell *matHeaderCellDef> visite AB+ </th>
            <td mat-cell *matCellDef="let element"> {{element.perDays}} </td>
          </ng-container>

          <ng-container matColumnDef="perMonths">
            <th mat-header-cell *matHeaderCellDef> Désactivation de l’AB+ </th>
            <td mat-cell *matCellDef="let element"> {{element.perMonths}} </td>
          </ng-container>

          <ng-container matColumnDef="Data1">
            <th mat-header-cell *matHeaderCellDef> Ok c'est fait </th>
            <td mat-cell *matCellDef="let element"> {{element.Data1}} </td>
          </ng-container>

          <ng-container matColumnDef="Data2">
            <th mat-header-cell *matHeaderCellDef> (videoheader) Comment faire ? </th>
            <td mat-cell *matCellDef="let element"> {{element.Data2}} </td>
          </ng-container>

          <ng-container matColumnDef="Datas">
            <th mat-header-cell *matHeaderCellDef> visite -10s </th>
            <td mat-cell *matCellDef="let element"> {{element.Datas}} </td>
          </ng-container>

          <ng-container (click)="expandTab();" matColumnDef="Expand">
            <th mat-header-cell (click)="expandTab();resetbool();" class="expand" *matHeaderCellDef> </th>
            <td mat-cell (click)="expandTab();resetbool();" class="expand" *matCellDef="let element"> <i
                style="color: lightgrey;margin-right: 5px; " class="fas fa-arrows-alt-h fa-3x"></i> </td>
          </ng-container>
        </div>
        <div class="Back">
          <ng-container matColumnDef="Data3">
            <th mat-header-cell class="hide" [hidden]="this.booltab" *matHeaderCellDef> visites utiles </th>
            <td mat-cell class="hide" [hidden]="this.booltab" *matCellDef="let element;"> {{element.Data3}} </td>
          </ng-container>

          <ng-container class="hide" matColumnDef="Data4">
            <th mat-header-cell class="hide" *matHeaderCellDef> Visites sans actions </th>
            <td mat-cell class="hide" *matCellDef="let element"> {{element.Data4}} </td>
          </ng-container>

          <ng-container class="hide" matColumnDef="Data5">
            <th mat-header-cell class="hide" *matHeaderCellDef> Ok c'est fait sans déblocage du AB+ </th>
            <td mat-cell class="hide" *matCellDef="let element"> {{element.Data5}} </td>
          </ng-container>

          <ng-container class="hide" matColumnDef="Data6">
            <th mat-header-cell class="hide" *matHeaderCellDef> tuto (Ok c'est fait) </th>
            <td mat-cell class="hide" *matCellDef="let element"> {{element.Data6}} </td>
          </ng-container>

          <ng-container class="hide" matColumnDef="Data7">
            <th mat-header-cell class="hide" *matHeaderCellDef> Fin actuelle du tableau primaire et secondaire </th>
            <td mat-cell class="hide" *matCellDef="let element"> {{element.Data7}} </td>
          </ng-container>

          <ng-container class="hide" matColumnDef="Data8">
            <th mat-header-cell class="hide" *matHeaderCellDef> Fin actuelle du tableau primaire et secondaire </th>
            <td mat-cell class="hide" *matCellDef="let element"> {{element.Data8}} </td>
          </ng-container>

          <ng-container class="hide" matColumnDef="Data9">
            <th mat-header-cell class="hide" *matHeaderCellDef> Fin actuelle du tableau primaire et secondaire </th>
            <td mat-cell class="hide" *matCellDef="let element"> {{element.Data9}} </td>
          </ng-container>

          <ng-container class="hide" matColumnDef="Data10">
            <th mat-header-cell class="hide" *matHeaderCellDef> Fin actuelle du tableau primaire et secondaire </th>
            <td mat-cell class="hide" *matCellDef="let element"> {{element.Data10}} </td>
          </ng-container>
        </div>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsArray"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsArray;"></tr>
      </table>
    </div>
  </div> -->
  <!-- <div class="step" *ngIf="this.step == 2">
    <div class="card">
      <table mat-table [dataSource]="displayedDatas" class="mat-elevation-z8">
        <ng-container matColumnDef="Data">
          <th mat-header-cell *matHeaderCellDef> Data captée</th>
          <td mat-cell *matCellDef="let element"> {{element.Data}} </td>
        </ng-container>
        <ng-container matColumnDef="perHours">
          <th mat-header-cell *matHeaderCellDef> par heures </th>
          <td mat-cell *matCellDef="let element"> {{element.perHours}} </td>
        </ng-container>
        <ng-container matColumnDef="perDays">
          <th mat-header-cell *matHeaderCellDef> par jours </th>
          <td mat-cell *matCellDef="let element"> {{element.perDays}} </td>
        </ng-container>

        <ng-container matColumnDef="perMonths">
          <th mat-header-cell *matHeaderCellDef> par mois </th>
          <td mat-cell *matCellDef="let element"> {{element.perMonths}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsArray"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsArray;"></tr>
      </table>
    </div>
  </div> -->

  <div class="step" *ngIf="this.step == 0">
    <div>
      <mat-form-field  class="filter-container" appearance="fill">
        <mat-label>Pour cette landing page</mat-label>
        <mat-select [(value)]="selected" (selectionChange)="onChange($event.value)">
          <mat-option *ngFor="let Landingpage of Displaylandingpagearray" selected="0" [value]="Landingpage.page">
            {{Landingpage.page}}
          </mat-option>

        </mat-select>
      </mat-form-field>
      <mat-form-field class="filter-container" appearance="fill">
        <mat-select [(value)]="selectedPeriod" (selectionChange)="changePeriod($event.value)">
          <mat-option *ngFor="let period of periods" selected=3 [value]="period.value">
            {{period.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <p style="color: white;font-family: sans-serif;font-size: 20px;margin-top: 3%;">Par widget/URL</p>



    <div class="kpiwidgettab">
      <div class="chart-container p-2">
        <canvas #chart id="chart">{{ chart }}</canvas>
      </div>
      <!-- <div class="card">
                <table mat-table [dataSource]="displayedDatas" class="mat-elevation-z8">

                    <ng-container matColumnDef="originwidget">
                        <th mat-header-cell *matHeaderCellDef>Domaine du widget</th>
                        <td mat-cell  *matCellDef="let element"> {{element.originwidget}} </td>
                    </ng-container>


                    <ng-container matColumnDef="nbrLanding">
                        <th mat-header-cell *matHeaderCellDef> Ouverture du widget </th>
                        <td mat-cell style="border-right: 1px solid currentColor;" *matCellDef="let element"> {{element.nbrLanding}} </td>


                    </ng-container>

                    <!-- per days Column
                    <ng-container matColumnDef="nbr10s">
                        <th mat-header-cell *matHeaderCellDef> Leaving -10s </th>
                        <td mat-cell *matCellDef="let element"> {{element.nbr10s}} </td>
                    </ng-container>

                    <!-- per months Column
                    <ng-container matColumnDef="nbrOk">
                        <th mat-header-cell *matHeaderCellDef> OK button</th>
                        <td mat-cell *matCellDef="let element"> {{element.nbrOk}} </td>
                    </ng-container>

                    <ng-container matColumnDef="nbrLM">
                        <th mat-header-cell *matHeaderCellDef> Learn More button</th>
                        <td mat-cell *matCellDef="let element"> {{element.nbrLM}} </td>
                    </ng-container>

                    <ng-container matColumnDef="nbrCross">
                        <th mat-header-cell *matHeaderCellDef> Close X button</th>
                        <td mat-cell *matCellDef="let element"> {{element.nbrCross}} </td>
                    </ng-container>

                    <ng-container matColumnDef="TwoutAction">
                        <th mat-header-cell *matHeaderCellDef>Temps Avant Action</th>
                        <td mat-cell *matCellDef="let element"> {{element.TwoutAction}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsArray"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsArray;"></tr>
            </table>
           </div> -->
    </div>

  </div>
</div>
<div class="buttons">
  <button (click)="previousArray()" mat-raised-button color="primary">précédent</button>
  <button (click)="nextArray()" mat-raised-button color="primary">Suivant</button>
</div>
