import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {

  constructor(private _sanitizer:DomSanitizer) {
  }

  transform(v:string):SafeHtml {
    let tamp = this._sanitizer.bypassSecurityTrustHtml(v);
    if ((tamp as any).changingThisBreaksApplicationSecurity){
      return tamp
    }
    else{
      return ""
    }
  }
}