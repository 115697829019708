<div class="layout">
  <div class="container">
  <div class="content">
    <input type="checkbox" id="switch" (change)="particulier = !particulier;">
    <div class="app">
      <div class="content">
        <label for="switch">
          <div class="toggle"></div>
          <div class="names">
            <p class="pro">Particulier</p>
            <p class="particulier">Professionnel</p>
          </div>
        </label>
      </div>
    </div>
  </div>
  </div>
  <app-notifier></app-notifier>
  <div class="container" *ngIf="particulier">
    <div class="container">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row">
        <label for="email">Email*</label>
        <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
      </div>
      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
        <div *ngIf="f.email.errors.required">Email is required</div>
      </div>
      <alert></alert>
      <div class="row">
        <label for="password">Mot de passe*</label>
        <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }"/>
      </div>
      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
        <div *ngIf="f.password.errors.required">Mot de passe requis</div>
        <div *ngIf="f.password.errors">
          <p>Le mot de passe doit contenir au moins :</p>
          <tr>8 caractères</tr>
          <tr>un nombre</tr>
          <tr>une majuscule</tr>
          <tr>un caractère spécial</tr>
        </div>
      </div>
      <div class="row">
        <label for="phoneNumber">Téléphone</label>
        <input type="text" formControlName="phoneNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }" />
      </div>
      <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
      </div>
      <div class="row right">
        <button *ngIf="!isAdd" class="btn btn-primary">
      S'inscrire
        </button>
        <button *ngIf="isAdd" class="btn btn-primary">
          Ajouter le compte
        </button>
      </div>
      <label>Vous avez déjà un compte? <a routerLink="/login"  id="register">  Se connecter</a></label>
    </form>
  </div>
  </div>
  <div class="container" *ngIf="!particulier">
    <app-registerpro></app-registerpro>
  </div>
</div>
