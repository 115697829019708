<ng-container *ngIf="toggled">
  <div class="dialog">
    <div class="header">
      <div class="title">{{title}}</div>
      <div (click)="cancel()" class="Xclose" *ngIf="closable">
        <i class="fa fa-times"></i>
      </div>
    </div>
    <div class="content">
      <ng-content></ng-content>
    </div>
    <app-yesno [disabled]="disabled" (onCancel)="cancel()" (onValidate)="valid()"></app-yesno>
  </div>
  <div *ngIf="modal" class="overlay"></div>
</ng-container>
