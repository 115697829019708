export class Mime {
  public static Image = ['image/aces',
    'image/avci',
    'image/avcs',
    'image/bmp',
    'image/cgm',
    'image/dicom-rle',
    'image/emf',
    'image/example',
    'image/fits',
    'image/g3fax',
    'image/heic',
    'image/heic-sequence',
    'image/heif',
    'image/heif-sequence',
    'image/hej2k',
    'image/hsj2',
    'image/jls',
    'image/jp2',
    'image/jpg',
    'image/jpeg',
    'image/jph',
    'image/jphc',
    'image/jpm',
    'image/jpx',
    'image/jxr',
    'image/jxrA',
    'image/jxrS',
    'image/jxs',
    'image/jxsc',
    'image/jxsi',
    'image/jxss',
    'image/ktx',
    'image/ktx2',
    'image/naplps',
    'image/png',
    'image/prs.btif',
    'image/prs.pti',
    'image/pwg-raster',
    'image/svg+xml',
    'image/t38',
    'image/tiff',
    'image/tiff-fx',
    'image/vnd.adobe.photoshop',
    'image/vnd.airzip.accelerator.azv',
    'image/vnd.cns.inf2',
    'image/vnd.dece.graphic',
    'image/vnd.djvu',
    'image/vnd.dwg',
    'image/vnd.dxf',
    'image/vnd.dvb.subtitle',
    'image/vnd.fastbidsheet',
    'image/vnd.fpx',
    'image/vnd.fst',
    'image/vnd.fujixerox.edmics-mmr',
    'image/vnd.fujixerox.edmics-rlc',
    'image/vnd.globalgraphics.pgb',
    'image/vnd.microsoft.icon',
    'image/vnd.mix',
    'image/vnd.ms-modi',
    'image/vnd.mozilla.apng',
    'image/vnd.net-fpx',
    'image/vnd.pco.b16',
    'image/vnd.radiance',
    'image/vnd.sealed.png',
    'image/vnd.sealedmedia.softseal.gif',
    'image/vnd.sealedmedia.softseal.jpg',
    'image/vnd.svf',
    'image/vnd.tencent.tap',
    'image/vnd.valve.source.texture',
    'image/vnd.wap.wbmp',
    'image/vnd.xiff',
    'image/vnd.zbrush.pcx',
    'image/wmf',
    'image/emf',
    'image/wmf'];
  public static Video = ['video/1d-interleaved-parityfec',
    'video/3gpp',
    'video/3gpp2',
    'video/3gpp-tt',
    'video/BMPEG',
    'video/BT656',
    'video/CelB',
    'video/DV',
    'video/encaprtp',
    'video/example',
    'video/flexfec',
    'video/H261',
    'video/H263',
    'video/H263-1998',
    'video/H263-2000',
    'video/H264',
    'video/H264-RCDO',
    'video/H264-SVC',
    'video/H265',
    'video/iso.segment',
    'video/JPEG',
    'video/jpeg2000',
    'video/mj2',
    'video/MP1S',
    'video/MP2P',
    'video/MP2T',
    'video/mp4',
    'video/MP4V-ES',
    'video/MPV',
    'video/mpeg4-generic',
    'video/nv',
    'video/ogg',
    'video/parityfec',
    'video/pointer',
    'video/quicktime',
    'video/raptorfec',
    'video/raw',
    'video/rtp-enc-aescm128',
    'video/rtploopback',
    'video/rtx',
    'video/smpte291',
    'video/SMPTE292M',
    'video/ulpfec',
    'video/vc1',
    'video/vc2',
    'video/vnd.CCTV',
    'video/vnd.dece.hd',
    'video/vnd.dece.mobile',
    'video/vnd.dece.mp4',
    'video/vnd.dece.pd',
    'video/vnd.dece.sd',
    'video/vnd.dece.video',
    'video/vnd.directv.mpeg',
    'video/vnd.directv.mpeg-tts',
    'video/vnd.dlna.mpeg-tts',
    'video/vnd.dvb.file',
    'video/vnd.fvt',
    'video/vnd.hns.video',
    'video/vnd.iptvforum.1dparityfec-1010',
    'video/vnd.iptvforum.1dparityfec-2005',
    'video/vnd.iptvforum.2dparityfec-1010',
    'video/vnd.iptvforum.2dparityfec-2005',
    'video/vnd.iptvforum.ttsavc',
    'video/vnd.iptvforum.ttsmpeg2',
    'video/vnd.motorola.video',
    'video/vnd.motorola.videop',
    'video/vnd.mpegurl',
    'video/vnd.ms-playready.media.pyv',
    'video/vnd.nokia.interleaved-multimedia',
    'video/vnd.nokia.mp4vr',
    'video/vnd.nokia.videovoip',
    'video/vnd.objectvideo',
    'video/vnd.radgamettools.bink',
    'video/vnd.radgamettools.smacker',
    'video/vnd.sealed.mpeg1',
    'video/vnd.sealed.mpeg4',
    'video/vnd.sealed.swf',
    'video/vnd.sealedmedia.softseal.mov',
    'video/vnd.uvvu.mp4',
    'video/vnd.youtube.yt',
    'video/vnd.vivo',
    'video/VP8'
  ];
}
