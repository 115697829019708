import { AuthService } from '@services/auth.service';
import { ConfigService } from './config.service';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { User } from '@models/user';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HttpConstants} from "../constants/http.constants";
import {map} from "rxjs/operators";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class UserService  {

  user!: User;
  constructor(protected http: HttpClient,
              protected config: ConfigService,
              protected router: Router) {

  }

  logout() {
    this.router.navigate(['/']);
  }

  delete(id: string) {
    return this.http.delete(`${this.config.endpoint}/users/${id}`)
  }

  checkPermission(id:string, perm: string):boolean{
    if (this.user.profilType != "Admin") {
      var aPerm = this.user.permissions.find(e => e.customIDElem === id)
      if (aPerm) {
        if (aPerm.permissions.includes(perm)) {
          return true
        }
      }
      return false
    } else{
      return true;
    }
  }


  register(user: User) {
    return this.http.post(`${this.config.endpoint}/register`, user);
  }



  getAll() {
    return this.http.get<User[]>(`${this.config.endpoint}/users`);
  }
  getByRole(role: string, type : string) {
    return this.http.get<User[]>(`${this.config.endpoint}/users/${role}/${type}`);
  }

  getById(id: string) {
    return this.http.get<User>(`${this.config.endpoint}/users/${id}`);
  }
  getByEmail(email: string) {
    return this.http.get<User>(`${this.config.endpoint}/users/${email}`);
  }
  update(id: string, params:User) {
    return this.http.post(`${this.config.endpoint}/users/${id}`, params)
  }
  add(user:User)
  {
    return this.http.post(`${this.config.endpoint}/users`, user);
  }
}
