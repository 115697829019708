import { PublicApiService } from './../../services/public-api.service';
import { NotifyService } from './../../services/notify.service';
import { Mime } from './../../constants/mime';
import { BackendResponse } from './../../models/response';
import { TileStyle } from '@models/tile-style';
import { TilesService } from '@services/tiles.service';
import { Component, HostBinding, Input, OnInit, Output, EventEmitter, ElementRef, AfterViewInit, ViewChild, ChangeDetectorRef, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { Tile } from '@models/tile';
import { Size } from '@models/size';

import { of } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { ConfTemplateComponent } from '@components/conf-template/conf-template.component';
import { box, OverlayFactoryComponent } from '@components/overlay-factory/overlay-factory.component';

@Component({
  selector: 'app-tile-editor',
  templateUrl: './tile-editor.component.html',
  styleUrls: ['./tile-editor.component.scss'],
})
export class TileEditorComponent implements OnInit {
  fileDragged = false;
  badformat = false;
  waiting = false;
  private _tile!: Tile;

  @ViewChild('format') formatRow!: ElementRef;
  @ViewChild('target') targetRow!: ElementRef;
  @ViewChild('url') urlRow!: ElementRef;
  @ViewChild('thumbnail') thumbnailRow!: ElementRef;
  @ViewChild('fileInput') fileInputRow!: ElementRef;
  @ViewChild('fileThInput') fileThInputRow!: ElementRef;
  @ViewChild('mediapath') mediapath!: ElementRef;
  @ViewChild('dropImageContent') dropImageContent!: ElementRef;
  @ViewChild('embedded') embeddedRow!: ElementRef;
  @ViewChild('urlth') thRow!: ElementRef;
  @ViewChild('jwplayer') jwplayer!: ElementRef;
  @ViewChild('iframeVideo') iframeVdeo!: ElementRef;
  @ViewChild('progressBar') progressBar!: ElementRef;
  @ViewChild('progressBarTh') progressBarTh!: ElementRef;
  @ViewChild('editTemplate') editTemplate!: ElementRef;
  @ViewChild('containerConfigurationEditTemplate', { read: ViewContainerRef })
  containerConfigurationEditTemplate!: ViewContainerRef;
  @HostBinding('class.toggled') get hasTile(): boolean {
    return this.tile !== undefined;
  }
  @HostBinding('class.waiting') get isWaiting(): boolean {
    return this.waiting;
  }
  @ViewChild('containerOverlayConfiguration', { read: ViewContainerRef })
  containerOverlayConfiguration!: ViewContainerRef;

  @Input()
  get tile(): Tile | undefined {
    return this._tile;
  }
  set tile(value: Tile | undefined) {
    if (value) {
      if (value.customID !== undefined) {
        this.tileService
          .getTile(this.page, this.wave, value?.customID!)
          .subscribe((tile: Tile) => {
            this._tile = tile;
            this._tile.size = this.sizes.find(
              (s) => s.customID === this._tile.sizeID
            );
            setTimeout(() => {
              this.toggleFormFields();
              if (
                (this.el.nativeElement.querySelector('.drop') &&
                  this._tile.thFileName) ||
                this._tile.type === 'image'
                  ? this._tile.fileName
                  : ''
              ) {
                this.el.nativeElement
                  .querySelector('.drop')
                  .style.setProperty(
                    '--background-target-url',
                    `url(${this._tile.thFileName || this._tile.fileName})`
                  );
              } else {
                this.el.nativeElement
                  .querySelector('.drop')
                  .style.setProperty('--background-target-url', `none`);
              }
            });
          });
      } else {
        this._tile = new Tile();
        setTimeout(() => {
          this.toggleFormFields();
          if (this.el.nativeElement.querySelector('.drop')) {
            this.el.nativeElement
              .querySelector('.drop')
              .style.setProperty('--background-target-url', `none`);
          }
        });
      }

      this.changeDetector.detectChanges();
    } else {
      this._tile = undefined!;
      this.toggleFormFields();
    }
  }
  @Input() page!: string;
  @Input() wave!: string;
  @Output() tileChange = new EventEmitter<Tile>();
  @Output() showPreview = new EventEmitter<Tile>();

  @Output() integrityChange = new EventEmitter<boolean>();

  TileStyle = TileStyle;
  sizes!: Size[];
  fileProgress = -1;
  fileProgressTh = -1;
  public file?: File;
  public filename = '';

  constructor(
    private tileService: TilesService,
    private notify: NotifyService,
    private el: ElementRef,
    private changeDetector: ChangeDetectorRef,
    private publicApi: PublicApiService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.tileService.getSize().then((sizes) => {
      this.sizes = sizes;
    });
  }

  SrcModif(text: any) {
    if (text.value.search(/https:\/\/www.instagram.com\/embed.js/g) == -1) {
      let out = text.value.replaceAll(
        /\/\/www.instagram.com\/embed.js/g,
        'https://www.instagram.com/embed.js'
      );
      this.el.nativeElement.querySelector('textarea').value = out;
      this.tile!.embedded = out;
    } else {
      this.tile!.embedded = text.value;
    }
  }

  updateTitle(content: string): void {
    this._tile.title = content;
    this.toggleFormFields();
  }

  hasTargetMedia(): boolean {
    return this.isMediaTile() && this.urlCheck();
  }

  resetColor(mouseEvent: MouseEvent): void {
    this.tile!.backgroundColor = 'transparent';
  }

  preview(): void {
    this.showPreview.emit(this.tile);
  }

  isMediaTile(): boolean {
    return this._tile.type === 'image' || this._tile.type === 'video';
  }

  updateTile(): void {
    if (this.page && this.wave) {
      if (this._tile && this._tile.customID) {
        this.tileService
          .updateTile(this.page, this.wave, this._tile!)
          .subscribe(
            (tile) => {
              this.notify.success(`"${this._tile.title}" a été mise à jour`);
              this.tileChange.emit(this._tile);
              this.tileService.tileUpdated.next(this._tile);
              this.publicApi.updateDone.next(false);
            },
            (error) => {
              this.notify.failed(`Erreur de mise à jour de la tuile`);
            }
          );
      } else {
        this.tileService.addTile(this.page, this.wave, this._tile).subscribe(
          (response) => {
            this.tileChange.emit(this._tile);
            console.log(response);
            this.notify.success(`Nouvelle tuile ajoutée`);
            this.tileService
              .getTile(this.page, this.wave, response.message!)
              .subscribe((newTile: Tile) => {
                this.notify.success(`"${newTile.title}" a été ajoutée`);
                this.tileService.tileUpdated.next(newTile);
                this.tile = newTile;
                this.publicApi.updateDone.next(false);
                if (this.isMediaTile()) {
                  this.waitingFileName(false);
                }
                this.showPreview.emit(undefined);
              });
          },
          (error) => {
            this.notify.failed(`Erreur d'ajout de la tuile`);
          }
        );
      }
    }
  }

  private toggleElements(element: ElementRef[], value: boolean): void {
    element.forEach((e) => {
      if (e) {
        e.nativeElement.style.display = `${value ? 'flex' : 'none'}`;
      }
    });
  }

  updateForm(type: string): void {
    this._tile.type = type;
    this.toggleFormFields();
  }

  private toggleFormFields(): void {
    setTimeout(() => {
      if (this._tile && this._tile.title) {
        switch (this._tile.type) {
          case 'image':
            this.toggleElements(
              [
                this.fileInputRow,
                this.dropImageContent,
                this.thumbnailRow,
                this.fileThInputRow,
                this.mediapath,
                this.thRow,
              ],
              true
            );
            this.toggleElements(
              [
                this.embeddedRow,
                this.jwplayer,
                this.iframeVdeo,
                this.editTemplate,
              ],
              false
            );
            break;
          case 'video':
            this.toggleElements(
              [
                this.fileInputRow,
                this.mediapath,
                this.fileThInputRow,
                this.jwplayer,
                this.thRow,
                this.dropImageContent,
              ],
              true
            );
            this.toggleElements(
              [this.embeddedRow, this.iframeVdeo, this.editTemplate],
              false
            );
            break;
          case 'iframe':
            this.toggleElements(
              [
                this.embeddedRow,
                this.fileInputRow,
                this.dropImageContent,
                this.thumbnailRow,
                this.jwplayer,
                this.mediapath,
                this.thRow,
                this.editTemplate,
              ],
              false
            );
            this.toggleElements([this.iframeVdeo, this.fileThInputRow], true);
            break;
          case 'advertizer':
            this.toggleElements(
              [
                this.fileInputRow,
                this.dropImageContent,
                this.iframeVdeo,
                this.thumbnailRow,
                this.jwplayer,
                this.mediapath,
                this.thRow,
                this.editTemplate,
              ],
              false
            );
            this.toggleElements([this.embeddedRow, this.fileThInputRow], true);
            break;
          case 'embedded':
            this.toggleElements(
              [
                this.fileInputRow,
                this.dropImageContent,
                this.iframeVdeo,
                this.thumbnailRow,
                this.jwplayer,
                this.mediapath,
                this.thRow,
                this.editTemplate,
              ],
              false
            );
            this.toggleElements([this.embeddedRow, this.fileThInputRow], true);
            break;
          case 'edit':
            this.toggleElements(
              [
                this.embeddedRow,
                this.fileInputRow,
                this.dropImageContent,
                this.iframeVdeo,
                this.jwplayer,
                this.thumbnailRow,
                this.mediapath,
                this.thRow,
                this.fileThInputRow,
              ],
              false
            );
            break;
          default:
            this.toggleElements(
              [
                this.embeddedRow,
                this.fileInputRow,
                this.dropImageContent,
                this.iframeVdeo,
                this.jwplayer,
                this.thumbnailRow,
                this.mediapath,
                this.thRow,
                this.editTemplate,
              ],
              false
            );
            break;
        }
      } else {
        this.toggleElements(
          [
            this.embeddedRow,
            this.fileInputRow,
            this.dropImageContent,
            this.iframeVdeo,
            this.thumbnailRow,
            this.jwplayer,
            this.fileThInputRow,
            this.mediapath,
            this.thRow,
            this.editTemplate,
          ],
          false
        );
      }
    });
  }

  checkImage(mime: string): boolean {
    return Mime.Image.findIndex((f) => f === mime) !== -1;
  }

  checkVideo(mime: string): boolean {
    return Mime.Video.findIndex((f) => f === mime) !== -1;
  }

  checkMimeType(mime: string): boolean {
    let correspond = false;
    switch (this._tile.type) {
      case 'image':
        correspond = this.checkImage(mime);
        break;
      case 'video':
        correspond = this.checkVideo(mime);
        break;
    }
    return correspond;
  }

  urlCheck(): boolean {
    const URLCheck =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
    let checked = false;
    if (this._tile.fileName) {
      checked =
        this.tile!.fileName !== null &&
        this.tile!.fileName.match(URLCheck) !== null;
    }
    return checked;
  }

  waitingFileName(hasContent: boolean): void {
    if (!hasContent) {
      this.waiting = true;
      setTimeout(() => {
        this.tileService
          .getTile(this.page, this.wave, this.tile?.customID!)
          .subscribe((tile: Tile) => {
            this.waitingFileName(this.urlCheck());
          });
      }, 1000);
    } else {
      this.waiting = false;
    }
  }

  async dropFile(event: DragEvent): Promise<void> {
    this.fileDragged = false;
    this.badformat = false;
    event.preventDefault();
    const dataTransfert = event.dataTransfer;
    if (
      this.tile?.title &&
      (this.tile.type === 'image' || this.tile.type === 'video') &&
      dataTransfert?.files.length === 1 &&
      dataTransfert.files[0]
    ) {
      const content = await this.addMedia(dataTransfert.files[0]);
      if (content) {
        this.tile.fileName = content;
      }
    }
  }

  async getFile(event: Event): Promise<void> {
    if (event.type === 'change') {
      const inputFile = event.target as HTMLInputElement;
      this.addMedia(inputFile.files![0]);
    }
  }

  async getThFile(event: Event): Promise<void> {
    if (event.type === 'change') {
      const inputFile = event.target as HTMLInputElement;
      this.addThumb(inputFile.files![0]);
    }
  }

  addThumb(media: File): void {
    if (this.checkImage(media.type)) {
      this.tileService.upload(media).subscribe((response) => {
        if (response.type === HttpEventType.Response) {
          if (response.body.messageType === 'File.upload.done') {
            this._tile.thFileName = response.body.message;
            this.notify.success(`"${this._tile.thFileName}" a été téléversé`);
          } else {
            this.notify.failed(`Erreur de téléversement du média`);
          }
          this.fileProgressTh = -1;
          this.progressBarTh.nativeElement.style.setProperty(
            '--progress-pos',
            `0%`
          );
        }
        if (response.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round(
            (100 * response.loaded) / response.total
          );
          this.fileProgressTh = percentDone;
          if (this.progressBarTh) {
            this.progressBarTh.nativeElement.style.setProperty(
              '--progress-pos',
              `${percentDone}%`
            );
          }
        }
      });
    }
  }

  async addMedia(media: File): Promise<string | undefined> {
    if (this.checkMimeType(media.type)) {
      this.tileService.upload(media).subscribe((response) => {
        if (response.type === HttpEventType.Response) {
          if (response.body.messageType === 'File.upload.done') {
            this._tile.fileName = response.body.message;
            this.notify.success(`"${this._tile.fileName}" a été téléversé`);
          } else {
            this.notify.failed(`Erreur de téléversement du média`);
          }
          this.fileProgress = -1;
          this.progressBar.nativeElement.style.setProperty(
            '--progress-pos',
            `0%`
          );
        }
        if (response.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round(
            (100 * response.loaded) / response.total
          );
          this.fileProgress = percentDone;
          if (this.progressBar) {
            this.progressBar.nativeElement.style.setProperty(
              '--progress-pos',
              `${percentDone}%`
            );
          }
        }
      });
      // return (await this.tileService.upload(media).toPromise()).message;
    }
    return undefined;
  }

  stopDrag(): void {
    this.fileDragged = false;
    this.badformat = false;
  }

  dragOverHandler(event: DragEvent): void {
    this.fileDragged = true;
    const data = event.dataTransfer;
    this.badformat = !this.checkMimeType(data!.items![0]?.type);
    event.preventDefault();
  }

  updateColor(value: string): void {
    this._tile.backgroundColor = value;
    this.tileService.tilePreview.next(this._tile);
  }

  checkIntegrity(): boolean {
    if (this.tile != undefined) {
      switch (this.tile.type) {
        case 'advertizer': {
          const integrity =
            this._tile !== undefined &&
            this._tile.title !== undefined &&
            this._tile.type !== undefined &&
            this._tile.sizeID !== undefined &&
            this._tile.url !== undefined;
          this.integrityChange.emit(integrity);
          return integrity;
        }
        case 'image': {
          const integrity =
            this._tile !== undefined &&
            this._tile.title !== undefined &&
            this._tile.type !== undefined &&
            this._tile.sizeID !== undefined &&
            this._tile.url !== undefined;
          this.integrityChange.emit(integrity);
          return integrity;
        }
        case 'video': {
          const integrity =
            this._tile !== undefined &&
            this._tile.title !== undefined &&
            this._tile.type !== undefined &&
            this._tile.sizeID !== undefined &&
            this._tile.url !== undefined;
          this.integrityChange.emit(integrity);
          return integrity;
        }
        case 'iframe': {
          const integrity =
            this._tile !== undefined &&
            this._tile.title !== undefined &&
            this._tile.type !== undefined &&
            this._tile.sizeID !== undefined &&
            this._tile.url !== undefined;
          this.integrityChange.emit(integrity);
          return integrity;
        }
        case 'embedded': {
          const integrity =
            this._tile !== undefined &&
            this._tile.title !== undefined &&
            this._tile.type !== undefined &&
            this._tile.sizeID !== undefined &&
            this._tile.embedded !== '' &&
            this._tile.url !== undefined;
          this.integrityChange.emit(integrity);
          return integrity;
        }
        default: {
          const integrity =
            this._tile !== undefined &&
            this._tile.title !== undefined &&
            this._tile.type !== undefined &&
            this._tile.sizeID !== undefined &&
            this._tile.url !== undefined;
          this.integrityChange.emit(integrity);
          return integrity;
        }
      }
    }
    return false;
  }

  updateTileSize(value: string): void {
    this._tile.sizeID = value;
    this._tile.size = this.sizes.find((s) => s.customID === value)!;
    this.tileService.tilePreview.next(this._tile);
    if (this._tile.type == 'edit')
      this.toggleElements([this.editTemplate], true);
  }

  updateBlur(value: boolean): void {
    this._tile.backgroundBlurred = value;
    this.tileService.tilePreview.next(this._tile);
  }
  updateYtEmbed(value: boolean): void {
    console.log(this.tile?.type);
    this._tile.isYoutubeEmbed = value;
    this.tileService.tilePreview.next(this._tile);
  }
  canEnableYtEmbed():boolean{
    return this._tile.type == 'advertizer';
  }

  cancel(): void {
    this._tile = undefined!;
    this.tileChange.emit(this._tile);
  }

  clone(tile: Tile): void {
    this.tileService
      .clone(this.page!, this.wave, tile)
      .subscribe((clone: Tile) => {
        this.tile = tile;
        this.tileService.tileUpdated.next(clone);
      });
  }

  deleteTile(event: MouseEvent, tile: Tile): void {
    event.stopPropagation();
    this.tileService
      .deleteTile(this.page!, this.wave, tile)
      .subscribe((done: BackendResponse) => {
        this.cancel();
        this.tileService.tileUpdated.next(undefined);
      });
  }

  ngOnInit(): void {
    if (this.tile) {
      this.el.nativeElement
        .querySelector('.drop')
        .style.setProperty(
          '--background-target-url',
          `url(${this.tile.thUrl})`
        );
    }
  }

  ConfigurationEditTemplatePopUp() {
    const dynamicComponentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        ConfTemplateComponent
      );
    const componentRef =
      this.containerConfigurationEditTemplate.createComponent(
        dynamicComponentFactory
      );
    componentRef.instance.size = this._tile.size;
    componentRef.instance.text = this._tile.edit;
    componentRef.instance.SendOutput.subscribe((data: any) => {
      if (data != 'close') {
        this._tile.edit = data;
      }
      componentRef.destroy();
    });
  }

  ConfigurationOverlayPopUp() {
    const dynamicComponentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        OverlayFactoryComponent
      );
    const componentRef = this.containerOverlayConfiguration.createComponent(
      dynamicComponentFactory
    );
    componentRef.instance.previewTile = this._tile;
    componentRef.instance.SendOutput.subscribe((data: box[]) => {
      if (data.length > 0) {
        this._tile.SurCoucheInfo = data;
      }
      componentRef.destroy();
    });
  }
}
