import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "@services/user.service";
import {first} from "rxjs/operators";
import {User} from "@models/user";
import {Perm} from "@models/perm";
import {NotifyService} from "@services/notify.service";

@Component({ templateUrl: 'add-edit.component.html',
  styleUrls: ['../../../pages/login/login.component.scss']})
export class AddEditComponent implements OnInit {
  form!: FormGroup;
  id!: string;
  isAddMode!: boolean;
  loading = false;
  submitted = false;
  userProfil!:string;
  user!: any;
  isEdit = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: UserService,
    private notifyService: NotifyService
  ) {
  }

  ngOnInit() {
    this.userProfil = this.accountService.user.profilType
    this.id = this.route.snapshot.params['customID'];
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      profilType: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8),
        Validators.pattern(/\d/),
        Validators.pattern(/[A-Z]/),
        Validators.pattern(/[a-z]/),
        Validators.pattern(/[!@#$%^&*()_+-={};':"|,.<>/?+-=]/)]]
    });
      let currRoute = this.router.url
    if (currRoute.includes("edit")){
      this.isEdit = true;
      this.id = this.route.snapshot.params['customID'];
      this.accountService.getById(this.id).subscribe(
        (user:any) => {
        this.user = user['data']
        this.form.setValue({
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email,
          profilType: this.user.profilType,
          password: ''
        })
      })
    }

    this.isAddMode = !this.id;

    // password not required in edit mode
    // const passwordValidators = [Validators.minLength(6)];
    // if (this.isAddMode) {
    //   passwordValidators.push(Validators.required);
    // }

  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;


    // stop here if form is invalid
    if (this.form.invalid && !this.isEdit){
        this.notifyService.failed("Vérifiez les champs renseignés")
        return;
    }

    this.form.value.particulier = true;

    this.form.value.tiersID = this.accountService.user.customID
    this.form.value.permissions = []

    this.loading = true;
    if (this.isAddMode) {
      this.form.value.permissions.push(new Perm("basique perm", "read_update_delete_import"))
      this.createUser();
    } else {
      this.updateUser();
    }
  }

  private createUser() {
    this.accountService.add(this.form.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.router.navigate(['/list'], { relativeTo: this.route });
          this.notifyService.success("Utilisateur crée !")
        },
        error: () => {
          this.notifyService.failed("Erreur lors de la création de l'utilisateur")
        }
      });
  }

  private updateUser() {
    let user = new User()
    user.firstName = this.form.value.firstName
    user.lastName = this.form.value.lastName
    user.email = this.form.value.email
    user.profilType = this.form.value.profilType
    if (this.form.value.password.length > 5){
      user.password = this.form.value.password
    }else{
      delete this.form.value.password
    }

    this.accountService.update(this.id, user)

      .pipe(first())
      .subscribe({
        next: () => {
          console.log(user)
          this.router.navigate(['../../'], { relativeTo: this.route });
          this.notifyService.success("Utilisateur modifié !")
        },
        error: ()  => {
          this.notifyService.failed("Erreur lors de la modification de l'utilisateur")
        }
      });
  }
}