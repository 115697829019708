import { box } from '@components/overlay-factory/overlay-factory.component';
import { IData } from '../interfaces/data';
import { Size } from './size';
export class Tile implements IData {
  id?: string;
  customID?: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  isActive: boolean;
  deleted?: boolean;
  customCode?: string;
  owner?: string;
  description?: string;
  target: string | null;
  type!: string;
  size?: Size;
  sizeID!: string;
  useJWPlayer: boolean;
  html: string | null;
  embedded!: string;
  edit: EditType = { html: '' };
  SurCoucheInfo: box[] = [];
  url: string | null;
  title!: string;
  fileName: string | null;
  thUrl!: string;
  thFileName!: string;
  tiersID?: string;
  resize?: boolean;
  backgroundColor?: string;
  backgroundBlurred?: boolean;
  printOrder = 1;
  iframeVideo?: boolean;
  isYoutubeEmbed?:boolean;
  constructor() {
    this.isActive = true;
    this.useJWPlayer = false;
    this.iframeVideo = false;
    this.embedded = '';
    this.backgroundBlurred = false;
    this.html = '';
    this.size = new Size();
    this.description = '';
    this.target = '';
    this.url = '';
    this.fileName = '';
    this.tiersID = '';
  }
}

export interface EditType
{
  html:string,
  params?:any,
}
