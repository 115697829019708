import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserService } from '@services/user.service';
import {HttpConstants} from "../../constants/http.constants";
import {User} from "@models/user";
import {AuthService} from "@services/auth.service";

@Component({
  selector: 'app-user-params',
  templateUrl: './user-params.component.html',
  styleUrls: ['./user-params.component.scss']
})
export class UserParamsComponent implements OnInit {


  get fullName(): string {
    if(this.userService.user){
      return `${this.userService.user.firstName} ${this.userService.user.lastName}`;
    }
    else{
      return ""
    }
  }
  constructor(private userService: UserService, private router: Router, private authService:AuthService) {

  }

  isAdmin():boolean{
    if (this.userService.user.profilType == "Admin"){
      return true;
    }
    return false;
  }
  isModerator():boolean{
    if (this.userService.user.profilType == "Moderateur")
    {
      return true;
    }
    return false;
  }

  userClick() {
    this.authService.logout();
    this.router.navigate(['logout']);
  }

  kpiClick() {
    this.router.navigate(['kpi']);
  }

  feedbackClick() {
    this.router.navigateByUrl('feedback');
  }

  listUserClick() {
    this.router.navigateByUrl('list');
  }

  ngOnInit(): void {
  }

}
